import eventUtil from "@/services/eventUtil.service";

export default {
    error: state => state.error,
    jobs: state => state.jobs,

    eventTemplates: state => state.eventTemplates,
    eventTemplate: state => state.eventTemplate,
    appliedOnEvents: state => state.appliedOnEvents,
    eventCapacityTemplatesOptions: state => state.eventCapacityTemplatesOptions,
    eventTemplateCapacityLocationOptions: (state) => {
        // if(!state.eventTemplate || !state.eventTemplate.capacity) {
        //     return {
        //         aggregateId: null,
        //         id: null,
        //         capacity: {
        //             name: null,
        //             type: "aggregated",
        //             children: []
        //         }
        //     }
        // }
        // return {
        //     aggregateId: state.eventTemplate.id,
        //     id: state.eventTemplate.capacity.id,
        //     capacity: state.eventTemplate.capacity
        // }

        if(state.eventTemplate && state.eventTemplate.capacity) {
            // return eventUtil.getCapacityLocations(state.eventTemplate.capacity);
            return eventUtil.getCapacityLocationOptions(state.eventTemplate.capacity);
        }
        if(state.eventCapacityTemplatesOptions && state.eventCapacityTemplatesOptions.length > 0) {
            let options = [];
            for (let i = 0; i < state.eventCapacityTemplatesOptions.length; i++) {
                if(state.eventCapacityTemplatesOptions[i].capacityString) {
                    const capacity = JSON.parse(state.eventCapacityTemplatesOptions[i].capacityString);
                    // const locations = eventUtil.getCapacityLocations(capacity);
                    // options = [...options, ...locations];
                    const locations = eventUtil.getCapacityLocationOptions(capacity);
                    options = options.concat(locations);
                }
            }
            // return options.filter((value, index, self) => self.indexOf(value) === index); // return only unique values
            return options.reduce((x, y) => x.findIndex(e=>e.path===y.path)<0 ? [...x, y]: x, []); // return only unique values
        }
        return []
    },
    eventTemplateCapacity: (state) => {
        if(!state.eventTemplate || !state.eventTemplate.capacity) {
            return {
                aggregateId: null,
                id: null,
                capacity: {
                    // name: null,
                    // type: "aggregated",
                    // children: []
                    name: 'root',
                    type: "aggregated",
                    children: [{
                        location: null,
                        name: null,
                        value: 0,
                        type: "nonRegulated",
                        start: null,
                        end: null,
                        tags: null,
                        constraints: null,
                        isTemplate: false
                    }]
                }
            }
        }
        return {
            aggregateId: state.eventTemplate.id,
            id: state.eventTemplate.capacity.id,
            capacity: state.eventTemplate.capacity
        }
    },
    eventTemplateAccessDefinitions: state => state.eventTemplate && state.eventTemplate.accessDefinitions ? state.eventTemplate.accessDefinitions : [],


    // eventTemplateListView: state => state.eventTemplateListView,
    // eventTemplateDetailView: state => state.eventTemplateDetailView,
    // accessDefinitionListView: state => state.accessDefinitionListView,
    // accessDefinitionDetailView: state => state.accessDefinitionDetailView,
    // capacityDetailView: state => state.capacityDetailView,


};
