import * as mutationTypes from "../mutation-types";
import BackendService from "@/services/backend.service";
import GraphqlQueryBuilder from "@/services/GraphqlQueryBuilder.service";
import validationService from "@/services/validation.service";
import * as jobTypes from "../job-types";
import router from '@/router'
const backoffice = new BackendService();


export default {

    async getPackageDefinitions({ commit, state }, {rows, sort, columns, filters}) {
        const c = {options: [], selected: columns.selected}; // create custom column selection because different types of validityPeriod doest not work with query builder

        // build query
        const builder = new GraphqlQueryBuilder();
        builder.setType("packageDefinitions");
        builder.setRows(rows);
        builder.setSort(sort);
        builder.setColumns(c, ["id", "name", "content{... on PackageContentAccess{id,eventManagerId,eventId,accessDefinitionId,requestedConditionPath,capacityLocationPath}, ... on PackageContentProduct{id,productDefinitionId,productVariantId,requestedConditionPath}}"]);
        if( validationService.validateDatatableFilters(filters) ) {
            builder.setFilters(filters);
        }
        const query = builder.build();

        //
        commit(mutationTypes.START_PACKAGE_DEFINITION_JOB, jobTypes.GET_PACKAGE_DEFINITION);
        const result = await backoffice.sendQuery(query);
        if (result.errors) {
            commit(mutationTypes.PACKAGE_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PACKAGE_DEFINITION_JOB, jobTypes.GET_PACKAGE_DEFINITION);
        } else {
            commit(mutationTypes.RECEIVED_PACKAGE_DEFINITIONS, { packageDefinitions: result.data.packageDefinitions });
            commit(mutationTypes.END_PACKAGE_DEFINITION_JOB, jobTypes.GET_PACKAGE_DEFINITION);
        }
    },

    async getPackageDefinition({ commit, state }, {id}) {
        commit(mutationTypes.START_PACKAGE_DEFINITION_JOB, jobTypes.GET_PACKAGE_DEFINITION);
        commit(mutationTypes.CLEAR_PACKAGE_DEFINITION);
        const query = `query { packageDefinition(id: "${id}"){ id,name,description,tags{id,name},content{... on PackageContentAccess{id,eventManagerId,eventId,accessDefinitionId,requestedConditionPath,capacityLocationPath,event{name,start,location}}, ... on PackageContentProduct{id,productDefinitionId,productVariantId,requestedConditionPath,productDefinition{name}}} }}`;
        const result = await backoffice.sendQuery(query);
        if (result.error) {
            commit(mutationTypes.PACKAGE_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PACKAGE_DEFINITION_JOB, jobTypes.GET_PACKAGE_DEFINITION);
        } else {
            commit(mutationTypes.RECEIVED_PACKAGE_DEFINITION, { packageDefinition: result.data.packageDefinition });
            commit(mutationTypes.END_PACKAGE_DEFINITION_JOB, jobTypes.GET_PACKAGE_DEFINITION);
        }
    },

    async savePackageDefinitionMutations({ commit, state, dispatch }, mutations) {
        commit(mutationTypes.START_PACKAGE_DEFINITION_JOB, jobTypes.SAVE_PACKAGE_DEFINITION);
        const result = await backoffice.sendCommandBatch(mutations);
        if (result.error) {
            commit(mutationTypes.PACKAGE_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PACKAGE_DEFINITION_JOB, jobTypes.SAVE_PACKAGE_DEFINITION);
        } else {
            commit(
                mutationTypes.SAVED_PACKAGE_DEFINITION,
                mutations.reduce(
                    (state, command) => Object.assign(state, command.data),
                    {}
                )
            );
            commit(mutationTypes.END_PACKAGE_DEFINITION_JOB, jobTypes.SAVE_PACKAGE_DEFINITION);

            // get updated version from api
            const id = mutations && mutations[0] && mutations[0].data && mutations[0].data.aggregateId ? mutations[0].data.aggregateId : null;
            if(id) dispatch('getPackageDefinition', {id: id});
        }
    },

    async createPackageDefinition({ commit, state, dispatch }, {packageDefinition}) {
        commit(mutationTypes.START_PACKAGE_DEFINITION_JOB, jobTypes.SAVE_PACKAGE_DEFINITION);
        const result = await backoffice.sendCommand('CreatePackageDefinition', packageDefinition);
        if (result.error) {
            commit(mutationTypes.PACKAGE_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PACKAGE_DEFINITION_JOB, jobTypes.SAVE_PACKAGE_DEFINITION);
        } else {
            commit(mutationTypes.CREATED_PACKAGE_DEFINITION, packageDefinition);
            commit(mutationTypes.END_PACKAGE_DEFINITION_JOB, jobTypes.SAVE_PACKAGE_DEFINITION);
            dispatch('getPackageDefinition', {id: result.data.packageDefinitionId});
            router.push({ name: "package-definition-detail", params: { id: result.data.packageDefinitionId } });
        }
    },

    async addPackageDefinitionContent({ commit, state, dispatch }, {content}) {
        const commandType = content.eventManagerId ? 'AddPackageDefinitionAccess' : 'AddPackageDefinitionProduct';
        commit(mutationTypes.START_PACKAGE_DEFINITION_JOB, jobTypes.SAVE_PACKAGE_DEFINITION);
        const result = await backoffice.sendCommand(commandType, content);
        if (result.error) {
            commit(mutationTypes.PACKAGE_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PACKAGE_DEFINITION_JOB, jobTypes.SAVE_PACKAGE_DEFINITION);
        } else {
            commit(mutationTypes.END_PACKAGE_DEFINITION_JOB, jobTypes.SAVE_PACKAGE_DEFINITION);
            dispatch('getPackageDefinition', {id: content.aggregateId});
            router.push({ name: "package-definition-content-list", params: { id: content.aggregateId } });
        }

    },

    async startBatchOperation({ commit, dispatch }, operations) {
        const jobName = 'start-batch-operation';
        commit(mutationTypes.START_PACKAGE_DEFINITION_JOB, jobName);
        await dispatch('core/startBatchOperation', operations, {root:true});
        commit(mutationTypes.END_PACKAGE_DEFINITION_JOB, jobName);
    },

    clearPackageDefinition({ commit, state }) {
        commit(mutationTypes.CLEAR_PACKAGE_DEFINITION);
    },


};
