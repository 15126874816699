import { render, staticRenderFns } from "./FormOrderTimeoutEditor.vue?vue&type=template&id=e620c82e&scoped=true&"
import script from "./FormOrderTimeoutEditor.vue?vue&type=script&lang=js&"
export * from "./FormOrderTimeoutEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../backoffice-client/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e620c82e",
  null
  
)

export default component.exports