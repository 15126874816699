import { render, staticRenderFns } from "./DeliveryEditorDelivery.vue?vue&type=template&id=83a6bda4&scoped=true&"
import script from "./DeliveryEditorDelivery.vue?vue&type=script&lang=js&"
export * from "./DeliveryEditorDelivery.vue?vue&type=script&lang=js&"
import style0 from "./DeliveryEditorDelivery.vue?vue&type=style&index=0&id=83a6bda4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../backoffice-client/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83a6bda4",
  null
  
)

export default component.exports