export default [
    {
        path: "/system",
        name: "system-root",
        redirect: { name: 'system-coupon' }
        // component: () =>
        //     import(/* webpackChunkName: "system" */ "@/modules/system/views/SystemDashboardView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },


    {
        path: "/system/user",
        name: "system-user",
        component: () =>
            import(/* webpackChunkName: "system-user" */ "@/modules/system/views/SystemUserListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },
    {
        path: "/system/user/new",
        name: "system-user-new",
        component: () =>
            import(/* webpackChunkName: "system-user" */ "@/modules/system/views/SystemUserDetailView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },
    {
        path: "/system/user/:id",
        name: "system-user-detail",
        component: () =>
            import(/* webpackChunkName: "system-user" */ "@/modules/system/views/SystemUserDetailView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },


    {
        path: "/system/tag",
        name: "system-tag",
        component: () =>
            import(/* webpackChunkName: "system-tag" */ "@/modules/system/views/SystemTagListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },
    {
        path: "/system/tag/new",
        name: "system-tag-new",
        component: () =>
            import(/* webpackChunkName: "system-tag" */ "@/modules/system/views/SystemTagDetailView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },
    {
        path: "/system/tag/:id",
        name: "system-tag-detail",
        component: () =>
            import(/* webpackChunkName: "system-tag" */ "@/modules/system/views/SystemTagDetailView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },


    {
        path: "/system/coupon",
        name: "system-coupon",
        component: () =>
            import(/* webpackChunkName: "system-coupon" */ "@/modules/system/views/SystemCouponListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },
    {
        path: "/system/coupon/new",
        name: "system-coupon-new",
        component: () =>
            import(/* webpackChunkName: "system-coupon" */ "@/modules/system/views/SystemCouponNewView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },
    {
        path: "/system/coupon/:id",
        name: "system-coupon-detail-root",
        component: () =>
            import(/* webpackChunkName: "system-coupon" */ "@/modules/system/views/SystemCouponDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "system-coupon-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-coupon" */ "@/modules/system/components/CouponDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "codes",
                name: "system-coupon-tokens",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-coupon" */ "@/modules/system/components/CouponTokens.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            }
        ]
    },


    {
        path: "/system/sales-channel",
        name: "system-sales-channel",
        component: () =>
            import(/* webpackChunkName: "system-sales-channel" */ "@/modules/system/views/SystemSalesChannelListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },
    {
        path: "/system/sales-channel/new",
        name: "system-sales-channel-new",
        component: () =>
            import(/* webpackChunkName: "system-sales-channel" */ "@/modules/system/views/SystemSalesChannelNewView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },
    {
        path: "/system/sales-channel/:id",
        name: "system-sales-channel-detail-root",
        component: () =>
            import(/* webpackChunkName: "system-sales-channel" */ "@/modules/system/views/SystemSalesChannelDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "system-sales-channel-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-sales-channel" */ "@/modules/system/components/SalesChannelDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "register",
                name: "system-sales-channel-register",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-sales-channel" */ "@/modules/system/components/RegisterList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "register/:registerId",
                name: "system-sales-channel-register-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-sales-channel" */ "@/modules/system/components/RegisterDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            }
        ]
    },



    {
        path: "/system/payment-method",
        name: "system-payment-method",
        component: () =>
            import(/* webpackChunkName: "system-payment-method" */ "@/modules/system/views/SystemPaymentMethodListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },
    // {
    //     path: "/system/payment-method/new",
    //     name: "system-payment-method-new",
    //     component: () =>
    //         import(/* webpackChunkName: "system-payment-method" */ "@/modules/system/views/SystemPaymentMethodDetailView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    // },
    {
        path: "/system/payment-method/:id",
        name: "system-payment-method-detail-root",
        component: () =>
            import(/* webpackChunkName: "system-payment-method" */ "@/modules/system/views/SystemPaymentMethodDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "system-payment-method-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-payment-method" */ "@/modules/system/components/PaymentMethodDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
        ]
    },



    {
        path: "/system/opt-in",
        name: "system-opt-in-definition",
        component: () =>
            import(/* webpackChunkName: "system-opt-in-definition" */ "@/modules/system/views/SystemOptInDefinitionListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },
    {
        path: "/system/opt-in/:id",
        name: "system-opt-in-definition-detail-root",
        component: () =>
            import(/* webpackChunkName: "system-opt-in-definition" */ "@/modules/system/views/SystemOptInDefinitionDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "system-opt-in-definition-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-opt-in-definition" */ "@/modules/system/components/OptInDefinitionDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
        ]
    },



    {
        path: "/system/order-message",
        name: "system-order-message-definition",
        component: () =>
            import(/* webpackChunkName: "system-order-message-definition" */ "@/modules/system/views/SystemOrderMessageDefinitionListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },
    {
        path: "/system/order-message/:id",
        name: "system-order-message-definition-detail-root",
        component: () =>
            import(/* webpackChunkName: "system-order-message-definition" */ "@/modules/system/views/SystemOrderMessageDefinitionDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "system-order-message-definition-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-order-message-definition" */ "@/modules/system/components/OrderMessageDefinitionDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
        ]
    },



    {
        path: "/system/email-template",
        name: "system-email-template",
        component: () =>
            import(/* webpackChunkName: "system-email-template" */ "@/modules/system/views/SystemEmailTemplateListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },
    // {
    //     path: "/system/email-template/new",
    //     name: "system-email-template-new",
    //     component: () =>
    //         import(/* webpackChunkName: "system-email-template" */ "@/modules/system/views/SystemTagDetailView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    // },
    {
        path: "/system/email-template/:id",
        name: "system-email-template-detail-root",
        component: () =>
            import(/* webpackChunkName: "system-email-template" */ "@/modules/system/views/SystemEmailTemplateDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "system-email-template-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-email-template" */ "@/modules/system/components/EmailTemplateDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "attachment",
                name: "system-email-template-attachment",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-email-template" */ "@/modules/system/components/EmailTemplateAttachmentList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "attachment/:index",
                name: "system-email-template-attachment-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-email-template" */ "@/modules/system/components/EmailTemplateAttachmentDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "document-attachment",
                name: "system-email-template-document-attachment",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-email-template" */ "@/modules/system/components/EmailTemplateDocumentAttachmentList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "document-attachment/:attachmentType/:attachmentId",
                name: "system-email-template-document-attachment-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-email-template" */ "@/modules/system/components/EmailTemplateDocumentAttachmentDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
        ]
    },





    {
        path: "/system/document-template",
        name: "system-document-template",
        component: () =>
            import(/* webpackChunkName: "system-document-template" */ "@/modules/system/views/SystemDocumentTemplateListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },
    {
        path: "/system/document-template/:id",
        name: "system-document-template-detail-root",
        component: () =>
            import(/* webpackChunkName: "system-document-template" */ "@/modules/system/views/SystemDocumentTemplateDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "system-document-template-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-document-template" */ "@/modules/system/components/DocumentTemplateDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
        ]
    },





    {
        path: "/system/integration",
        name: "system-integration",
        // redirect: { name: 'system-integration-fiona-entity-map' }
        redirect: { name: 'system-integration-fiona-accreditation' }
        // component: () =>
        //     import(/* webpackChunkName: "system-integration" */ "@/modules/system/views/SystemIntegrationView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },
    {
        path: "/system/integration/fiona",
        name: "system-integration-fiona",
        component: () =>
            import(/* webpackChunkName: "system-integration-fiona" */ "@/modules/system/views/SystemIntegrationFionaView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [

            // {
            //     path: "map",
            //     name: "system-integration-fiona-entity-map",
            //     components: {
            //         detail: () =>
            //             import(/* webpackChunkName: "system-integration-fiona" */ "@/modules/system/components/FionaIntegrationEntityMapList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
            //     }
            // },
            // {
            //     path: "map/:id",
            //     name: "system-integration-fiona-entity-map-detail",
            //     components: {
            //         detail: () =>
            //             import(/* webpackChunkName: "system-integration-fiona" */ "@/modules/system/components/FionaIntegrationEntityMapDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
            //     }
            // },
            {
                path: "accreditation",
                name: "system-integration-fiona-accreditation",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-integration-fiona" */ "@/modules/system/components/FionaIntegrationAccreditationList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "accreditation/import",
                name: "system-integration-fiona-accreditation-import",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-integration-fiona" */ "@/modules/system/components/FionaIntegrationAccreditationImportWizard.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "accreditation/:id",
                name: "system-integration-fiona-accreditation-api-response",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-integration-fiona" */ "@/modules/system/components/FionaIntegrationFionaApiResponse.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "accreditation-map",
                name: "system-integration-fiona-accreditation-map",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-integration-fiona" */ "@/modules/system/components/FionaIntegrationAccreditationMapList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "accreditation-map/:id",
                name: "system-integration-fiona-accreditation-map-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-integration-fiona" */ "@/modules/system/components/FionaIntegrationAccreditationMapDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "volunteer",
                name: "system-integration-fiona-volunteer",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-integration-fiona" */ "@/modules/system/components/FionaIntegrationVolunteerList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "volunteer/import",
                name: "system-integration-fiona-volunteer-import",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-integration-fiona" */ "@/modules/system/components/FionaIntegrationVolunteerImportWizard.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "volunteer-map",
                name: "system-integration-fiona-volunteer-map",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-integration-fiona" */ "@/modules/system/components/FionaIntegrationVolunteerMapList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "volunteer-map/:id",
                name: "system-integration-fiona-volunteer-map-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "system-integration-fiona" */ "@/modules/system/components/FionaIntegrationVolunteerMapDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
        ]
    },






    {
        path: "/system/test",
        name: "system-test",
        component: () =>
            import(/* webpackChunkName: "system" */ "@/modules/system/views/SystemTestView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    }
];
