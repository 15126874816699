export const MEMBERSHIP_DEFINITION_ERROR = "MEMBERSHIP_DEFINITION_ERROR";
export const FLUSH_MEMBERSHIP_DEFINITION_ERROR = "FLUSH_MEMBERSHIP_DEFINITION_ERROR";

export const START_MEMBERSHIP_DEFINITION_JOB = "START_MEMBERSHIP_DEFINITION_JOB";
export const END_MEMBERSHIP_DEFINITION_JOB = "END_MEMBERSHIP_DEFINITION_JOB";

export const RECEIVED_MEMBERSHIP_DEFINITIONS = "RECEIVED_MEMBERSHIP_DEFINITIONS";
export const RECEIVED_MEMBERSHIP_DEFINITION = "RECEIVED_MEMBERSHIP_DEFINITION";
export const SAVED_MEMBERSHIP_DEFINITION = "SAVED_MEMBERSHIP_DEFINITION";
export const CREATED_MEMBERSHIP_DEFINITION = "CREATED_MEMBERSHIP_DEFINITION";
export const CLEAR_MEMBERSHIP_DEFINITION = "CLEAR_MEMBERSHIP_DEFINITION";

export const RECEIVED_MEMBERSHIPS = "RECEIVED_MEMBERSHIPS";
