var render, staticRenderFns
import script from "./BaseHeading.vue?vue&type=script&lang=js&"
export * from "./BaseHeading.vue?vue&type=script&lang=js&"
import style0 from "./BaseHeading.vue?vue&type=style&index=0&id=46984b35&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../backoffice-client/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46984b35",
  null
  
)

export default component.exports