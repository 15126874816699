import * as mutationTypes from "../mutation-types";
import BackendService from "@/services/backend.service";
import router from "@/router";

const backoffice = new BackendService();

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
};


export default {

    async signIn({ commit, dispatch }, data) {
        dispatch('flushError');
        const job = {key: 'login'};
        commit(mutationTypes.START_CORE_JOB, job);
        // const backoffice = new BackendService();
        const result = await backoffice.getAuthToken(data);
        if (result.error) {
            commit(mutationTypes.CORE_ERROR, result.error);
            commit(mutationTypes.END_CORE_JOB, job);
        } else {
            // commit(mutationTypes.LOGGED_IN, { users: result.data.users });
            dispatch('getUser');
            commit(mutationTypes.END_CORE_JOB, job);
            // router.push('/');
            router.push({ name: "event-root" });
        }
    },


    async getUser({ commit, dispatch, state }) {
        if(state.user === null || state.user === {}) {
            const job = {key: 'get-user'};
            commit(mutationTypes.START_CORE_JOB, job);
            commit(mutationTypes.CLEAR_USER);
            const query = `query { me{id,username,scopes} }`;
            const response = await backoffice.sendQuery(query);
            if (response.errors) {
                commit(mutationTypes.CORE_ERROR, { error: response.errors });
            } else {
                commit(mutationTypes.RECEIVED_USER, {user: response.data.me});
            }
            commit(mutationTypes.END_CORE_JOB, job);
        }
    },


    async clearUser({ commit }) {
        commit(mutationTypes.CLEAR_USER);
    },


    async search ({ commit, state }, searchQuery) {
        const job = {key: 'searching'};
        commit(mutationTypes.START_CORE_JOB, job);
        const query = `query { search(searchTerm: "${searchQuery}"){id,name,description,type,status,date} }`;
        const result = await backoffice.sendQuery(query);
        if (result.errors) {
            commit(mutationTypes.CORE_ERROR, { error: result.errors });
            commit(mutationTypes.END_CORE_JOB, job);
        } else {
            commit(mutationTypes.RECEIVED_SEARCH_RESULT, result.data.search);
            commit(mutationTypes.END_CORE_JOB, job);
        }
    },


    async startBatchOperation ({ commit, state, dispatch }, operations) {
        let result = await backoffice.sendCommand("StartBatchOperation", {operations});
        if (result.errors) {
            commit(mutationTypes.CORE_ERROR, { error: result.errors });
        } else {
            // const job = {key: 'batch-operation', id: (new Date()).getTime()};
            const job = {key: 'batch-operation', id: result.data.batchId};
            commit(mutationTypes.START_CORE_JOB, job);
            dispatch('pollBatchOperationStatus', {batchId: result.data.batchId, pollInterval: 5000, remainingTries: 15});
            return result.data.batchId;
        }
    },


    async pollBatchOperationStatus ({ commit, state, dispatch }, {batchId, pollInterval, remainingTries}) {
        const query = `query { batchOperation(id: "${batchId}"){id,status,total,success,error,createdOn}}`;
        const result = await backoffice.sendQuery(query);
        if (result.errors) {
            commit(mutationTypes.CORE_ERROR, { error: result.errors });
        } else {
            const status = result.data.batchOperation.status;
            if(status !== 'finished' && remainingTries > 0) {
                await sleep(pollInterval);
                dispatch('pollBatchOperationStatus', {batchId: batchId, pollInterval: 10000, remainingTries: remainingTries--});
            } else if (status === 'finished') {
                commit(mutationTypes.FINISH_BATCH_OPERATION, {id: batchId, key: 'batch-operation'});
            } else {
                commit(mutationTypes.END_CORE_JOB, {id: batchId, key: 'batch-operation'});
            }
        }
    },


    async getCurrencyOptions ({ commit, state }) {
        const job = {key: 'get-options'};
        commit(mutationTypes.START_CORE_JOB, job);
        const query = `query { currencies{code,name,exponent,symbol} }`;
        const result = await backoffice.sendQuery(query);
        if (result.errors) {
            commit(mutationTypes.CORE_ERROR, { error: result.errors });
            commit(mutationTypes.END_CORE_JOB, job);
        } else {
            commit(mutationTypes.RECEIVED_CURRENCY_OPTIONS, result.data.currencies.map(currency => {
                return { value: currency.code, label: currency.name }
            }));
            commit(mutationTypes.END_CORE_JOB, job);
        }
    },


    async getTagOptions ({ commit, state }) {
        const job = {key: 'get-options'};
        commit(mutationTypes.START_CORE_JOB, job);
        const query = `query { tags{id,name} }`;
        const result = await backoffice.sendQuery(query);
        if (result.errors) {
            commit(mutationTypes.CORE_ERROR, { error: result.errors });
            commit(mutationTypes.END_CORE_JOB, job);
        } else {
            commit(mutationTypes.RECEIVED_TAG_OPTIONS, result.data.tags);
            commit(mutationTypes.END_CORE_JOB, job);
        }
    },



    async getNameSuggestions({ commit, dispatch }, {type}) {
        const jobName = 'get-suggestions';
        commit(mutationTypes.START_CORE_JOB, jobName);
        commit(mutationTypes.CLEAR_NAME_SUGGESTIONS);
        const result = await backoffice.sendQuery(`query { nameSuggestions(filters: {type: {equals: "${type}"}}, first: 0, offset: 500){ id,type,name }}`);
        if (result.error) {
            commit(mutationTypes.CORE_ERROR, result.error);
            commit(mutationTypes.END_CORE_JOB, jobName);
        } else {
            commit(mutationTypes.RECEIVED_NAME_SUGGESTIONS, {suggestions: result.data.nameSuggestions});
            commit(mutationTypes.END_CORE_JOB, jobName);
        }
    },


    registerJobToast ({ commit, state }, {job, toast}) {
        commit(mutationTypes.REGISTER_CORE_JOB_TOAST, {job, toast});
    },


    flushError({ commit, state }) {
        commit(mutationTypes.FLUSH_CORE_ERROR);
    }
};
