export default {
  error: state => state.error,
  jobs: state => state.jobs,
  customers: state => state.customers,
  customer: state => state.customer,
  customerOrders: state => state.customerOrders,
  customerAccess: state => state.customerAccess,
  customerProducts: state => state.customerProducts,
  customerMemberships: state => state.customerMemberships,
  customerListView: state => state.customerListView,
  customerDetailView: state => state.customerDetailView,
  customerAddView: state => state.customerAddView,
  user: (state, getters, rootState, rootGetters) => {
    return rootState;
  }
};
