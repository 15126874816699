import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import moment from "moment";
import formatService from "@/services/format.service";
import optionService from "@/services/option.service";


// initial state
const state = {
  //
  error: [],

  //
  jobs: [],

  // customer collection
  customers: [],

  // single customer
  customer: {
    firstName: "",
    lastName: ""
  },

  customerOrders: [],
  customerAccess: [],
  customerProducts: [],
  customerMemberships: [],

  // customer list view settings
  customerListView: {
    columns: {
      options: [
        // all available columns
        {
          key: "fullName",
          label: "common.name", // translation key for the header label
          link: {
            routeName: "customer-detail",
            params: [{ paramKey: "id", valueKey: "id" }]
          } // route params contains a params map for mapping row values to params properties
        },
        {
          key: "id",
          label: "common.id"
        },
        {
            key: 'email',
            label: 'person.email',
        },
        {
            key: 'phone',
            label: 'person.phone',
        },
        // {
        //     key: 'website',
        //     label: 'person.website',
        // },
        {
          key: "birthDate",
          label: "person.birthDate",
          format: function(value) {
            return moment(value).format("MMMM Do YYYY");
          },
          filter: {
            type: "date"
          }
        },
        {
          key: "gender",
          label: "person.gender"
          // filter: {
          //     type: 'enum',
          //     options: [{value: 'male', label: 'Male'}, {value: 'female', label: 'Female'}, {value: 'other', label: 'Other'}]
          // }
        },
        {
            key: 'company',
            label: 'person.company',
        },
        {
            key: 'jobTitle',
            label: 'person.jobTitle',
        },
        {
          key: 'preferredLanguage',
          label: 'person.preferredLanguage',
        },
      ],
      selected: ["common.name", "person.email"] // labels of columns that are displayed
    },
    rows: {
      total: 0, // total rows in search result
      max: 20, // maximum number of rows to display at once, elastic search query size
      from: 0 // show rows from row number, elastic search query from
    },
    searchTerm: "",
    searchFields: ["fullName", "email", "id"],
    filters: {
      // options: [
      //     {
      //         key: 'fullName',
      //         label: 'common.name',
      //         type: 'text'
      //     },
      //     {
      //         key: 'sex',
      //         label: 'person.gender',
      //         type: 'enum',
      //         options: [{value: 'male', label: 'Male'}, {value: 'female', label: 'Female'}, {value: 'other', label: 'Other'}]
      //     },
      //     {
      //         key: 'birthDate',
      //         label: 'person.birthDate',
      //         type: 'date'
      //     }
      // ],
      selected: []
      // selected: [{operator: 'equals', label: 'common.name', value: 'test'}, {operator: 'contains', label: 'person.sex', value: [{label: 'Female', value: 'female'}, {label: 'Male', value: 'male'}]}, {operator: 'gte', label: 'person.company', value: 4}, {operator: 'isTrue', label: 'person.jobTitle', value: null}, {operator: 'between', label: 'person.birthDate', value: ['2018-09-13', '2018-09-18']}]
    },
    sort: {
      key: "fullName",
      direction: "desc"
    },
    // bulkActions: ["actions.delete"]
  },

  // customer detail view settings
  customerDetailView: {
    tabs: [
      { routeName: "customer-detail", label: "common.details" },
      { routeName: "customer-order", label: "common.orders" },
      {routeName: 'customer-access', label: 'common.tickets'},
      // {routeName: 'customer-product', label: 'common.products'},
      {routeName: 'customer-membership', label: 'common.memberships'},
      // {routeName: 'customer-package', label: 'common.packages'},
    ],

    form: {
      schema: {
        groups: [
          {
            // legend: 'common.general',
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "person.firstName",
                model: "firstName",
                icon: "label-alt",
                command: "ChangeCustomer",
                placeholder: "person.placeholder.firstName"
                // featured: true,
                // required: true,
                // validator: validators.string
                // validator: 'string'
              },
              {
                type: "input",
                inputType: "text",
                label: "person.lastName",
                model: "lastName",
                icon: "label-alt",
                command: "ChangeCustomer",
                placeholder: "person.placeholder.lastName"
                // hint: 'hint text',
                // help: 'help text',
                // validator: validators.string
                // validator: 'string'
              },
              {
                type: "input",
                inputType: "text",
                label: "person.company",
                model: "company",
                icon: "label-alt",
                command: "ChangeCustomer",
                placeholder: "person.placeholder.company"
              },
              {
                type: "input",
                inputType: "text",
                label: "person.jobTitle",
                model: "jobTitle",
                icon: "label-alt",
                command: "ChangeCustomer",
                placeholder: "person.placeholder.jobTitle"
              },
              {
                type: "select",
                // type: 'vueMultiSelect',
                label: "person.gender",
                model: "gender",
                icon: "venus",
                command: "ChangeCustomer",
                placeholder: "",
                values: [
                  { id: "male", name: "Male" },
                  { id: "female", name: "Female" },
                  { id: "other", name: "Other" }
                ],
                selectOptions: {
                  // closeOnSelect: true,
                  // showLabels: false,
                  trackBy: "id",
                  label: "name"
                }
              },
              {
                type: "teDateTime",
                // type: 'input',
                inputType: "date",
                label: "person.birthDate",
                model: "birthDate",
                format: function(value) {
                  return value ? moment(value).format("MMMM Do YYYY") : '';
                },
                icon: "calendar-alt",
                command: "ChangeCustomer",
                placeholder: "person.placeholder.birthDate"
              },
              {
                type: "input",
                inputType: "email",
                label: "person.email",
                model: "email",
                icon: "envelope",
                command: "ChangeCustomer",
                placeholder: "person.placeholder.email"
              },
              {
                type: "input",
                inputType: "phone",
                label: "person.phone",
                model: "phone",
                icon: "phone",
                command: "ChangeCustomer",
                placeholder: "person.placeholder.phone"
              },
//               {
//                 type: "vueMultiSelect",
//                 label: "common.tags",
//                 model: "tags",
//                 icon: "tag-alt",
//                 command: "ChangeCustomer",
//                 placeholder: "",
//                 values: [],
//                 format: function(value) {
//                   return (value && value.length > 0) ? value.map(t => t.name).join(', ') : '';
//                 },
//                 selectOptions: {
//                   multiple: true,
//                   taggable: true,
//                   closeOnSelect: false,
//                   clearOnSelect: false,
//                   internalSearch: false,
//                   hideSelected: true,
//                   searchable: true,
//                   showLabels: false,
//                   showNoResults: false,
//                   label: 'name',
//                   trackBy: 'id',
//                   onNewTag: function(newTag, id, options, value) {
// console.log(newTag);
//                     options.push(newTag);
//                     value.push(newTag);
//                   },
//                   searchChange: function(query) {
// console.log(query);
//                   }
//                 }
//               }
              {
                type: "teTag",
                label: "common.tags",
                model: "tags",
                icon: "tag-alt",
                command: "ChangeCustomer",
                format: formatService.formatTags,
                searchTag: optionService.searchTag,
                addTag: optionService.addTag,
              },
              {
                type: "input",
                inputType: "text",
                label: "person.imageUrl",
                model: "imageUrl",
                icon: "image",
                command: "ChangeCustomer",
                placeholder: "person.placeholder.imageUrl",
                format: function(value) {
                  if(value) {
                    return '<img src="'+value+'" alt="image" width="150">'
                  }
                  return value;
                }
              }

            ]
          }
        ]
      },
      options: {
        validateAfterLoad: true,
        validateAfterChanged: true
      },
      commandDataMap: [
        {
          modelKey: "id",
          commandKey: "id"
        }
      ]
    }
  }

  // customer add view settings
  // customerAddView: {
  //     form: {
  //         schema: {
  //             groups: [
  //                 {
  //                     // legend: 'common.general',
  //                     fields: [{
  //                         type: 'input',
  //                         inputType: 'text',
  //                         label: 'person.firstName',
  //                         model: 'firstName',
  //                         command: 'RenameCustomerFirstName',
  //                         placeholder: 'person.placeholder.firstName',
  //                         // featured: true,
  //                         required: true,
  //                         // validator: validators.string
  //                         // validator: 'string'
  //                     },{
  //                         type: 'input',
  //                         inputType: 'text',
  //                         label: 'person.lastName',
  //                         model: 'lastName',
  //                         command: 'RenameCustomerLastName',
  //                         placeholder: 'person.placeholder.lastName',
  //                         // hint: 'hint text',
  //                         // help: 'help text',
  //                         // validator: validators.string
  //                         // validator: 'string'
  //                     },{
  //                         type: 'select',
  //                         label: 'person.sex',
  //                         model: 'sex',
  //                         command: 'ChangeCustomerSex',
  //                         values: () => {
  //                             return [
  //                                 {id: 'male', name: 'Male'},
  //                                 {id: 'female', name: 'Female'},
  //                                 {id: 'other', name: 'Other'}
  //                             ]
  //                         }
  //                     },{
  //                         type: 'input',
  //                         inputType: 'date',
  //                         label: 'person.birthDate',
  //                         model: 'birthDate',
  //                         command: 'ChangeCustomerBirthDate',
  //                         placeholder: 'person.placeholder.birthDate',
  //                     // },
  //                     // {
  //                     //     type: 'input',
  //                     //     inputType: 'date',
  //                     //     label: 'person.birthDate',
  //                     //     model: 'birthDate',
  //                     //     command: 'ChangeCustomerBirthDate',
  //                     //     placeholder: 'person.placeholder.birthDate',
  //                     }]
  //                 },
  //             ]
  //         },
  //         options: {
  //             validateAfterLoad: true,
  //             validateAfterChanged: true
  //         },
  //     }
  // }
};

//
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
