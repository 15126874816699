import _ from 'lodash';
import translationService from '@/services/translation.service';
export default {
  jobs: state => state.jobs,
  error: state => state.error,
  language: state => state.language,
  user: state => state.user,
  navigation: state => state.navigation,
  searchResults: state => state.searchResults,
  floatingActionButton: state => state.floatingActionButton,
  tags: state => state.options.tags,
  nameSuggestions: state => state.nameSuggestions,
  conditions: function(state) {
    // translate options
    let conditions = _.cloneDeep(state.options.conditions);
    translationService.translateLabels(conditions.price.currency);
    translationService.translateLabels(conditions.price.tax);
    translationService.translateLabels(conditions.postponed);
    translationService.translateLabels(conditions.limit);
    conditions.rules.forEach(group => {
      translationService.translateLabels(group.values);
    });
    return conditions;
  }
};
