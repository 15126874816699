export default [


    {
        path: "/template",
        name: "event-template-root",
        // redirect: { name: 'event-template-list', params: {type: 'event'} }
        redirect: { name: 'event-template-event' }
    },

    {
        path: "/template/event",
        name: "event-template-event",
        // redirect: { name: 'event-template-list', params: {type: 'event'} }
        component: () =>
            import(/* webpackChunkName: "event-template-list" */ "@/modules/event-template/views/EventTemplateListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },

    {
        path: "/template/capacity",
        name: "event-template-capacity",
        // redirect: { name: 'event-template-list', params: {type: 'capacity'} }
        component: () =>
            import(/* webpackChunkName: "event-template-list" */ "@/modules/event-template/views/EventTemplateListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },

    {
        path: "/template/access",
        name: "event-template-access",
        // redirect: { name: 'event-template-list', params: {type: 'access'} }
        component: () =>
            import(/* webpackChunkName: "event-template-list" */ "@/modules/event-template/views/EventTemplateListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },

    // {
    //     path: "/template/:type",
    //     name: "event-template-list",
    //     component: () =>
    //         import(/* webpackChunkName: "event-template-list" */ "@/modules/event-template/views/EventTemplateListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    // },

    {
        path: "/template/event/new",
        name: "event-template-event-new",
        component: () =>
            import(/* webpackChunkName: "event-template-list" */ "@/modules/event-template/views/EventTemplateNewView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },

    {
        path: "/template/event/:id",
        name: "event-template-event-detail-root",
        // component: EventDetailView,
        component: () =>
            import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/views/EventTemplateDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "event-template-event-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "capacity",
                name: "event-template-event-capacity-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateCapacityDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "access",
                name: "event-template-event-access-definition-list",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateAccessList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "access/:accessDefinitionId",
                name: "event-template-event-access-definition-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateAccessDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "used-by-events",
                name: "event-template-event-used-by-events",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateAppliedOn.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            }
        ]
    },



    {
        path: "/template/capacity/new",
        name: "event-template-capacity-new",
        component: () =>
            import(/* webpackChunkName: "event-template-list" */ "@/modules/event-template/views/EventTemplateNewView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },

    {
        path: "/template/capacity/:id",
        name: "event-template-capacity-detail-root",
        // component: EventDetailView,
        component: () =>
            import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/views/EventTemplateDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "event-template-capacity-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "capacity",
                name: "event-template-capacity-capacity-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateCapacityDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "used-by-events",
                name: "event-template-capacity-used-by-events",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateAppliedOn.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            }
        ]
    },



    {
        path: "/template/access/new",
        name: "event-template-access-new",
        component: () =>
            import(/* webpackChunkName: "event-template-list" */ "@/modules/event-template/views/EventTemplateNewView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },

    {
        path: "/template/access/:id",
        name: "event-template-access-detail-root",
        // component: EventDetailView,
        component: () =>
            import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/views/EventTemplateDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "event-template-access-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "access",
                name: "event-template-access-access-definition-list",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateAccessList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "access/:accessDefinitionId",
                name: "event-template-access-access-definition-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateAccessDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "used-by-events",
                name: "event-template-access-used-by-events",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateAppliedOn.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            }
        ]
    },






    // {
    //     path: "/event-template",
    //     name: "event-template-root",
    //     component: () =>
    //         import(/* webpackChunkName: "event-template-list" */ "@/modules/event-template/views/EventTemplateListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    // },
    //
    // {
    //     path: "/event-template/new",
    //     name: "event-template-new",
    //     component: () =>
    //         import(/* webpackChunkName: "event-template-list" */ "@/modules/event-template/views/EventTemplateNewView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    // },
    //
    // {
    //     path: "/event-template/:id",
    //     name: "event-template-detail-root",
    //     // component: EventDetailView,
    //     component: () =>
    //         import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/views/EventTemplateDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    //     children: [
    //         {
    //             path: "detail",
    //             name: "event-template-detail",
    //             components: {
    //                 detail: () =>
    //                     import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    //             }
    //         },
    //         {
    //             path: "capacity",
    //             name: "event-template-capacity-detail",
    //             components: {
    //                 detail: () =>
    //                     import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateCapacityDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    //             }
    //         },
    //         {
    //             path: "access",
    //             name: "event-template-access-definition-list",
    //             components: {
    //                 detail: () =>
    //                     import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateAccessList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    //             }
    //         },
    //         {
    //             path: "access/:accessDefinitionId",
    //             name: "event-template-access-definition-detail",
    //             components: {
    //                 detail: () =>
    //                     import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateAccessDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    //             }
    //         },
    //         {
    //             path: "used-by-events",
    //             name: "event-template-used-by-events",
    //             components: {
    //                 detail: () =>
    //                     import(/* webpackChunkName: "event-template-detail" */ "@/modules/event-template/components/EventTemplateAppliedOn.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    //             }
    //         }
    //     ]
    // },

];
