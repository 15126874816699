import * as types from "../mutation-types";
import _ from "lodash";

export default {
    [types.PACKAGE_DEFINITION_ERROR](state, error) {
        state.error = { message: error };
    },

    [types.FLUSH_PACKAGE_DEFINITION_ERROR](state) {
        state.error = { message: null };
    },

    [types.START_PACKAGE_DEFINITION_JOB](state, key) {
        state.jobs.push(key);
    },

    [types.END_PACKAGE_DEFINITION_JOB](state, key) {
        state.jobs = state.jobs.filter(job => job !== key);
    },

    [types.RECEIVED_PACKAGE_DEFINITIONS](state, { packageDefinitions }) {
        state.packageDefinitions = packageDefinitions;
    },

    [types.RECEIVED_PACKAGE_DEFINITION](state, { packageDefinition }) {
        state.packageDefinition = packageDefinition;
    },

    [types.SAVED_PACKAGE_DEFINITION](state, commandData) {
        state.packageDefinition = _.merge(state.packageDefinition, commandData);
    },

    [types.CREATED_PACKAGE_DEFINITION](state, data) {
        state.packageDefinition = data;
    },

    [types.CLEAR_PACKAGE_DEFINITION](state) {
        state.packageDefinition = {};
    },


};
