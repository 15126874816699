import * as types from "../mutation-types";
// import _ from "lodash";

export default {
    [types.REPORT_ERROR](state, error) {
        state.error = { message: error };
    },
    [types.FLUSH_REPORT_ERROR](state) {
        state.error = { message: null };
    },


    [types.START_REPORT_JOB](state, key) {
        state.jobs.push(key);
    },
    [types.END_REPORT_JOB](state, key) {
        state.jobs = state.jobs.filter(job => job !== key);
    },


    [types.RECEIVED_REPORT_LEDGER](state, { ledger }) {
        state.ledger = ledger;
    },
    [types.CLEAR_REPORT_LEDGER](state) {
        state.ledger = [];
    },



};
