export default [

    {
        path: "/account",
        name: "account-detail-root",
        component: () =>
            import(/* webpackChunkName: "account-detail" */ "@/modules/account/views/AccountDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "account-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "account-detail" */ "@/modules/account/components/AccountDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "batch-operation",
                name: "account-batch-operation",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "account-detail" */ "@/modules/account/components/AccountBatchOperationList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "batch-operation/:id",
                name: "account-batch-operation-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "account-detail" */ "@/modules/account/components/AccountBatchOperationDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
        ]
    },

];
