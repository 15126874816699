import * as types from "../mutation-types";
import _ from "lodash";

export default {
    // jobs and error
    [types.EVENT_TEMPLATE_ERROR](state, error) {
        state.error = { message: error };
    },
    [types.FLUSH_EVENT_TEMPLATE_ERROR](state) {
        state.error = { message: null };
    },
    [types.START_EVENT_TEMPLATE_JOB](state, key) {
        state.jobs.push(key);
    },
    [types.END_EVENT_TEMPLATE_JOB](state, key) {
        state.jobs = state.jobs.filter(job => job !== key);
    },

    // event template
    [types.RECEIVED_EVENT_TEMPLATES](state, { eventTemplates }) {
        state.eventTemplates = eventTemplates;
    },
    [types.RECEIVED_EVENT_TEMPLATE](state, { eventTemplate }) {
        state.eventTemplate = eventTemplate;
    },
    [types.RECEIVED_EVENT_TEMPLATE_APPLIED_ON_EVENTS](state, { events }) {
        state.appliedOnEvents = events;
    },
    [types.SAVED_EVENT_TEMPLATE](state, commandData) {
        state.eventTemplate = _.merge(state.eventTemplate, commandData);
    },
    [types.ADD_EVENT_TEMPLATE_ACCESS_DEFINITION](state, commandData) {
        if(state.eventTemplate.accessDefinitions) {
            state.eventTemplate.accessDefinitions.push(commandData);
        }
    },
    [types.UPDATE_EVENT_TEMPLATE_ACCESS_DEFINITION](state, commandData) {
        for (let i in state.eventTemplate.accessDefinitions) {
            if(state.eventTemplate.accessDefinitions[i].id === commandData.accessDefinitionId) {
                state.eventTemplate.accessDefinitions[i] = _.merge(state.eventTemplate.accessDefinitions[i], commandData);
                break;
            }
        }
    },
    [types.CLEAR_EVENT_TEMPLATES](state) {
        state.eventTemplates = [];
    },
    [types.CREATED_EVENT_TEMPLATE](state, data) {
        state.eventTemplate = data;
    },
    [types.CLEAR_EVENT_TEMPLATE](state) {
        state.eventTemplate = {};
    },
    [types.CLEAR_EVENT_TEMPLATE_APPLIED_ON_EVENTS](state) {
        state.appliedOnEvents = [];
    },


    //
    [types.RECEIVED_EVENT_CAPACITY_TEMPLATES_OPTIONS](state, { templates }) {
        state.eventCapacityTemplatesOptions = templates;
    },


    // // event template list mutations
    // [types.SELECT_EVENT_TEMPLATE_LIST_COLUMNS](state, selectedColumns) {
    //     state.eventTemplateListView.columns.selected = selectedColumns;
    // },
    // [types.SET_EVENT_TEMPLATE_LIST_ROWS](state, rows) {
    //     state.eventTemplateListView.rows = _.merge(state.eventTemplateListView.rows, rows);
    // },
    // [types.SET_EVENT_TEMPLATE_LIST_SORT](state, sort) {
    //     state.eventTemplateListView.sort = _.merge(state.eventTemplateListView.sort, sort);
    // },
    // [types.FILTER_EVENT_TEMPLATE_LIST](state, selectedFilters) {
    //     state.eventTemplateListView.filters.selected = selectedFilters;
    // },


};





