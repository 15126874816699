import * as types from "../mutation-types";
import _ from "lodash";

export default {
    [types.CREDIT_ERROR](state, error) {
        state.error = { message: error };
    },

    [types.FLUSH_CREDIT_ERROR](state) {
        state.error = { message: null };
    },

    [types.START_CREDIT_JOB](state, key) {
        state.jobs.push(key);
    },

    [types.END_CREDIT_JOB](state, key) {
        state.jobs = state.jobs.filter(job => job !== key);
    },

    // credit account
    [types.RECEIVED_CREDIT_ACCOUNTS](state, { creditAccounts }) {
        state.creditAccounts = creditAccounts;
    },

    [types.RECEIVED_CREDIT_ACCOUNT](state, { creditAccount }) {
        state.creditAccount = creditAccount;
    },

    [types.SAVED_CREDIT_ACCOUNT](state, commandData) {
        state.creditAccount = _.merge(state.creditAccount, commandData);
    },

    [types.CREATED_CREDIT_ACCOUNT](state, data) {
        state.creditAccount = data;
    },

    [types.CLEAR_CREDIT_ACCOUNT](state) {
        state.creditAccount = {};
    },

    // credit account currency
    [types.RECEIVED_CREDIT_ACCOUNT_CURRENCIES](state, { creditAccountCurrencies }) {
        state.creditAccountCurrencies = creditAccountCurrencies;
    },

    [types.RECEIVED_CREDIT_ACCOUNT_CURRENCY](state, { creditAccountCurrency }) {
        state.creditAccountCurrency = creditAccountCurrency;
    },

    [types.SAVED_CREDIT_ACCOUNT_CURRENCY](state, commandData) {
        state.creditAccountCurrency = _.merge(state.creditAccountCurrency, commandData);
    },

    [types.CREATED_CREDIT_ACCOUNT_CURRENCY](state, data) {
        state.creditAccountCurrency = data;
    },

    [types.CLEAR_CREDIT_ACCOUNT_CURRENCY](state) {
        state.creditAccountCurrency = {};
    },

};
