export default [
    // {
    //     path: "/package",
    //     name: "package-root",
    //     redirect: { name: 'package-definition-list' }
    // },

    {
        path: "/package",
        // name: "package-definition-list",
        name: "package-root",
        component: () =>
            import(/* webpackChunkName: "package" */ "@/modules/package/views/PackageDefinitionListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },

    {
        path: "/package/:id",
        name: "package-definition-detail-root",
        component: () =>
            import(/* webpackChunkName: "package" */ "@/modules/package/views/PackageDefinitionDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "package-definition-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "package" */ "@/modules/package/components/PackageDefinitionDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "content",
                name: "package-definition-content-list",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "package" */ "@/modules/package/components/PackageDefinitionContentList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "content/:contentItemId",
                name: "package-definition-content-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "package" */ "@/modules/package/components/PackageDefinitionContentDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
        ]
    },

];
