import * as types from "../mutation-types";
import _ from "lodash";

export default {
    [types.MEMBERSHIP_DEFINITION_ERROR](state, error) {
        state.error = { message: error };
    },

    [types.FLUSH_MEMBERSHIP_DEFINITION_ERROR](state) {
        state.error = { message: null };
    },

    [types.START_MEMBERSHIP_DEFINITION_JOB](state, key) {
        state.jobs.push(key);
    },

    [types.END_MEMBERSHIP_DEFINITION_JOB](state, key) {
        state.jobs = state.jobs.filter(job => job !== key);
    },

    [types.RECEIVED_MEMBERSHIP_DEFINITIONS](state, { membershipDefinitions }) {
        state.membershipDefinitions = membershipDefinitions;
    },

    [types.RECEIVED_MEMBERSHIP_DEFINITION](state, { membershipDefinition }) {
        state.membershipDefinition = membershipDefinition;
    },

    [types.SAVED_MEMBERSHIP_DEFINITION](state, commandData) {
        state.membershipDefinition = _.merge(state.membershipDefinition, commandData);
    },

    [types.CREATED_MEMBERSHIP_DEFINITION](state, data) {
        state.membershipDefinition = data;
    },

    [types.CLEAR_MEMBERSHIP_DEFINITION](state) {
        state.membershipDefinition = {};
    },

    [types.RECEIVED_MEMBERSHIPS](state, {memberships}) {
        state.memberships = memberships;
    },

};
