import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import optionService from "@/services/option.service";


// initial state
const state = {
  jobs: [],
  error: {},
  language: "en",
  user: null,
  nameSuggestions: [],
  searchResults: [],

  isSearching: false,

  // user: {
  //     token: localStorage.getItem('token') || '',
  //     clientKey: localStorage.getItem('clientKey') || '',
  // },

  navigation: [
    {
      name: "sales",
      items: [
        {
          name: "event-root",
          label: "navigation.event.root",
          icon: "ticket",
          requiredPermission: 'event:read',
        },
        // {
        //   name: "event-template-root",
        //   label: "navigation.event.template",
        //   icon: "file-alt"
        // },
        {
          name: "event-template-root",
          label: "navigation.event.template.root",
          icon: "file-alt",
          requiredPermission: 'event_template:read',
          items: [
            {
              name: "event-template-event",
              label: "navigation.event.template.event",
              icon: "file-alt",
              requiredPermission: 'event_template:read',
              // params: {type: 'event'}
            },
            {
              name: "event-template-capacity",
              label: "navigation.event.template.capacity",
              icon: "file-alt",
              requiredPermission: 'event_template:read',
              // params: {type: 'capacity'}
            },
            {
              name: "event-template-access",
              label: "navigation.event.template.access",
              icon: "file-alt",
              requiredPermission: 'event_template:read',
              // params: {type: 'access'}
            },
          ]
        },
        {
          name: "product-definition-root",
          label: "navigation.product.root",
          icon: "dropbox",
          requiredPermission: 'product_definition:read',
        },
        {
          name: "offer-root",
          label: "navigation.offer.root",
          icon: "pricetag-alt",
          requiredPermission: 'offer:read',
        },
      ]
    },
    // {
    //     name: 'process',
    //     items: [
    //         {
    //             name: 'sales-channel-root',
    //             label: 'navigation.salesChannel.root',
    //             icon: 'sales-channel',
    //         },
    //     ]
    // },
    {
      name: "report",
      items: [
        // {
        //   name: 'analytics-root',
        //   label: 'navigation.analytics.root',
        //   icon: 'analysis',
        // },
        {
          name: "order-root",
          label: "navigation.order.root",
          icon: "invoice",
          requiredPermission: 'order:read',
          // icon: "shopping-cart-alt"
        },
        {
          name: "credit-root",
          label: "navigation.credit.root",
          icon: "money-bill",
          requiredPermission: 'credit_account:read',
          items: [
            {
              name: "credit-account",
              label: "navigation.credit.account",
              requiredPermission: 'credit_account:read',
            },
            {
              name: "credit-account-currency",
              label: "navigation.credit.type",
              requiredPermission: 'credit_account:read',
            },
          ]
        },
        {
          name: "membership-root",
          label: "navigation.membership.root",
          icon: "user-square",
          requiredPermission: 'membership_definition:read',
          items: [
            {
              name: "membership-list",
              label: "navigation.membership.holder",
              requiredPermission: 'membership_definition:read',
            },
            {
              name: "membership-definition-list",
              label: "navigation.membership.type",
              requiredPermission: 'membership_definition:read',
            },
          ]
        },
        {
          name: "package-root",
          label: "navigation.package.root",
          icon: "package",
          requiredPermission: 'package_definition:read',
        },
        {
          name: "customer-root",
          label: "navigation.customer.root",
          icon: "users-alt",
          requiredPermission: 'customer:read',
        },
        {
          name: 'report-root',
          label: 'navigation.report.root',
          icon: 'file-medical-alt',
          requiredPermission: 'payment:read',
          items: [
            {
              name: "report-ledger",
              label: "navigation.report.ledger",
              requiredPermission: 'payment:read',
            },
          ]
        },
      ]
    },
    {
      name: "system",
      items: [
        // {
        //     name: 'sales-channel-root',
        //     label: 'navigation.salesChannel.root',
        //     icon: 'sales-channel',
        // },
        {
          name: "system-root",
          label: "navigation.system.root",
          icon: "cog",
          items: [
            // {
            //   name: "system-root",
            //   label: "navigation.system.root"
            // },

            {
              name: "system-coupon",
              label: "navigation.system.coupon",
              requiredPermission: 'token:read',
            },
            {
              name: "system-email-template",
              label: "navigation.system.emailTemplate",
              requiredPermission: 'email:read',
            },
            {
              name: "system-document-template",
              label: "navigation.system.documentTemplate",
              requiredPermission: 'document:read',
            },
            {
              name: "system-sales-channel",
              label: "navigation.system.salesChannel",
              requiredPermission: 'sales_chanel:read',
            },
            {
              name: "system-tag",
              label: "navigation.system.tag",
              requiredPermission: 'tag:read',
            },
            {
              name: "system-payment-method",
              label: "navigation.system.paymentMethod",
              requiredPermission: 'admin.payment:write',
            },
            {
              name: "system-opt-in-definition",
              label: "navigation.system.optInDefinition",
              requiredPermission: 'opt_in_definition:read',
            },
            {
              name: "system-order-message-definition",
              label: "navigation.system.orderMessageDefinition",
              requiredPermission: 'order_message_definition:read',
            },
            {
              name: "system-integration",
              label: "navigation.system.integration",
              requiredPermission: 'integration:read',
            },
            {
              name: "system-user",
              label: "navigation.system.user",
              requiredPermission: 'user:read',
            },
            // {
            //   name: "system-test",
            //   label: "Test"
            // }
          ]
        }
      ]
    }
  ],

  floatingActionButton: [
    [
      {
        name: "event-new",
        label: "Add event",
        icon: "ticket"
      },
      {
        name: "event-template-new",
        label: "Add event template",
        icon: "file-alt"
      },
      {
        name: "product-definition-new",
        label: "Add product",
        icon: "dropbox"
      }
    ],
    [
      // {
      //   name: "name31",
      //   label: "Add order",
      //   icon: "invoice"
      // },
      {
        name: "customer-new",
        label: "Add customer",
        icon: "users-alt"
      },
      // {
      //   name: "credit-account-currency-detail",
      //   label: "Add credit type",
      //   icon: "money-bill"
      // }
    ]
  ],

  options: {
    tags: [],
    // tags: optionService.getTags(),

    conditions: {
      price: {
        currency: [
            { value: "EUR", label: "Euro" },
            // { value: "CINEVILLE", label: "Cineville" },
        ],
        tax: [
          { value: "fixed", label: "common.tax.fixed" },
          { value: "percentage", label: "common.tax.percentage" }
        ]
      },
      postponed: [
        { value: null, label: "conditions.postponed.notPostponed" },
        { value: "until", label: "conditions.postponed.until" },
        {
          value: "untilMinutesBeforeEventStart",
          label: "conditions.postponed.untilMinutesBeforeEventStart"
        },
        {
          value: "untilMinutesBeforeEventEnd",
          label: "conditions.postponed.untilMinutesBeforeEventEnd"
        }
      ],
      capacityGroups: [],
      limit: [
        { value: "LimitPerEventPerOrder", label: "conditions.limit.LimitPerEventPerOrder" },
        { value: "LimitPerEventPerCustomer", label: "conditions.limit.LimitPerEventPerCustomer" }
      ],
      rules: [
        {
          name: "Payment conditions",
          values: [
            {
              fact: "price",
              label: "conditions.price",
              type: "price"
            },
            {
              fact: "loyaltyCardPrice",
              label: "conditions.loyaltyCardPrice",
              type: "loyaltyCardPrice",
              options: optionService.loyaltyCardTypes,
              async: false
            },
            {
              fact: "free",
              label: "conditions.free",
              type: "free"
            }
          ]
        },
        {
          name: "Cart conditions", // rule option group name
          values: [
            // { // rule option
            //     fact: 'hasItemOfTypeInCart', // fact name
            //     label: 'conditions.hasItemOfTypeInCart',
            //     type: 'multiselect', // option type
            //     operators: ['contains', 'containsAll', 'notContains'],
            //     options: ['ticket', 'product', 'membership', 'package']
            // },
            {
              fact: "numberOfItemsInCart",
              // fact: 'itemsInCart',
              label: "conditions.numberOfItemsInCart",
              type: "number",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"]
            },
            // {
            //     fact: 'numberOfTicketsInCart',
            //     label: 'conditions.numberOfTicketsInCart',
            //     type: 'number',
            //     operators: ['equals', 'notEquals', 'gt', 'gte', 'lt', 'lte']
            // },
            // {
            //     fact: 'numberOfMembershipsInCart',
            //     label: 'conditions.numberOfMembershipsInCart',
            //     type: 'number',
            //     operators: ['equals', 'notEquals', 'gt', 'gte', 'lt', 'lte']
            // },
            // {
            //     fact: 'numberOfProductsInCart',
            //     label: 'conditions.numberOfProductsInCart',
            //     type: 'number',
            //     operators: ['equals', 'notEquals', 'gt', 'gte', 'lt', 'lte']
            // },
            // {
            //     fact: 'numberOfPackagesInCart',
            //     label: 'conditions.numberOfPackagesInCart',
            //     type: 'number',
            //     operators: ['equals', 'notEquals', 'gt', 'gte', 'lt', 'lte']
            // },
            // {
            //     fact: 'ticketInCart',
            //     label: 'conditions.ticketInCart',
            //     type: 'multiselect',
            //     operators: ['contains', 'containsAll', 'notContains'],
            //     options: ['Show1', 'Show2', 'Show3'],
            // },
            // {
            //     fact: 'membershipsInCart',
            //     label: 'conditions.membershipsInCart',
            //     type: 'multiselect',
            //     operators: ['contains', 'containsAll', 'notContains'],
            //     options: ['TigerFriend', 'Guest', 'VIP'],
            // },
            // {
            //     fact: 'productsInCart',
            //     label: 'conditions.productsInCart',
            //     type: 'multiselect',
            //     operators: ['contains', 'containsAll', 'notContains'],
            //     options: ['t-shirt', 'tas'],
            // },
            // {
            //     fact: 'packagesInCart',
            //     label: 'conditions.packagesInCart',
            //     type: 'multiselect',
            //     operators: ['contains', 'containsAll', 'notContains'],
            //     options: ['VK-Dag', 'Actie1'],
            // },
            // {
            //   fact: "cartAccessTags",
            //   label: "conditions.cartAccessTags",
            //   type: "multiselect",
            //   operators: ["contains", "containsAll", "notContains"],
            //   options: [
            //     "guest",
            //     "vip",
            //     "invitedForOpening2019",
            //     "regular",
            //     "discount"
            //   ]
            // },
            // {
            //     fact: 'membershipsInCartWithTag',
            //     label: 'conditions.membershipsInCartWithTag',
            //     type: 'multiselect',
            //     operators: ['contains', 'containsAll', 'notContains'],
            //     options: ['tag1', 'tag2'],
            // },
            // {
            //     fact: 'productsInCartWithTag',
            //     label: 'conditions.productsInCartWithTag',
            //     type: 'multiselect',
            //     operators: ['contains', 'containsAll', 'notContains'],
            //     options: ['tag1', 'tag2'],
            // },
            // {
            //     fact: 'packagesInCartWithTag',
            //     label: 'conditions.packagesInCartWithTag',
            //     type: 'multiselect',
            //     operators: ['contains', 'containsAll', 'notContains'],
            //     options: ['tag1', 'tag2'],
            // },
            {
              fact: "cartTotalValue",
              label: "conditions.cartTotalValue",
              type: "number",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"]
            },
            {
              fact: "orderSalesChannel",
              label: "conditions.salesChannel",
              type: "multiselect",
              operators: ["contains", "containsNot"],
              // options: ["online", "boxoffice"]
              options: optionService.searchSalesChannel,
              async: false
            },
            // {
            //   fact: "orderRegister",
            //   label: "conditions.register",
            //   type: "multiselect",
            //   operators: ["contains", "equals"],
            //   options: ["kassa1", "kassa2", "kassa3"]
            // },
            {
              fact: "isPartOfPackage",
              label: "conditions.isPartOfPackage",
              type: "boolean",
              operators: ["equals"]
            },
            {
              fact: "tokenType",
              label: "conditions.tokenType",
              type: "multiselect",
              operators: ["contains", "containsNot"],
              options: optionService.searchCoupons,
              async: false
            },
            {
              fact: "accessToEventInCart",
              label: "conditions.accessToEventInCart",
              type: "multiselect",
              operators: ["contains", "containsNot"],
              options: optionService.events,
              async: false
            },
            {
              fact: "productOfTypeInCart",
              label: "conditions.productOfTypeInCart",
              type: "multiselect",
              operators: ["contains", "containsNot"],
              options: optionService.productDefinitions,
              async: false
            },
            {
              fact: "accessToEventWithTagInCart",
              label: "conditions.accessToEventWithTagInCart",
              type: "multiselect",
              operators: ["contains", "containsNot"],
              options: optionService.searchTag,
              async: false
            },
            {
              fact: "accessWithTagInCart",
              label: "conditions.accessWithTagInCart",
              type: "numberOfType",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"],
              options: optionService.searchTag,
              async: false
            },

          ]
        },
        {
          name: "Customer conditions",
          values: [
            {
              fact: "customerWithTag",
              label: "conditions.tag",
              type: "multiselect",
              operators: ["contains", "containsAll", "containsNot"],
              // options: ["guest", "discount"],
              options: optionService.searchTag,
              async: false
            },
            {
              fact: "customerPurchasedAccessToEvent",
              label: "conditions.purchasedAccessToEvent",
              type: "multiselect",
              operators: ["contains", "containsNot"],
              options: optionService.events,
              async: false
            },
            {
              fact: "customerPurchasedProductOfType",
              label: "conditions.purchasedProductOfType",
              type: "multiselect",
              operators: ["contains", "containsNot"],
              options: optionService.productDefinitions,
              async: false
            },
            {
              fact: 'customerHasActiveMembershipOfType',
              label: 'conditions.hasActiveMembershipOfType',
              type: 'multiselect',
              operators: ["contains", "containsNot"],
              options: optionService.membershipDefinitions,
              async: false
            },
            {
              fact: 'customerHasActiveOrFutureMembershipOfType',
              label: 'conditions.hasActiveOrFutureMembershipOfType',
              type: 'multiselect',
              operators: ["contains", "containsNot"],
              options: optionService.membershipDefinitions,
              async: false
            },
            {
              fact: 'customerHasExpiredMembershipOfType',
              label: 'conditions.hasExpiredMembershipOfType',
              type: 'multiselect',
              operators: ["contains", "containsNot"],
              options: optionService.membershipDefinitions,
              async: false
            },
            {
              fact: 'customerHasFutureMembershipOfType',
              label: 'conditions.hasFutureMembershipOfType',
              type: 'multiselect',
              operators: ["contains", "containsNot"],
              options: optionService.membershipDefinitions,
              async: false
            },
            {
                fact: 'customerFirstPurchase',
                label: 'conditions.firstPurchaseDate',
                type: 'date',
                operators: ['equals', 'notEquals', 'gt', 'gte', 'lt', 'lte']
            },
            {
                fact: 'customerLastPurchase',
                label: 'conditions.lastPurchaseDate',
                type: 'date',
                operators: ['equals', 'notEquals', 'gt', 'gte', 'lt', 'lte']
            },
            // {
            //     fact: 'customerLifeTimeValue',
            //     label: 'conditions.lifeTimeValue',
            //     type: 'number',
            //     operators: ['equals', 'notEquals', 'gt', 'gte', 'lt', 'lte']
            // },
            {
              fact: "customerLifetimePurchasedItems",
              label: "conditions.lifeTimeNumberOfPurchasedItems",
              type: "number",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"]
            },
            {
              fact: "customerBirthDate",
              label: "conditions.birthDate",
              type: "date",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"]
            },
            // {
            //     fact: 'customerBirthDateIsToday',
            //     label: 'conditions.birthDateIsToday',
            //     type: 'boolean'
            // },
            {
              fact: "customerAccessWithTag",
              label: "conditions.customerAccessWithTag",
              type: "numberOfType",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"],
              options: optionService.searchTag,
              async: false
            },
            {
              fact: "customerAccessToEventsWithTag",
              label: "conditions.customerAccessToEventsWithTag",
              type: "numberOfType",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"],
              options: optionService.searchTag,
              async: false
            },

            {
              fact: "customerAccessToEventsInTimeFrame",
              label: "conditions.customerAccessToEventsInTimeFrame",
              type: "number",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"]
            },
            {
              fact: "customerAccessToEventsWithTagInTimeFrame",
              label: "conditions.customerAccessToEventsWithTagInTimeFrame",
              type: "numberOfType",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"],
              options: optionService.searchTag,
              async: false
            },
            {
              fact: "customerAccessWithTagToEvent",
              label: "conditions.customerAccessWithTagToEvent",
              type: "numberOfType",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"],
              options: optionService.searchTag,
              async: false
            },
            {
              fact: "customerAccessWithTagToEventsInTimeFrame",
              label: "conditions.customerAccessWithTagToEventsInTimeFrame",
              type: "numberOfType",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"],
              options: optionService.searchTag,
              async: false
            },
            {
              fact: "customerAccessWithTagToEventsWithTagInTimeFrame",
              label: "conditions.customerAccessWithTagToEventsWithTagInTimeFrame",
              type: "numberOfTypeWithSubType",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"],
              typeOptions: optionService.searchTag,
              typeOptionsAsync: false,
              typeLabel: 'Access with tags',
              subTypeOptions: optionService.searchTag,
              subTypeLabelLabel: 'Events with tags',
              subTypeOptionsAsync: false,
            },

          ]
        },
        {
          name: "Event conditions",
          values: [
            {
              fact: "eventStartInMinutes",
              label: "conditions.eventStartInMinutes",
              type: "number",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"]
            },
            {
              fact: "eventEndInMinutes",
              label: "conditions.eventEndInMinutes",
              type: "number",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"]
            },
            {
              fact: "eventStartToday",
              label: "conditions.eventStartToday",
              type: "boolean",
              operators: ["equals"]
            },
            {
              fact: "eventWithTag",
              label: "conditions.eventWithTag",
              type: "multiselect",
              operators: ["contains", "containsAll", "containsNot"],
              options: optionService.searchTag,
              async: false
            },
            {
              fact: "customerAccessToEvent",
              label: "conditions.customerAccessToEvent",
              type: "number",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"]
            }
          ]
        },
        {
          name: "Access conditions",
          values: [
            {
              fact: "accessHasTag",
              label: "conditions.accessHasTag",
              type: "multiselect",
              operators: ["contains", "containsAll", "containsNot"],
              options: optionService.searchTag,
              async: false
            }
          ]
        },
        {
          name: "Other conditions",
          values: [
            {
              fact: "accessWithTag",
              label: "conditions.accessWithTag",
              type: "numberOfType",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"],
              options: optionService.searchTag,
              async: false
            },
            {
              fact: "currentDate",
              label: "conditions.currentDate",
              type: "datetime",
              operators: ["equals", "notEquals", "gt", "gte", "lt", "lte"]
            }
          ]
        }
      ]
    }
  }
};

//
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
