export default [
  {
    path: "/order",
    name: "order-root",
    component: () =>
      import(/* webpackChunkName: "order" */ "@/modules/order/views/OrderListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
  },

  {
    path: "/order/:id",
    name: "order-detail-root",
    component: () =>
      import(/* webpackChunkName: "order-detail" */ "@/modules/order/views/OrderDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    children: [
      {
        path: "detail",
        name: "order-detail",
        components: {
          detail: () =>
            import(/* webpackChunkName: "order-detail" */ "@/modules/order/components/OrderDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "access",
        name: "order-access",
        components: {
          detail: () =>
            import(/* webpackChunkName: "order-detail" */ "@/modules/order/components/OrderAccess.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "product",
        name: "order-product",
        components: {
          detail: () =>
              import(/* webpackChunkName: "order-detail" */ "@/modules/order/components/OrderProduct.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "payment",
        name: "order-payment",
        components: {
          detail: () =>
              import(/* webpackChunkName: "order-detail" */ "@/modules/order/components/OrderPayment.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "refund",
        name: "order-refund",
        components: {
          detail: () =>
              import(/* webpackChunkName: "order-detail" */ "@/modules/order/components/OrderRefund.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "delivery",
        name: "order-delivery",
        components: {
          detail: () =>
              import(/* webpackChunkName: "order-detail" */ "@/modules/order/components/OrderDelivery.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "delivery/:deliveryId",
        name: "order-delivery-detail",
        components: {
          detail: () =>
              import(/* webpackChunkName: "order-detail" */ "@/modules/order/components/OrderDeliveryDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      }
    ]
  }
];
