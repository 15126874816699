export const PACKAGE_DEFINITION_ERROR = "PACKAGE_DEFINITION_ERROR";
export const FLUSH_PACKAGE_DEFINITION_ERROR = "FLUSH_PACKAGE_DEFINITION_ERROR";

export const START_PACKAGE_DEFINITION_JOB = "START_PACKAGE_DEFINITION_JOB";
export const END_PACKAGE_DEFINITION_JOB = "END_PACKAGE_DEFINITION_JOB";

export const RECEIVED_PACKAGE_DEFINITIONS = "RECEIVED_PACKAGE_DEFINITIONS";
export const RECEIVED_PACKAGE_DEFINITION = "RECEIVED_PACKAGE_DEFINITION";
export const SAVED_PACKAGE_DEFINITION = "SAVED_PACKAGE_DEFINITION";
export const CREATED_PACKAGE_DEFINITION = "CREATED_PACKAGE_DEFINITION";
export const CLEAR_PACKAGE_DEFINITION = "CLEAR_PACKAGE_DEFINITION";

