import Vue from "vue";
import Router from "vue-router";
// import Home from "./views/Home.vue";
import Core from "@/modules/core/routes";
import Event from "@/modules/event/routes";
import EventTemplate from "@/modules/event-template/routes";
import Product from "@/modules/product/routes";
import Credit from "@/modules/credit/routes";
import Membership from "@/modules/membership/routes";
import Package from "@/modules/package/routes";
import Offer from "@/modules/offer/routes";
import Order from "@/modules/order/routes";
import Customer from "@/modules/customer/routes";
import Analytics from "@/modules/analytics/routes";
import Report from "@/modules/report/routes";
import System from "@/modules/system/routes";
import Account from "@/modules/account/routes";

Vue.use(Router);

// merge all routes
const baseRoutes = [
  {
    path: "/",
    // name: "home",
    // component: Home
    redirect: { name: 'login' }
  }
];
const routes = baseRoutes.concat(
  Core,
  Event,
  EventTemplate,
  Product,
  Credit,
  Membership,
  Package,
  Offer,
  Order,
  Customer,
  Analytics,
  Report,
  System,
  Account
);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // linkActiveClass: "is-active",
  // linkExactActiveClass: "is-exact-active",
  // routes: [
  //   {
  //     path: "/",
  //     name: "home",
  //     component: Home
  //   },
  //   {
  //     path: "/about",
  //     name: "about",
  //     // route level code-splitting
  //     // this generates a separate chunk (about.[hash].js) for this route
  //     // which is lazy-loaded when the route is visited.
  //     component: () =>
  //       import(/* webpackChunkName: "about" */ "./views/About.vue")
  //   }
  // ]
  routes
});
