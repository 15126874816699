export default [
    {
        path: "/credit",
        name: "credit-root",
        redirect: { name: 'credit-account' }
    },

    {
        path: "/credit/account",
        name: "credit-account",
        component: () =>
            import(/* webpackChunkName: "credit" */ "@/modules/credit/views/CreditAccountListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },

    {
        path: "/credit/type",
        // name: "credit-account-currency-list",
        name: "credit-account-currency",
        component: () =>
            import(/* webpackChunkName: "credit" */ "@/modules/credit/views/CreditAccountCurrencyListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },

    {
        path: "/credit/account/:id",
        name: "credit-account-detail-root",
        component: () =>
            import(/* webpackChunkName: "credit-account-detail" */ "@/modules/credit/views/CreditAccountDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "credit-account-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "credit-account-detail" */ "@/modules/credit/components/CreditAccountDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "transaction",
                name: "credit-account-transaction",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "credit-account-detail" */ "@/modules/credit/components/CreditAccountTransactions.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
        ]
    },

    {
        path: "/credit/type/:code",
        name: "credit-account-currency-detail-root",
        component: () =>
            import(/* webpackChunkName: "credit-account-currency-detail" */ "@/modules/credit/views/CreditAccountCurrencyDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "credit-account-currency-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "credit-account-currency-detail" */ "@/modules/credit/components/CreditAccountCurrencyDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
        ]
    }
];
