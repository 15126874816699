import i18n from "@/locals/index";
import moment from "moment";


export default {
  // translateLabels(options) {
  //     return options.map(o => {
  //         o.label = i18n.t(o.label);
  //         return o;
  //     });
  // },

  formatStatus(status) {
    return `<span class="status status--${status}">${status}</span>`;
  },

  formatTags(tags) {
    return (tags && tags.length > 0) ? tags.filter(t => t && t.name).map(t => t.name).join(', ') : ''
  },

  formatPrice(price, currency) {
    const currencySymbolPrefix = currency && currency.symbol ? currency.symbol + ' ' : '';
    const currencyExponent = currency && (currency.exponent !== null || true) ? currency.exponent : 2;
    if(price) {
      return currencySymbolPrefix +
          price
          .toFixed(currencyExponent) // always two decimal digits
          .replace(".", ",") // replace decimal point character with ,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }
    return '-';
  },


    formatPriceFromRequiredPayments(requiredPayments) {
        const self = this;
        const prices = [];
        if(requiredPayments && requiredPayments.length > 0) {
            for (let i = 0; i < requiredPayments.length; i++) {
                const price = requiredPayments[i].amount ? requiredPayments[i].amount : null;
                const currency = requiredPayments[i].currency ? requiredPayments[i].currency : null;
                if(price && currency) {
                    prices.push(self.formatPrice(price, currency))
                }
            }
            return prices.join(' + ');
        }
        return '-';
    },

  // getCurrencySymbol(currency) {
  //   if (currency === "EUR") return "€";
  //   return "€";
  // },


    formatValidityPeriod(validityPeriod) {
        if(validityPeriod && validityPeriod.type && validityPeriod.type === 'forDuration') {
            return i18n.t('membership.validityPeriodDescription.forDuration') + ' ' + validityPeriod.value + ' ' + i18n.t('membership.validityPeriodUnit.' + validityPeriod.unit);
        }
        if(validityPeriod && validityPeriod.type && validityPeriod.type === 'fixed') {
            return i18n.t('membership.validityPeriodDescription.fixed') + ' ' + moment(validityPeriod.start).format("MMM Do YYYY @ HH:mm") + ' - ' + moment(validityPeriod.end).format("MMM Do YYYY @ HH:mm");
        }
        if(validityPeriod && validityPeriod.type && validityPeriod.type === 'indefinitely') {
            return i18n.t('membership.validityPeriodDescription.indefinitely')
        }
        return '';
    },

    formatEventDate(date) {
        return moment(date).format("MMMM Do YYYY @ HH:mm")
    },

    formatTextTranslations(translations) {
        if(translations && Array.isArray(translations)) {
            return translations
                .map((translation) => {
                    return `<div>${translation.languageCode}: ${translation.text}</div>`;
                })
                .join('');
        }
        return '';
    },

  formatConditions(conditions) {
    let html = '';
    const indent = (depth) => {
      let d = '';
      for (let i = 0; i < depth; i++) {
        d += '<span style="display: inline-block; width: 10px;"></span>';
      }
      return d;
    };
    const calculatePrice = (condition) => {
      let p = condition.price;
      if(condition.taxes && Array.isArray(condition.taxes)) {
          let totalTax = 0;
          condition.taxes.forEach((tax) => {
              if(tax.type === 'percentage') {
                  totalTax += condition.price * tax.value;
              }
              if(tax.type === 'fixed') {
                  totalTax += tax.value;
              }
          });
          p += totalTax;
      }
      return p.toFixed(2)
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    };
    const format = function(condition, depth) {
      const conditionDepth = depth-1;
      if (condition.operator && condition.conditions) {
        condition.conditions.forEach((c, i) => {
          if(i > 0) {
            // html += "<div>" + indent(depth) + i18n.t('operators.' + condition.operator) + "</div>";
          }
          format(c, depth+1);
        });
      } else if(condition && condition.fact === "price") {
        // const price = condition.price ? condition.price.toFixed(2)
        //     .replace(".", ",")
        //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
        //     : null;
        const price = condition.price ? calculatePrice(condition) : i18n.t('conditions.free');
        html += "<div>" + condition.name + ": " + price  + " (" + condition.currency + ")</div>";
      } else if(condition && condition.fact === "free") {
        html += "<div>" + condition.name + ": " + i18n.t('conditions.free') + "</div>";
      } else {
        let value = condition.value;
        if(condition.type === 'multiselect') value = condition.value.map(v => {
          if(v && v.name) {
            return v.name;
          } else {
            return v;
          }
        }).join(', ');
        if(condition.type === 'date') value = moment(condition.value).format("MMMM Do YYYY @ HH:mm");
        // html += "<div>" + indent(conditionDepth) + i18n.t('conditions.' + condition.fact) + " " + i18n.t('operators.' + condition.operator).toLowerCase() + " " + value + "</div>";
      }
    };


    if (conditions) {
      format(conditions, 0);
    }
    return html;
  },


    formatFullConditions(conditions) {

        const formatPriceCondition = (condition) => {
            let p = condition.price;
            if(condition && condition.taxes && Array.isArray(condition.taxes)) {
                let totalTax = 0;
                condition.taxes.forEach((tax) => {
                    if(tax.type === 'percentage') {
                        totalTax += condition.price * tax.value;
                    }
                    if(tax.type === 'fixed') {
                        totalTax += tax.value;
                    }
                });
                p += totalTax;
            }
            const price = p.toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
            return `<div>${condition.name}: ${price} (${condition.currency})</div>`;
        };
        const formatFreeCondition = (condition) => {
            return `<div>Free</div>`;
        };
        const formatConditionValue = (value) => {
            if(value && Array.isArray(value) && value.length > 0 && value[0].name) {
                return value.map((v) => v.name).join(', ');
            }
            if(value && value.type && value.number) {
                // return value.type.map((v) => v.name).join(', ');
                return value.number;
            }
            if(value && value.name) {
                return value.name;
            }
            return value;
        };
        const formatConditionType = (value) => {
            if(value && value.type && value.number && Array.isArray(value.type)) {
                return value.type.map((v) => v.name).join(', ');
            }
            return null;
        };
        const formatCondition = (condition) => {
            if(condition && condition.conditions) {
                return formatGroup(condition);
            }
            if(condition && condition.fact === "price") {
                return formatPriceCondition(condition);
            }
            if(condition && condition.fact === "free") {
                return formatFreeCondition(condition);
            }
            const type = formatConditionType(condition.value);
            const typeHtml = type ? `<span style="margin-left: 5px;">${type}</span>` : '';
            return `<div><span>${i18n.t('conditions.' + condition.fact)}</span>${typeHtml}<span style="margin-left: 5px;">${i18n.t('operators.' + condition.operator)}</span><span style="margin-left: 5px;">${formatConditionValue(condition.value)}</span></div>`;
        };
        const formatGroup = (group, hideOperator) => {
            if(hideOperator === true) {
                return `<div>${group.conditions.map(formatCondition).join('')}</div>`;
            }
            return `<div><div>${group.operator}</div><div style="margin-left: 15px;">${group.conditions.map(formatCondition).join('')}</div></div>`;
        };

        return formatGroup(conditions, true);
    }

};
