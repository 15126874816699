export default {
  // create array of capacities with capacity path
  getCapacityLocations(capacity, parentPath = []) {
    // return capacity && capacity.children
    //   ? capacity.children.map(c => c.location)
    //   : [];
    const self = this;
    let locations = [];
    const path = parentPath.concat([]);
    path.push(capacity.location);
    if(capacity.type !== 'aggregated') {
      locations.push(path.join('.'));
      // locations.push({locationPath: path.join('.'), name: capacity.name});
    }
    if(capacity.children && capacity.children.length > 0) {
      capacity.children.forEach(child => {
        const cl = self.getCapacityLocations(child, path);
        locations = locations.concat(cl);
      });
    }
    return locations;
  },


  getCapacityLocationOptions(capacity, parentPath = []) {
    const self = this;
    let locations = [];
    const path = parentPath.concat([]);
    path.push(capacity.location);
    if(capacity.type !== 'aggregated') {
      locations.push({path: path.join('.'), name: capacity.name + ' (' + path.join('.') + ')'});
      // locations.push({locationPath: path.join('.'), name: capacity.name});
    }
    if(capacity.children && capacity.children.length > 0) {
      capacity.children.forEach(child => {
        const cl = self.getCapacityLocationOptions(child, path);
        locations = locations.concat(cl);
      });
    }
    return locations;
  }

};
