export default {
  jobs: state => state.jobs,
  error: state => state.error,
  users: state => state.users,
  user: state => state.user,
  tags: state => state.tags,
  tag: state => state.tag,
  coupons: state => state.coupons,
  coupon: state => state.coupon,
  tokens: state => state.tokens,
  emailTemplates: state => state.emailTemplates,
  emailTemplate: state => state.emailTemplate,
  emailTemplateAttachments: state => {
    return state.emailTemplate && state.emailTemplate.attachments ? state.emailTemplate.attachments.map((a, i) => {
      a.emailTemplateId = state.emailTemplate.id;
      a.index = i.toString();
      return a;
    }) : []
  },
  documentTemplates: state => state.documentTemplates,
  documentTemplate: state => state.documentTemplate,
  salesChannels: state => state.salesChannels,
  salesChannel: state => state.salesChannel,
  registers: state => {
    return state.salesChannel && state.salesChannel.registers ? state.salesChannel.registers.map(r => {
      r.salesChannelId = state.salesChannel.id;
      return r;
    }) : []
  },
  paymentMethods: state => state.paymentMethods,
  paymentMethod: state => state.paymentMethod,
  optInDefinitions: state => state.optInDefinitions,
  optInDefinition: state => state.optInDefinition,
  orderMessageDefinitions: state => state.orderMessageDefinitions,
  orderMessageDefinition: state => state.orderMessageDefinition,
  integrationProductDefinitions: state => state.integration.productDefinitions,
  integrationPrices: state => state.integration.prices,
  fionaIntegrationConfig: state => state.integration.fiona.config,
  fionaIntegrationConfigSalesChannelId: state => state.integration.fiona.config.salesChannelId,
  fionaIntegrationEntityMaps: state => state.integration.fiona.entityMaps,
  fionaIntegrationEntityMap: state => state.integration.fiona.entityMap,
  fionaIntegrationAccreditations: state => state.integration.fiona.accreditations,
  fionaIntegrationVolunteers: state => state.integration.fiona.volunteers,
  fionaIntegrationEvents: state => state.integration.fiona.events,
  fionaIntegrationSyncQueue: state => state.integration.fiona.syncQueue,
  fionaIntegrationProxyEditions: state => state.integration.fiona.proxy.editions,
  fionaIntegrationProxyEditionTypes: state => state.integration.fiona.proxy.editionTypes,
  fionaIntegrationProxyGuestbooks: state => state.integration.fiona.proxy.guestbooks,
  fionaIntegrationProxyAccreditations: state => state.integration.fiona.proxy.accreditations,
  fionaIntegrationProxyVolunteers: state => state.integration.fiona.proxy.volunteers,
  fionaIntegrationProxyVolunteerEditions: state => state.integration.fiona.proxy.volunteerEditions,
  fionaIntegrationProxyShows: state => state.integration.fiona.proxy.shows,
  fionaIntegrationProxyAccreditationBadgeTypes: state => state.integration.fiona.proxy.accreditationBadgeTypes,
  fionaIntegrationProxyAccreditationBadgeStatus: state => state.integration.fiona.proxy.accreditationBadgeStatus,
  fionaIntegrationProxyVolunteerBadgeTypes: state => state.integration.fiona.proxy.volunteerBadgeTypes,
};
