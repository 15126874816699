export default [
    {
        path: "/report",
        name: "report-root",
        redirect: { name: 'report-ledger' }
        // component: () =>
        //     import(/* webpackChunkName: "report" */ "@/modules/report/views/ReportList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },
    {
        path: "/report/ledger",
        name: "report-ledger",
        component: () =>
            import(/* webpackChunkName: "report" */ "@/modules/report/views/LedgerList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    }
];
