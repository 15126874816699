import * as mutationTypes from "../mutation-types";
import * as jobTypes from "../job-types";
import BackendService from "@/services/backend.service";
import GraphqlQueryBuilder from "@/services/GraphqlQueryBuilder.service";
import validationService from "@/services/validation.service";
// import router from '@/router'
const backoffice = new BackendService();
import router from "@/router";


export default {

    async getCreditAccounts({ commit, state }, {rows, sort, columns, filters}) {
        // build query
        const builder = new GraphqlQueryBuilder();
        builder.setType("creditAccounts");
        builder.setRows(rows);
        builder.setSort(sort);
        builder.setColumns(columns, ["id"]);
        if( validationService.validateDatatableFilters(filters) ) {
            builder.setFilters(filters);
        }
        const query = builder.build();

        //
        const jobType = jobTypes.GET_CREDIT_ACCOUNT;
        commit(mutationTypes.START_CREDIT_JOB, jobType);
        const result = await backoffice.sendQuery(query);
        if (result.errors) {
            commit(mutationTypes.CREDIT_ERROR, result.error);
            commit(mutationTypes.END_CREDIT_JOB, jobType);
        } else {
            commit(mutationTypes.RECEIVED_CREDIT_ACCOUNTS, { creditAccounts: result.data.creditAccounts });
            commit(mutationTypes.END_CREDIT_JOB, jobType);
        }
    },

    async getCreditAccount({ commit, state }, {id}) {
        const jobType = jobTypes.GET_CREDIT_ACCOUNT;
        commit(mutationTypes.START_CREDIT_JOB, jobType);
        commit(mutationTypes.CLEAR_CREDIT_ACCOUNT);
        const query = `query { creditAccount(id: "${id}"){ id,type,status,currencyCode,balance,customer{id,fullName},transactions{id,date,currencyCode,amount,status,type,to,from,paymentId,orderId,reference,webHookUrl,remark} }}`;
        const result = await backoffice.sendQuery(query);
        if (result.error) {
            commit(mutationTypes.CREDIT_ERROR, result.error);
            commit(mutationTypes.END_CREDIT_JOB, jobType);
        } else {
            // if (result.data.productDefinition && result.data.productDefinition.conditionsString) {
            //     result.data.productDefinition.productConditions = JSON.parse(result.data.productDefinition.conditionsString);
            commit(mutationTypes.RECEIVED_CREDIT_ACCOUNT, { creditAccount: result.data.creditAccount });
            // }
            commit(mutationTypes.END_CREDIT_JOB, jobType);
        }
    },

    async getCreditAccountCurrencies({ commit, state }, {rows, sort, columns, filters}) {
        // build query
        const builder = new GraphqlQueryBuilder();
        builder.setType("creditAccountCurrencies");
        builder.setRows(rows);
        builder.setSort(sort);
        builder.setColumns(columns, ["id"]);
        if( validationService.validateDatatableFilters(filters) ) {
            builder.setFilters(filters);
        }
        const query = builder.build();

        //
        const jobType = jobTypes.GET_CREDIT_ACCOUNT_CURRENCY;
        commit(mutationTypes.START_CREDIT_JOB, jobType);
        const result = await backoffice.sendQuery(query);
        if (result.errors) {
            commit(mutationTypes.CREDIT_ERROR, result.error);
            commit(mutationTypes.END_CREDIT_JOB, jobType);
        } else {
            commit(mutationTypes.RECEIVED_CREDIT_ACCOUNT_CURRENCIES, { creditAccountCurrencies: result.data.creditAccountCurrencies });
            commit(mutationTypes.END_CREDIT_JOB, jobType);
        }
    },

    async getCreditAccountCurrency({ commit, state }, {code}) {
        const jobType = jobTypes.GET_CREDIT_ACCOUNT_CURRENCY;
        commit(mutationTypes.START_CREDIT_JOB, jobType);
        commit(mutationTypes.CLEAR_CREDIT_ACCOUNT);
        const query = `query { creditAccountCurrency(code: "${code.toUpperCase()}"){id,code,name,exponent,symbol,receivingAccountNumber} }`;
        const result = await backoffice.sendQuery(query);
        if (result.error) {
            commit(mutationTypes.CREDIT_ERROR, result.error);
            commit(mutationTypes.END_CREDIT_JOB, jobType);
        } else {
            commit(mutationTypes.RECEIVED_CREDIT_ACCOUNT_CURRENCY, { creditAccountCurrency: result.data.creditAccountCurrency });
            commit(mutationTypes.END_CREDIT_JOB, jobType);
        }
    },

    async saveCreditMutations({ commit, state }, mutations) {
        const jobType = jobTypes.SAVE_CREDIT_ACCOUNT_CURRENCY;
        commit(mutationTypes.START_CREDIT_JOB, jobType);
        const result = await backoffice.sendCommandBatch(mutations);
        if (result.error) {
            commit(mutationTypes.CREDIT_ERROR, result.error);
            commit(mutationTypes.END_CREDIT_JOB, jobType);
        } else {
            commit(
                mutationTypes.SAVED_CREDIT_ACCOUNT_CURRENCY,
                mutations.reduce(
                    (state, command) => Object.assign(state, command.data),
                    {}
                )
            );
            commit(mutationTypes.END_CREDIT_JOB, jobType);
        }
    },

    async createCreditAccountCurrency({ commit, state }, {name, code, exponent, symbol}) {
        const jobType = jobTypes.SAVE_CREDIT_ACCOUNT_CURRENCY;
        commit(mutationTypes.START_CREDIT_JOB, jobType);
        const result = await backoffice.sendCommand('CreateAccountCurrency', {name, code, exponent, symbol});
        if (result.error) {
            commit(mutationTypes.CREDIT_ERROR, result.error);
            commit(mutationTypes.END_CREDIT_JOB, jobType);
        } else {
            router.push({ name: "credit-account-currency-detail", params: { code: code } }, () => {router.go()});
            commit(mutationTypes.END_CREDIT_JOB, jobType);
        }
    },



    async startBatchOperation({ commit, dispatch }, operations) {
        const jobName = 'start-batch-operation';
        commit(mutationTypes.START_CREDIT_JOB, jobName);
        await dispatch('core/startBatchOperation', operations, {root:true});
        commit(mutationTypes.END_CREDIT_JOB, jobName);
    },

    clearCreditAccount({ commit, state }) {
        commit(mutationTypes.CLEAR_CREDIT_ACCOUNT);
    },

    clearCreditAccountCurrency({ commit, state }) {
        commit(mutationTypes.CLEAR_CREDIT_ACCOUNT_CURRENCY);
    },


};
