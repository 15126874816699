import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

// initial state
const state = {
    jobs: [],
    error: [],
    ledger: [],
};

//
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
