import * as mutationTypes from "../mutation-types";
import BackendService from "@/services/backend.service";
// import router from '@/router'

const backoffice = new BackendService();

export default {
  /**
   * Get list of orders
   * @param commit
   * @param state
   * @param query             GraphQL query string
   * @returns {Promise<void>}
   */
  async getOrders({ commit, state }, query) {
    // const result = {
    //     "data": {
    //     }
    // };
    commit(mutationTypes.START_ORDER_JOB, "get-order");
    // const backoffice = new BackendService();
    const result = await backoffice.sendQuery(query);
    if (result.errors) {
      commit(mutationTypes.ORDER_ERROR, result.error);
      commit(mutationTypes.END_ORDER_JOB, "get-order");
    } else {
      commit(mutationTypes.RECEIVED_ORDERS, { orders: result.data.orders });
      commit(mutationTypes.END_ORDER_JOB, "get-order");
      // commit(mutationTypes.SET_ORDER_LIST_ROWS, {total: result.total});
    }
  },

  // async getOrder({ commit, state }, query) {
  async getOrder({ commit, state }, {id}) {
    // const result = {
    //     "data": {
    //     }
    // };
    commit(mutationTypes.START_ORDER_JOB, "get-order");
    commit(mutationTypes.CLEAR_ORDER);
    // const backoffice = new BackendService();
    const query = `query { order(id: "${id}"){id,salesChannel{id,name,description},createDate,number,alerts,paymentStatus,tokens{id,typeId,token},payments{id,currency{name,code,exponent,symbol},amount,status,method,psp,pspReference},refunds{id,currency{code,name,exponent,symbol},amount,status,refundMethod},requiredPayments{currencyCode,currency{name,code,exponent,symbol}},registerId,status,email,customer{id,fullName},createDate,lineItems{ ... on AccessLineItem {id,name,type,status,currency{name,code,exponent,symbol},price,tax,accessDefinition{id},capacityLocationPath,accessId,event{id,name,location,start,end}} ... on ProductLineItem {id,type,status,price,tax,currency{name,code,exponent,symbol},requestedConditionPath,productId,productDefinition{id,name}} }}}`;
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.ORDER_ERROR, result.error);
      commit(mutationTypes.END_ORDER_JOB, "get-order");
    } else {
      commit(mutationTypes.RECEIVED_ORDER, { order: result.data.order });
      commit(mutationTypes.END_ORDER_JOB, "get-order");
    }
  },


  async getOrderEmails({ commit, state }, {id}) {
    commit(mutationTypes.START_ORDER_JOB, "get-order-email");
    const query = `query { emails(filters: {orderId: {equals: "${id}"}}){id,from,to,subject,body,senderName,cc,bcc,createDate,status,template{name},createDate,orderId,customerId} }`;
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.ORDER_ERROR, result.error);
      commit(mutationTypes.END_ORDER_JOB, "get-order-email");
    } else {
      commit(mutationTypes.RECEIVED_ORDER_EMAILS, { emails: result.data.emails });
      commit(mutationTypes.END_ORDER_JOB, "get-order-email");
    }
  },

  /**
   * Send order command
   * @param commit
   * @param state
   * @param mutations                     [ { type: '', data: {} } ]
   * @returns {Promise<void>}
   */
  async saveOrderMutations({ commit, state }, mutations) {
    commit(mutationTypes.START_ORDER_JOB, "save-order");
    // const backoffice = new BackendService();
    const result = await backoffice.sendCommandBatch(mutations);
    if (result.error) {
      commit(mutationTypes.ORDER_ERROR, result.error);
      commit(mutationTypes.END_ORDER_JOB, "save-order");
    } else {
      commit(
        mutationTypes.SAVED_ORDER,
        mutations.reduce(
          (state, command) => Object.assign(state, command.data),
          {}
        )
      );
      commit(mutationTypes.END_ORDER_JOB, "save-order");
    }
  },

  async cancelOrder({ commit, state }, data) {
    commit(mutationTypes.START_ORDER_JOB, "save-order");
    // const backoffice = new BackendService();
    const result = await backoffice.sendCommand("CancelOrder", data);
    if (result.error) {
      commit(mutationTypes.ORDER_ERROR, result.error);
      commit(mutationTypes.END_ORDER_JOB, "save-order");
    } else {
      commit(mutationTypes.SAVED_ORDER, { status: "canceled" });
      commit(mutationTypes.END_ORDER_JOB, "save-order");
    }
  },

  // async createNewOrder({ commit, state }, data) {
    // commit(mutationTypes.START_ORDER_JOB, 'save-order');
    // const backoffice = new BackendService();
    // let result = await backoffice.sendCommand('CreateOrderManager', {capacityAllocation: 'static'});
    // if(!result.error) {
    //     data.aggregateId = result.data.orderId;
    //     result = await backoffice.sendCommand('PlanOrder', data);
    // }
    //
    // if (result.error) {
    //     commit(mutationTypes.ORDER_ERROR, result.error);
    //     commit(mutationTypes.END_ORDER_JOB, 'save-order');
    // } else {
    //     data.orderId = result.data.orderId;
    //     commit(mutationTypes.SAVED_ORDER, data);
    //     commit(mutationTypes.END_ORDER_JOB, 'save-order');
    //     router.push({name: 'order-detail-root', params: {id: data.orderId}});
    // }
  // },

  async startBatchOperation({ commit, dispatch }, operations) {
    const jobName = 'start-batch-operation';
    commit(mutationTypes.START_ORDER_JOB, jobName);
    await dispatch('core/startBatchOperation', operations, {root:true});
    commit(mutationTypes.START_ORDER_JOB, jobName);
  },

  clearOrder({ commit, state }) {
    commit(mutationTypes.CLEAR_ORDER);
  },

  setOrderListSelectedColumns({ commit, state }, selectedColumns) {
    commit(mutationTypes.SELECT_ORDER_LIST_COLUMNS, selectedColumns);
  },

  setOrderListSelectedFilters({ commit, state }, selectedFilters) {
    commit(mutationTypes.FILTER_ORDER_LIST, selectedFilters);
  },

  setOrderListRows({ commit, state }, rows) {
    commit(mutationTypes.SET_ORDER_LIST_ROWS, rows);
  },

  setOrderListSort({ commit, state }, sort) {
    commit(mutationTypes.SORTED_ORDER_LIST, sort);
  }

};
