import i18n from "@/locals/index";
import _ from "lodash";
import moment from "moment";

export default {

    validateRequired(value, field, model) {
        const errors = [];
        if(field.required && (value === null || value === '')) {
            errors.push(i18n.t('validation.fieldIsRequired'));
        }
        return errors
    },

    validateDatatableFilters(filters) {
        let valid = true;
        if(filters && filters.selected) {
            filters.selected.forEach(f => {
                // if(f.label === null || f. key === null || f.operator === null || f.value === null) {
                if(f.label === null || f. key === null || f.operator === null || (f.value === null && !['isNull', 'notNull'].includes(f.operator))) {
                    valid = false;
                }
            });
        }
        return valid;
    },


    validateDelivery(value, field, model) {
        const errors = [];
        if(field.required && (value.type === null || value.type === '') && (value.deliveryTemplate === null)) {
            errors.push(i18n.t('validation.deliveryTemplateIsRequired'));
        }
        return errors
    },


    validateEmailAddress(value, field, model) {
        const errors = [];

        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if( !re.test(String(value).toLowerCase()) && value !== null && value !== '' ) {
            errors.push(i18n.t('validation.invalidEmail'))
        }

        if(field.required && (value === null || value === '')) {
            errors.push(i18n.t('validation.fieldIsRequired'));
        }
        return errors
    },


    validateValidityPeriod(value, field, model) {
        const errors = [];

        if(value && value.type === 'forDuration') {
            if(value.unit === null || value.unit === '') {
                errors.push(i18n.t('validation.invalidValidityPeriodUnit'));
            }
            if(value.value === null || !Number.isInteger(parseInt(value.value))) {
                errors.push(i18n.t('validation.invalidValidityPeriodValue'));
            }
        }
        if(value && value.type === 'fixed') {
            if(value.start === null || value.start === '' || value.start === 'Invalid date') {
                errors.push(i18n.t('validation.validityPeriodStartDateMissing'));
            }
            if(value.end === null || value.end === '' || value.end === 'Invalid date') {
                errors.push(i18n.t('validation.validityPeriodEndDateMissing'));
            }
            if(moment(value.start).isAfter(moment(value.end))) {
                errors.push(i18n.t('validation.validityPeriodEndDateBeforeStartDate'));
            }
        }

        return errors
    },


    validateEventCapacity(capacity, field, model) {
// console.log(capacity.name);
//         if( !capacity.name || capacity.name === '' ) {
//             return ['']
//         }
        //
        // if( !children || children.length === 0 ) {
        //     errors = [this.languageSettings.validation.capacityNeedsGroup];
        //     return errors
        // }
        //
        // for (let i = 0; i < children.length; i++) {
        //     errors = this.validateGroup(children[i]);
        //     if(errors.length > 0) {
        //         return errors;
        //     }
        // }
        // return errors;


        return []
    },


    /**
     * Validate the selected capacities at access detail form
     * @param value
     * @param field
     * @param model
     * @returns {Array}
     */
    validateAccessCapacity(value, field, model) {
        if( (value && Array.isArray(value) && value.length === 0) || (!value) ) {
            return [i18n.t('validation.fieldIsRequired')];
        }
        return []
    },


    validateConditions(value, field, model) {
        const isGroup = (condition) => {
            return condition && condition.conditions
        };
        const isPrice = (condition) => {
            return condition && condition.fact && condition.fact === 'price';
        };
        const isFreePrice = (condition) => {
            return condition && condition.fact && condition.fact === 'free';
        };
        const validateCondition = (condition) => {
// console.log(condition);
//             if(condition && condition.fact && condition.operator && condition.value) {
// console.log('condition fail');
//                 return ['dd'];
//             }
// console.log('condition success');
            return [];
        };
        const validatePrice = (condition) => {
            // if(condition.price && condition.price <= 0) {
            //     return ['Price must be a number larger than 0.'];
            // }
            // if(!condition.currency) {
            //     return ['Price currency must be set.'];
            // }
            // if(condition.name === null || condition.name === '') {
            //     return ['Price name must be set.'];
            // }
            return [];
        };
        const validateFreePrice = (condition) => {
            // if(condition && condition.name && condition.name !== '') {
            //     return ['Free price name must be set'];
            // }
            return [];
        };
        const validate = (condition) => {
            const result = [];
            if(isGroup(condition)) {
                condition.conditions.forEach((condition) => {
                    result.push( validate(condition) );
                })
            } else if(isPrice(condition)) {
                result.push( validatePrice(condition) );
            } else if(isFreePrice(condition)) {
                result.push( validateFreePrice(condition) );
            } else {
                result.push( validateCondition(condition) );
            }
            return result;
        };

        const validationResult = validate(value);
// console.log(_.flattenDeep(validationResult));
        return _.flattenDeep(validationResult);
    },



    validateAttachmentFilename(value, field, model) {
        const errors = [];

        if(field.required && (value === null || value === '')) {
            errors.push(i18n.t('validation.fieldIsRequired'));
        }

        const allowedExtentions = ['pdf', 'PDF'];
        const extention = value ? value.split('.').pop() : '';
        if( errors.length === 0 && !allowedExtentions.includes(extention) ) {
            errors.push(i18n.t('validation.extensionMustBePdf'))
        }

        return errors
    },

}
