import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import moment from "moment";
import formatService from "@/services/format.service";
import optionService from "@/services/option.service";
import validationService from "@/services/validation.service";
import validators from 'vue-form-generator/src/utils/validators'

// initial state
const state = {
  error: [],
  jobs: [],
  events: [],
  event: {},
  eventAttendees: [],
  eventAttendeeImportPriceList: [],
  accessDefinitions: [],
  accessDefinition: {},
  capacities: [],
  // nameSuggestions: [],

  // event list view settings
  eventListView: {
    columns: {
      options: [
        // all available columns
        {
          key: "name",
          label: "common.name", // translation key for the header label
          link: {
            routeName: "event-detail",
            params: [{ paramKey: "id", valueKey: "id" }]
          }, // route params contains a params map for mapping row values to params properties
          filter: {
            type: "text"
          }
        },
        {
          key: "id",
          label: "common.id"
        },
        {
          key: "description",
          label: "common.description"
        },
        {
          key: "start",
          label: "common.start",
          format: function(value) {
            return moment(value).format("MMMM Do YYYY @ HH:mm");
          },
          filter: {
            type: "date"
          }
        },
        {
          key: "end",
          label: "common.end",
          format: function(value) {
            return moment(value).format("MMMM Do YYYY @ HH:mm");
          },
          filter: {
            type: "date"
          }
        },
        {
          key: "location",
          label: "event.location",
          filter: {
            type: "text"
          }
        },
        {
          key: "status",
          label: "common.status",
          format: function(value) {
            return `<span class="status status--${value}">${value}</span>`;
          },
          filter: {
            type: "enum",
            options: [{label: 'Planned', value: 'planned'}, {label: 'Canceled', value: 'canceled'}, {label: 'Published', value: 'published'}, {label: 'Draft', value: 'draft'}]
          }
        },
        {
          key: "tags",
          label: "common.tags",
          format: function(value) {
            return Array.isArray(value) && value.length > 0 ? value.map((v) => v.name).join(', ') : '';
          },
          filter: {
            type: "enum",
            // options: optionService.searchTag
            // options: optionService.tagsAsLabelValue
            options: optionService.tags,
            multiple: true
          }
        },
        {
          key: "totalCapacity",
          label: "capacity.total",
          filter: {
            type: "number"
          }
        },
        {
          key: "availableCapacity",
          label: "capacity.available",
          filter: {
            type: "number"
          }
        },
        {
          key: "reservedCapacity",
          label: "capacity.reserved",
          filter: {
            type: "number"
          }
        },
        {
          key: "grantedCapacity",
          label: "capacity.granted",
          filter: {
            type: "number"
          }
        },
        {
          key: "usedCapacity",
          label: "capacity.used",
          filter: {
            type: "number"
          }
        },
        {
          key: "eventTemplateApplyStatus",
          label: "event.templateStatus",
          format: function(value) {
            if(value === 'up to date') {
              return `<span class="status status--success">${value}</span>`;
            }
            return `<span class="status status--error">${value}</span>`;
          },
          // filter: {
          //   type: "text"
          // }
        },
        {
          key: "eventTemplate.name",
          label: "event.templateName",
          link: {
            routeName: "event-template-event-detail",
            params: [{ paramKey: "id", valueKey: "eventTemplate.id" }]
          },
          filter: {
            key: "eventTemplateId", // overrule filter field, use different field for filtering than display field
            type: "enum",
            operators: ['equals', 'notEquals', 'isNull', 'notNull'], // overrule default operators
            options: optionService.eventTemplates
          }
        },


        {
          key: "eventCapacityTemplateApplyStatus",
          label: "event.capacityTemplateStatus",
          format: function(value) {
            if(value === 'up to date') {
              return `<span class="status status--success">${value}</span>`;
            }
            return `<span class="status status--error">${value}</span>`;
          },
        },
        {
          key: "eventCapacityTemplate.name",
          label: "event.capacityTemplateName",
          link: {
            routeName: "event-template-capacity-detail",
            params: [{ paramKey: "id", valueKey: "eventCapacityTemplate.id" }]
          },
          filter: {
            key: "eventCapacityTemplateId", // overrule filter field, use different field for filtering than display field
            type: "enum",
            operators: ['equals', 'notEquals', 'isNull', 'notNull'], // overrule default operators
            options: optionService.eventCapacityTemplates
          }
        },
        {
          key: "eventAccessTemplateApplyStatus",
          label: "event.accessTemplateStatus",
          format: function(value) {
            if(value === 'up to date') {
              return `<span class="status status--success">${value}</span>`;
            }
            return `<span class="status status--error">${value}</span>`;
          },
        },
        {
          key: "eventAccessTemplate.name",
          label: "event.accessTemplateName",
          link: {
            routeName: "event-template-access-detail",
            params: [{ paramKey: "id", valueKey: "eventAccessTemplate.id" }]
          },
          filter: {
            key: "eventAccessTemplateId", // overrule filter field, use different field for filtering than display field
            type: "enum",
            operators: ['equals', 'notEquals', 'isNull', 'notNull'], // overrule default operators
            options: optionService.eventAccessTemplates
          }
        },
        {
          key: "alertsCount",
          label: "common.alerts",
          filter: {
            type: "number"
          }
        },



      ],
      selected: [
        "common.name",
        "common.start",
        "event.location",
        "common.status",
        "capacity.available",
        "capacity.granted",
        "capacity.total",
      ] // labels of columns that are displayed
    },
    rows: {
      total: 0, // total rows in search result
      max: 20, // maximum number of rows to display at once, elastic search query size
      from: 0 // show rows from row number, elastic search query from
    },
    filters: {
      selected: []
    },
    sort: {
      key: "start",
      direction: "desc"
    },
    // bulkActions: ["actions.merge"],
    bulkActions: [
      "event.publish",
      "event.draft",
      "event.cancel",
      "event.applyTemplate",
      // "event.detachTemplate",
      "event.applyCapacityTemplate",
      // "event.detachCapacityTemplate",
      "event.applyAccessTemplate"
      // "event.detachAccessTemplate"
    ],
    menu: [
      // [
      //   {
      //     name: "name11",
      //     label: "Label 1 with a longer name",
      //     icon: "shopping-cart-alt"
      //   },
      //   {
      //     name: "name12",
      //     label: "Label 2",
      //     icon: "shopping-cart-alt"
      //   },
      //   {
      //     name: "name13",
      //     label: "Label 3",
      //     icon: "shopping-cart-alt"
      //   }
      // ],
      // [
      //   {
      //     name: "name21",
      //     label: "Label 1",
      //     icon: "folder-check"
      //   }
      // ],
      // [
      //   {
      //     name: "name31",
      //     label: "Label 1",
      //     icon: "shopping-cart-alt"
      //   },
      //   {
      //     name: "name32",
      //     label: "Label 2",
      //     icon: "box"
      //   },
      //   {
      //     name: "name33",
      //     label: "Label 3",
      //     icon: "ticket"
      //   }
      // ]
    ]
  },

  // event detail view settings
  eventDetailView: {
    tabs: [
      { routeName: "event-detail", label: "common.details" },
      { routeName: "capacity-detail", label: "common.capacity" },
      { routeName: "access-definition-list", label: "common.access" },
      { routeName: "attendee-list", label: "event.attendees" }
      // {routeName: 'event-plan', label: 'Rule builder example'},
    ],

    // menu: [
    //   [
    //     {
    //       name: "cancel-event",
    //       label: "event.cancel",
    //       icon: "times"
    //     },
    //     {
    //       name: "publish-event",
    //       label: "event.publish",
    //       icon: "cloud-upload"
    //     },
    //     {
    //       name: "draft-event",
    //       label: "event.draft",
    //       icon: "cloud-download"
    //     },
    //     {
    //       name: "apply-event-template",
    //       label: "event.applyTemplate",
    //       icon: "file-plus-alt"
    //     },
    //     {
    //       name: "detach-event-template",
    //       label: "event.detachTemplate",
    //       icon: "file-minus-alt"
    //     },
    //     {
    //       name: "apply-capacity-template",
    //       label: "event.applyCapacityTemplate",
    //       icon: "file-plus-alt"
    //     },
    //     {
    //       name: "detach-capacity-template",
    //       label: "event.detachCapacityTemplate",
    //       icon: "file-minus-alt"
    //     },
    //     {
    //       name: "apply-access-template",
    //       label: "event.applyAccessTemplate",
    //       icon: "file-plus-alt"
    //     },
    //     {
    //       name: "detach-access-template",
    //       label: "event.detachAccessTemplate",
    //       icon: "file-minus-alt"
    //     },
    //     {
    //       name: "scanner-config-page",
    //       label: "event.scannerConfigPage",
    //       icon: "print"
    //     }
    //   ]
    // ],

    form: {
      schema: {
        groups: [
          {
            // legend: 'common.general',
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "common.name",
                icon: "label-alt",
                model: "name",
                command: "RenameEvent",
                // hint: "hint",
                // help: "help",
                placeholder: "",
                // featured: true,
                required: true,
                // validator: validators.string
                validator: "string"
              },
              {
                type: "textArea",
                rows: 3,
                label: "common.description",
                icon: "paragraph",
                model: "description",
                command: "RenameEvent",
                placeholder: ""
              },
              {
                type: "input",
                inputType: "text",
                label: "event.location",
                icon: "map-marker",
                model: "location",
                command: "RelocateEvent",
                placeholder: ""
              },
              {
                type: "teDateTime",
                inputType: "datetime",
                required: true,
                range: true,
                label: "event.timeFrame",
                icon: "clock",
                model: "start",
                format: (value, field, data) => {
                  const end =
                    field &&
                    field.modelEndDate &&
                    data &&
                    data[field.modelEndDate]
                      ? " ~ " + moment(data[field.modelEndDate]).format("MMMM Do YYYY @ HH:mm")
                      : "";
                  return moment(value).format("MMMM Do YYYY @ HH:mm") + end;
                },
                modelEndDate: "end",
                command: "RescheduleEvent",
                placeholder: ""
              },
              {
                type: "teDateTime",
                inputType: "datetime",
                required: false,
                range: true,
                label: "event.doorsOpen",
                icon: "clock",
                model: "doorsOpen",
                format: (value, field, data) => {
                  if(!data[field.model] || !data[field.modelEndDate]) {
                    return '';
                  }
                  const end =
                      field &&
                      field.modelEndDate &&
                      data &&
                      data[field.modelEndDate]
                          ? " ~ " + moment(data[field.modelEndDate]).format("HH:mm")
                          : "";
                  return moment(value).format("MMMM Do YYYY @ HH:mm") + end;
                },
                modelEndDate: "doorsClose",
                command: "RescheduleEventDoorsOpen",
                placeholder: ""
              },
              // {
              //   type: "vueMultiSelect",
              //   label: "common.tags",
              //   icon: "tag-alt",
              //   model: "tags",
              //   format: formatService.formatTags,
              //   // format: value => {
              //   //   if (value instanceof Array) {
              //   //     return value.join(", ");
              //   //   }
              //   //   return "";
              //   // },
              //   command: "ChangeEventTags",
              //   placeholder: "",
              //   values: [],
              //   selectOptions: {
              //     multiple: true,
              //     taggable: true,
              //     closeOnSelect: false,
              //     hideSelected: true,
              //     searchable: true,
              //     showLabels: false,
              //     onNewTag: function(newTag, id, options, value) {
              //       options.push(newTag);
              //       value.push(newTag);
              //     }
              //   }
              // }
              {
                type: "teTag",
                label: "common.tags",
                model: "tags",
                icon: "tag-alt",
                command: "ChangeEventTags",
                format: formatService.formatTags,
                searchTag: optionService.searchTag,
                addTag: optionService.addTag,
              }
            ]
          }
        ]
      },
      options: {
        validateAfterLoad: true,
        validateAfterChanged: true
      },
      commandDataMap: [
        {
          modelKey: "eventManagerId",
          commandKey: "aggregateId"
        },
        {
          modelKey: "id",
          commandKey: "eventId"
        }
      ]
    }
  },

  // // access definition list view settings
  // accessDefinitionListView: {
  //   columns: {
  //     options: [
  //       // all available columns
  //       {
  //         key: "name",
  //         label: "common.name", // translation key for the header label
  //         link: {
  //           routeName: "access-definition-detail",
  //           params: [
  //             { paramKey: "accessDefinitionId", valueKey: "id" },
  //             {
  //               paramKey: "id",
  //               valueKey: "eventId"
  //             }
  //           ]
  //         } // route params contains a params map for mapping row values to params properties
  //       },
  //       {
  //         key: "id",
  //         label: "common.id"
  //       },
  //       {
  //         key: "eventManagerId",
  //         label: "event.eventManagerId"
  //       },
  //       {
  //         key: "eventId",
  //         label: "event.eventId"
  //       },
  //       {
  //         key: "description",
  //         label: "common.description"
  //       },
  //       {
  //         key: "start",
  //         label: "common.start",
  //         format: function(value) {
  //           return moment(value).format("MMMM Do YYYY @ HH:mm");
  //         }
  //       },
  //       {
  //         key: "end",
  //         label: "common.end",
  //         format: function(value) {
  //           return moment(value).format("MMMM Do YYYY @ HH:mm");
  //         }
  //       },
  //       {
  //         key: "accessConditions",
  //         label: "access.prices",
  //         format: formatService.formatConditions
  //       },
  //       {
  //         key: "accessConditions",
  //         label: "access.conditions",
  //         format: formatService.formatFullConditions
  //       },
  //       {
  //         key: "capacityLocations",
  //         label: "event.location"
  //       },
  //       {
  //         key: "capacityLocationAllocation",
  //         label: "event.allocation"
  //       },
  //       {
  //         key: "tags",
  //         label: "event.tags"
  //       },
  //       {
  //         key: "useLimit",
  //         label: "event.useLimit"
  //       }
  //     ],
  //     selected: ["common.name", "common.description", "access.conditions", "access.prices"] // labels of columns that are displayed
  //   },
  //   rows: {
  //     total: 0, // total rows in search result
  //     max: 20, // maximum number of rows to display at once, elastic search query size
  //     from: 0 // show rows from row number, elastic search query from
  //   },
  //   searchTerm: "",
  //   searchFields: [],
  //   filters: {
  //     options: [],
  //     selected: []
  //   },
  //   sort: {
  //     key: "name",
  //     direction: "asc"
  //   }
  //   // bulkActions: ['actions.merge']
  // },

  // access definition detail view settings
  accessDefinitionDetailView: {
    form: {
      schema: {
        groups: [
          {
            // legend: 'common.general',
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "common.name",
                icon: "label-alt",
                model: "name",
                command: "RenameAccessDefinition",
                placeholder: "",
                required: true,
                validator: "string"
              },
              {
                type: "input",
                inputType: "text",
                label: "common.description",
                icon: "paragraph",
                model: "description",
                command: "RenameAccessDefinition",
                placeholder: ""
                // }, {
                //     type: 'teDateTime',
                //     inputType: 'datetime',
                //     range: true,
                //     label: 'common.timeFrame',
                //     model: 'start',
                //     modelEndDate: 'end',
                //     command: 'RescheduleAccessDefinition',
                //     placeholder: '',
              },
              {
                // type: "vueMultiSelect",
                type: "teCapacityLocationSelector",
                label: "access.capacityLocations",
                icon: "map-marker-alt",
                model: "capacityLocations",
                command: "ChangeAccessDefinitionCapacityLocations",
                format: (capacityLocations) => {
                  return capacityLocations && capacityLocations.length > 0 ? capacityLocations.map(c => c.name).join(', ') : ''
                },
                placeholder: "",
                // required: true,
                values: [],
                required: true,
                validator: validationService.validateAccessCapacity,
                // validator: validators.required,
                selectOptions: {
                  multiple: true,
                  closeOnSelect: false,
                  hideSelected: true,
                  searchable: true,
                  // resetAfter: true,
                  // trackBy: "locationPath",
                  // label: "name",
                  showLabels: false,
                  trackBy: 'path',
                  label: 'name'
                }
              },
              // {
              //   type: "vueMultiSelect",
              //   label: "access.capacityLocationAllocation",
              //   icon: "crosshair",
              //   model: "capacityLocationAllocation",
              //   command: "ChangeAccessDefinitionCapacityLocationAllocation",
              //   // required: true,
              //   placeholder: "",
              //   values: [
              //     "all",
              //     "priority"
              //     // 'select'
              //   ],
              //   selectOptions: {
              //     multiple: false,
              //     closeOnSelect: true,
              //     hideSelected: true,
              //     searchable: false,
              //     showLabels: false
              //   }
              // },
              {
                type: "input",
                inputType: "number",
                label: "access.useLimit",
                icon: "label-alt",
                model: "useLimit",
                command: "ChangeAccessDefinitionUseLimit",
                // required: true,
                validator: "integer",
                min: 0,
                placeholder: ""
              },
              // {
              //   type: "teTag",
              //   label: "common.tags",
              //   model: "tags",
              //   icon: "tag-alt",
              //   command: "ChangeAccessDefinitionTags",
              //   format: formatService.formatTags,
              //   searchTag: optionService.searchTag,
              //   addTag: optionService.addTag,
              // },
              {
                type: "teConditionEditor",
                label: "common.conditions",
                icon: "sitemap",
                model: "accessConditions",
                command: "ChangeAccessDefinitionConditions",
                placeholder: "",
                conditionOptions: {
                  rules: [],
                  tags: [],
                  price: [],
                  postponed: [],
                  limit: [],
                  capacityGroups: [],
                  nameSuggestions: [],
                  searchTag: optionService.searchTag,
                  addTag: optionService.addTag
                },
                format: formatService.formatConditions,
                validator: validationService.validateConditions,
              }
            ]
          }
        ]
      },
      options: {
        validateAfterLoad: true,
        validateAfterChanged: true
      },
      commandDataMap: [
        {
          modelKey: "eventManagerId",
          commandKey: "aggregateId"
        },
        {
          modelKey: "eventId",
          commandKey: "eventId"
        },
        {
          modelKey: "id",
          commandKey: "accessDefinitionId"
        }
      ]
    }
  },

  // capacity detail view settings
  capacityDetailView: {
    // form: {
    //   schema: {
    //     groups: [
    //       {
    //         // legend: 'common.general',
    //         fields: [
    //           {
    //             type: "teCapacityEditor",
    //             label: "common.capacity",
    //             model: "capacity",
    //             command: "ChangeCapacity",
    //             validator: validationService.validateEventCapacity
    //           }
    //         ]
    //       }
    //     ]
    //   },
    //   options: {
    //     validateAfterLoad: true,
    //     validateAfterChanged: true
    //   },
    //   commandDataMap: [
    //     {
    //       modelKey: "eventManagerId",
    //       commandKey: "aggregateId"
    //     },
    //     {
    //       modelKey: "id",
    //       commandKey: "capacityId"
    //     }
    //   ]
    // }
  },





};

//
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
