export const ORDER_ERROR = "ORDER_ERROR";
export const FLUSH_ORDER_ERROR = "FLUSH_ORDER_ERROR";

export const START_ORDER_JOB = "START_ORDER_JOB";
export const END_ORDER_JOB = "END_ORDER_JOB";

export const RECEIVED_ORDERS = "RECEIVED_ORDERS";
export const RECEIVED_ORDER = "RECEIVED_ORDER";
export const SAVED_ORDER = "SAVED_ORDER";
export const CREATED_ORDER = "CREATED_ORDER";
export const CLEAR_ORDER = "CLEAR_ORDER";

export const RECEIVED_ORDER_EMAILS = "RECEIVED_ORDER_EMAILS";

export const SELECT_ORDER_LIST_COLUMNS = "SELECT_ORDER_LIST_COLUMNS";
export const SET_ORDER_LIST_ROWS = "SET_ORDER_LIST_ROWS";
export const SORTED_ORDER_LIST = "SORTED_ORDER_LIST";
export const FILTER_ORDER_LIST = "FILTER_ORDER_LIST";
