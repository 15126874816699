export const EVENT_ERROR = "EVENT_ERROR";
export const FLUSH_EVENT_ERROR = "FLUSH_EVENT_ERROR";

export const START_EVENT_JOB = "START_EVENT_JOB";
export const END_EVENT_JOB = "END_EVENT_JOB";

export const RECEIVED_EVENTS = "RECEIVED_EVENTS";
export const RECEIVED_EVENT = "RECEIVED_EVENT";
export const SAVED_EVENT = "SAVED_EVENT";
export const CREATED_EVENT = "CREATED_EVENT";
export const CLEAR_EVENT = "CLEAR_EVENT";

export const SELECT_EVENT_LIST_COLUMNS = "SELECT_EVENT_LIST_COLUMNS";
export const SET_EVENT_LIST_SEARCH_TERM = "SET_EVENT_LIST_SEARCH_TERM";
export const SET_EVENT_LIST_ROWS = "SET_EVENT_LIST_ROWS";
export const SET_EVENT_LIST_SORT = "SET_EVENT_LIST_SORT";
export const FILTER_EVENT_LIST = "FILTER_EVENT_LIST";
export const SET_EVENT_FORM_OPTIONS = "SET_EVENT_FORM_OPTIONS";

export const RECEIVED_ACCESS_DEFINITIONS = "RECEIVED_ACCESS_DEFINITIONS";
export const RECEIVED_ACCESS_DEFINITION = "RECEIVED_ACCESS_DEFINITION";
export const SAVED_ACCESS_DEFINITION = "SAVED_ACCESS_DEFINITION";
export const CREATED_ACCESS_DEFINITION = "CREATED_ACCESS_DEFINITION";
export const CLEAR_ACCESS_DEFINITIONS = "CLEAR_ACCESS_DEFINITIONS";
export const CLEAR_ACCESS_DEFINITION = "CLEAR_ACCESS_DEFINITION";

export const SET_ACCESS_DEFINITION_FORM_OPTIONS =
  "SET_ACCESS_DEFINITION_FORM_OPTIONS";

export const RECEIVED_CAPACITIES = "RECEIVED_CAPACITIES";
export const RECEIVED_CAPACITY = "RECEIVED_CAPACITY";
export const SAVED_CAPACITY = "SAVED_CAPACITY";
export const CREATED_CAPACITY = "CREATED_CAPACITY";
export const CLEAR_CAPACITY = "CLEAR_CAPACITY";

export const RECEIVED_EVENT_ATTENDEES = "RECEIVED_EVENT_ATTENDEES";
export const CLEAR_EVENT_ATTENDEES = "CLEAR_EVENT_ATTENDEES";
export const RECEIVED_EVENT_ATTENDEE_PRICE_LIST = "RECEIVED_EVENT_ATTENDEE_PRICE_LIST";
export const CLEAR_EVENT_ATTENDEE_PRICE_LIST = "CLEAR_EVENT_ATTENDEE_PRICE_LIST";

