import * as mutationTypes from "../mutation-types";
import BackendService from "@/services/backend.service";
import GraphqlQueryBuilder from "@/services/GraphqlQueryBuilder.service";
import validationService from "@/services/validation.service";
import * as jobTypes from "../job-types";
import router from '@/router'
const backoffice = new BackendService();


export default {

    async getMembershipDefinitions({ commit, state }, {rows, sort, columns, filters}) {
        const c = {options: [], selected: columns.selected}; // create custom column selection because different types of validityPeriod doest not work with query builder

        // build query
        const builder = new GraphqlQueryBuilder();
        builder.setType("membershipDefinitions");
        builder.setRows(rows);
        builder.setSort(sort);
        // builder.setColumns(columns, ["id"]);
        builder.setColumns(c, ["id", "name", "validityPeriod{... on MembershipValidityForDuration{type,unit,value}, ... on MembershipValidityFixed{type,start,end}, ... on MembershipValidityIndefinitely{type}}"]);
        if( validationService.validateDatatableFilters(filters) ) {
            builder.setFilters(filters);
        }
        const query = builder.build();

        //
        commit(mutationTypes.START_MEMBERSHIP_DEFINITION_JOB, jobTypes.GET_MEMBERSHIP_DEFINITION);
        const result = await backoffice.sendQuery(query);
        if (result.errors) {
            commit(mutationTypes.MEMBERSHIP_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_MEMBERSHIP_DEFINITION_JOB, jobTypes.GET_MEMBERSHIP_DEFINITION);
        } else {
            commit(mutationTypes.RECEIVED_MEMBERSHIP_DEFINITIONS, { membershipDefinitions: result.data.membershipDefinitions });
            commit(mutationTypes.END_MEMBERSHIP_DEFINITION_JOB, jobTypes.GET_MEMBERSHIP_DEFINITION);
        }
    },

    async getMembershipDefinition({ commit, state }, {id}) {
        commit(mutationTypes.START_MEMBERSHIP_DEFINITION_JOB, jobTypes.GET_MEMBERSHIP_DEFINITION);
        commit(mutationTypes.CLEAR_MEMBERSHIP_DEFINITION);
        const query = `query { membershipDefinition(id: "${id}"){ id,name,description,tags{id,name},validityPeriod{... on MembershipValidityForDuration{type,unit,value}, ... on MembershipValidityFixed{type,start,end}, ... on MembershipValidityIndefinitely{type}} }}`;
        const result = await backoffice.sendQuery(query);
        if (result.error) {
            commit(mutationTypes.MEMBERSHIP_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_MEMBERSHIP_DEFINITION_JOB, jobTypes.GET_MEMBERSHIP_DEFINITION);
        } else {
            commit(mutationTypes.RECEIVED_MEMBERSHIP_DEFINITION, { membershipDefinition: result.data.membershipDefinition });
            commit(mutationTypes.END_MEMBERSHIP_DEFINITION_JOB, jobTypes.GET_MEMBERSHIP_DEFINITION);
        }
    },

    async getIssuedMemberships({ commit, state }, {rows, sort, columns, filters}) {
        // build query
        const builder = new GraphqlQueryBuilder();
        builder.setType("memberships");
        builder.setRows(rows);
        builder.setSort(sort);
        builder.setColumns(columns, ["id", "orderLineItemId"]);
        if( validationService.validateDatatableFilters(filters) ) {
            builder.setFilters(filters);
        }
        const query = builder.build();

        commit(mutationTypes.START_MEMBERSHIP_DEFINITION_JOB, jobTypes.GET_MEMBERSHIP);
        // const query = `query { orderLineItems(filters: {membershipDefinitionId: {equals: "${membershipDefinitionId}"}, status: {notContains: ["removed", "returned"]}}){... on MembershipLineItem{id,orderId,type,status,price,tax,currency{name,code,exponent,symbol},name,customer{id,fullName},membership{id,reference},order{id,email,createDate}}}}`;
        // const query = `query { memberships{ id,status,orderId,orderLineItemId,customer{id,fullName},grantDate,start,end,membershipDefinition{id,name,description} }}`;
        const result = await backoffice.sendQuery(query);
        if (result.error) {
            commit(mutationTypes.MEMBERSHIP_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_MEMBERSHIP_DEFINITION_JOB, jobTypes.GET_MEMBERSHIP);
        } else {
            commit(mutationTypes.RECEIVED_MEMBERSHIPS, { memberships: result.data.memberships });
            commit(mutationTypes.END_MEMBERSHIP_DEFINITION_JOB, jobTypes.GET_MEMBERSHIP);
        }
    },

    async saveMembershipDefinitionMutations({ commit, state }, mutations) {
        commit(mutationTypes.START_MEMBERSHIP_DEFINITION_JOB, jobTypes.SAVE_MEMBERSHIP_DEFINITION);
        const result = await backoffice.sendCommandBatch(mutations);
        if (result.error) {
            commit(mutationTypes.MEMBERSHIP_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_MEMBERSHIP_DEFINITION_JOB, jobTypes.SAVE_MEMBERSHIP_DEFINITION);
        } else {
            commit(
                mutationTypes.SAVED_MEMBERSHIP_DEFINITION,
                mutations.reduce(
                    (state, command) => Object.assign(state, command.data),
                    {}
                )
            );
            commit(mutationTypes.END_MEMBERSHIP_DEFINITION_JOB, jobTypes.SAVE_MEMBERSHIP_DEFINITION);
        }
    },

    async createMembershipDefinition({ commit, state }, {membershipDefinition}) {
        commit(mutationTypes.START_MEMBERSHIP_DEFINITION_JOB, jobTypes.SAVE_MEMBERSHIP_DEFINITION);
        const result = await backoffice.sendCommand('CreateMembershipDefinition', membershipDefinition);
        if (result.error) {
            commit(mutationTypes.MEMBERSHIP_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_MEMBERSHIP_DEFINITION_JOB, jobTypes.SAVE_MEMBERSHIP_DEFINITION);
        } else {
            commit(mutationTypes.CREATED_MEMBERSHIP_DEFINITION, membershipDefinition);
            commit(mutationTypes.END_MEMBERSHIP_DEFINITION_JOB, jobTypes.SAVE_MEMBERSHIP_DEFINITION);
            // router.push({ name: "membership-definition-detail", params: { id: result.data.membershipDefinitionId } }, () => {router.go()});
            router.push({ name: "membership-definition-detail", params: { id: result.data.membershipDefinitionId } });
        }
    },

    async createMembershipDocument({ commit, state }, {templateId, orderLineItemIds}) {
        commit(mutationTypes.START_MEMBERSHIP_DEFINITION_JOB, jobTypes.CREATE_MEMBERSHIP_DOCUMENT);
        const result = await backoffice.sendCommand('CreateDocument', {templateId: templateId, ids: orderLineItemIds, multiple: false});
        if (result.error) {
            commit(mutationTypes.MEMBERSHIP_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_MEMBERSHIP_DEFINITION_JOB, jobTypes.CREATE_MEMBERSHIP_DOCUMENT);
        } else {
            if(result.data && result.data.documents && result.data.documents[0] && result.data.documents[0].url) {
                window.open(result.data.documents[0].url, '_blank'); // open created document
            }
            commit(mutationTypes.END_MEMBERSHIP_DEFINITION_JOB, jobTypes.CREATE_MEMBERSHIP_DOCUMENT);
        }
    },

    async startBatchOperation({ commit, dispatch }, operations) {
        const jobName = 'start-batch-operation';
        commit(mutationTypes.START_MEMBERSHIP_DEFINITION_JOB, jobName);
        await dispatch('core/startBatchOperation', operations, {root:true});
        commit(mutationTypes.END_MEMBERSHIP_DEFINITION_JOB, jobName);
    },

    clearMembershipDefinition({ commit, state }) {
        commit(mutationTypes.CLEAR_MEMBERSHIP_DEFINITION);
    },


};
