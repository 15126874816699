export default [
    {
        path: "/membership",
        name: "membership-root",
        redirect: { name: 'membership-list' }
    },

    {
        path: "/membership/holder",
        name: "membership-list",
        component: () =>
            import(/* webpackChunkName: "membership" */ "@/modules/membership/views/MembershipListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },

    {
        path: "/membership/type",
        name: "membership-definition-list",
        component: () =>
            import(/* webpackChunkName: "membership" */ "@/modules/membership/views/MembershpDefinitionListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },

    {
        path: "/membership/type/:id",
        name: "membership-definition-detail-root",
        component: () =>
            import(/* webpackChunkName: "membership" */ "@/modules/membership/views/MembershipDefinitionDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "membership-definition-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "membership" */ "@/modules/membership/components/MembershipDefinitionDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            // {
            //     path: "transaction",
            //     name: "credit-account-transaction",
            //     components: {
            //         detail: () =>
            //             import(/* webpackChunkName: "membership" */ "@/modules/credit/components/CreditAccountTransactions.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
            //     }
            // },
        ]
    },

];
