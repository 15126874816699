export default {
  error: state => state.error,

  jobs: state => state.jobs,

  orders: state => state.orders,

  order: state => state.order,

  orderEmails: state => state.orderEmails,

  orderListView: state => state.orderListView,

  orderDetailView: state => state.orderDetailView
};
