import i18n from "@/locals/index";

export default {
  translateDataTableConfigLabels(config) {
    let c = _.cloneDeep(config); // clone model to prevent mutating vuex state

    // translate option labels
    c.options = this.translateLabels(c.options);

    // translate selected values
    c.selected = this.translateArray(c.selected);
    return c;
  },

  translateLabels(options) {
    return options.map(o => {
      o.label = i18n.t(o.label);
      return o;
    });
  },

  translateArray(options) {
    return options.map(o => i18n.t(o));
  },

  translateConditionEditorOptionLabels(option) {
    let o = _.cloneDeep(option); // clone model to prevent mutating vuex state

    // translate option labels
    o.rules.forEach(group => {
      group.values.forEach(option => {
        option.label = i18n.t(option.label);
      });
    });

    return o;
  },

  translateNavigationLabels(navigation) {
    const navClone = _.cloneDeep(navigation);
    navClone.forEach(group => {
      group.items.forEach(item => {
        item.label = i18n.t(item.label);
        if (item.items) {
          item.items.forEach(child => {
            child.label = i18n.t(child.label);
          });
        }
      });
    });
    return navClone;
  },

  translateMenuLabels(navigation) {
    const navClone = _.cloneDeep(navigation);
    navClone.forEach(group => {
      group.forEach(item => {
        item.label = i18n.t(item.label);
        if (item.children) {
          item.children.forEach(child => {
            child.label = i18n.t(child.label);
          });
        }
      });
    });
    return navClone;
  },

  translateTabLabels(tabs) {
    const tabClone = _.cloneDeep(tabs);
    tabClone.forEach(tab => {
      tab.label = i18n.t(tab.label);
    });
    return tabClone;
  },

  translateFormSchemaLabels(schema) {
    const schemaClone = _.cloneDeep(schema);
    schemaClone.groups.forEach(group => {
      if(group.fields) {
        group.fields.forEach(field => {
          field.label = i18n.t(field.label);
          field.placeholder = i18n.t(field.placeholder);
        });
      }
    });
    return schemaClone;
  },


};
