// import { WebClient } from "ticketengine-sdk";
import BackendService from "@/services/backend.service";

const backoffice = new BackendService();

export default {

    async addTag(searchQuery) {
        try {
            // const client = new WebClient();
            // const response = await client.tag.createTag({name: searchQuery}); // send command
            const response = await backoffice.sendCommand('CreateTag', {name: searchQuery});
            return response.data.tagId;
        } catch (e) {
            return { error: e.message };
        }
    },

    async searchTag(searchQuery) {
        // if(searchQuery && searchQuery.length >= 1) {
        //     const response = await backoffice.sendQuery('query { search(searchTerm: "' + searchQuery + '", type: "Tag"){id,name,description,type,status,date} }');
        //     return response.data.search.map((result) => { return {id: result.id, name: result.name} });
        // } else {
        //     return []
        // }
        const response = await backoffice.sendQuery('query { tags{id,name} }');
        return response.data.tags;
    },

    async searchSalesChannel(searchQuery) {
        // if(searchQuery && searchQuery.length >= 1) {
        //     const response = await backoffice.sendQuery('query { search(searchTerm: "' + searchQuery + '", type: "Sales Channel"){id,name,description,type,status,date} }');
        //     return response.data.search.map((result) => { return {id: result.id, name: result.name} });
        // } else {
        //     return []
        // }
        const response = await backoffice.sendQuery('query { salesChannels{id,name} }');
        return response.data.salesChannels;
    },

    async searchCoupons() {
        const response = await backoffice.sendQuery('query { tokenTypes{id,name} }');
        return response.data.tokenTypes;
    },

    async events() {
        const response = await backoffice.sendQuery('query { events{id,name} }');
        return response.data.events;
    },

    async productDefinitions() {
        const response = await backoffice.sendQuery('query { productDefinitions{ id,name }}');
        return response.data.productDefinitions;
    },

    async membershipDefinitions() {
        const response = await backoffice.sendQuery('query { membershipDefinitions{ id,name }}');
        return response.data.membershipDefinitions;
    },

    async loyaltyCardTypes() {
        const response = await backoffice.sendQuery('query { loyaltyCardTypes{ id,name }}');
        return response.data.loyaltyCardTypes;
    },

    async salesChannels() {
        const response = await backoffice.sendQuery('query { salesChannels{id,name} }');
        return response.data.salesChannels;
    },

    async registers() {
        const response = await backoffice.sendQuery('query { salesChannels{id,name,registers{id,name}} }');
        const registers = [];
        response.data.salesChannels.forEach((sc) => {
            sc.registers.forEach((r) => {
                registers.push(r);
            })
        });
        return registers;
    },

    async users() {
        const response = await backoffice.sendQuery('query { users{id,username} }');
        return response.data.users ? response.data.users.map((u) => {u.name = u.username; return u}) : [];
    },

    async emailTemplates() {
        const response = await backoffice.sendQuery('query { emailTemplates{id,name} }');
        return response.data.emailTemplates;
    },

    async documentTemplates() {
        const response = await backoffice.sendQuery('query { documentTemplates{id,name} }');
        return response.data.documentTemplates;
    },

    async documentMembershipTemplates() {
        const response = await backoffice.sendQuery('query { documentTemplates(filters: {type: {contains: ["MembershipPdfTemplate"]}}, orderBy: [name_DESC]){id,name} }');
        return response.data.documentTemplates;
    },

    async tags() {
        const response = await backoffice.sendQuery('query { tags{id,name} }');
        return response.data.tags;
    },

    async eventTemplates() {
        const response = await backoffice.sendQuery('query { eventTemplates{id,name} }');
        return response.data.eventTemplates;
    },

    async eventCapacityTemplates() {
        const response = await backoffice.sendQuery('query { eventCapacityTemplates{id,name} }');
        return response.data.eventCapacityTemplates;
    },

    async eventAccessTemplates() {
        const response = await backoffice.sendQuery('query { eventAccessTemplates{id,name} }');
        return response.data.eventAccessTemplates;
    },


    // async tagsAsLabelValue() {
    //     const response = await backoffice.sendQuery('query { tags{id,name} }');
    //     return response.data.tags.map((t) => { return {label: t.name, value: t.id} });
    //     // const tags = response.data.tags.map((t) => { return {label: t.name, value: t.id} });
    //     // return tags;
    // },


}
