import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/locals/index";
import VueFormGenerator from "vue-form-generator";

import "vue-multiselect/dist/vue-multiselect.min.css";
// import "vue2-datepicker/src/index.scss";
// import "vue-scrolling-table";

import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);

import VTooltip from "v-tooltip";
Vue.use(VTooltip);

import VModal from "vue-js-modal";
Vue.use(VModal);

import Toasted from "vue-toasted";
Vue.use(Toasted, {
  theme: "tec-toasted",
  containerClass: "tec-toasted-container"
});







// import '@ticketengine/components';

import Unicon from "vue-unicons";
import {
  uniArrowLeft,
  uniArrowRight,
  uniShoppingCartAlt,
  uniUserSquare,
  uniFileCheckAlt,
  uniDropbox,
  uniCog,
  uniFolderCheck,
  uniTicket,
  uniUsersAlt,
  uniChartPieAlt,
  uniBox,
  uniCopy,
  uniAngleUp,
  uniAngleDown,
  uniSearch,
  uniLayersAlt,
  uniEnvelopeEdit,
  uniFilter,
  uniTrashAlt,
  uniColumns,
  uniUpload,
  uniEllipsisV,
  uniGripHorizontalLine,
  uniCheck,
  uniTimes,
  uniInvoice,
  uniLabelAlt,
  uniClock,
  uniParagraph,
  uniTagAlt,
  uniPlus,
  uniCommentAltShare,
  uniMoneyBill,
  uniMapMarkerAlt,
  uniCrosshair,
  uniSitemap,
  uniLayerGroup,
  uniAnalysis,
  uniFileMedicalAlt,
  uniHeartRate,
  uniKeyholeCircle,
  uniUser,
  uniUserCircle,
  uniLock,
  uniImport,
  uniExport,
  uniShield,
  uniEnvelope,
  uniCalendarAlt,
  uniVenus,
  uniMapMarker,
  uniCloudUpload,
  uniCloudDownload,
  uniFileAlt,
  uniFilePlusAlt,
  uniFileMinusAlt,
  uniPrint,
  uniTruck,
  uniFastMail,
  uniStopwatch,
  uniExclamationTriangle,
  uniPackage,
  uniPricetagAlt,
  uniPhone, uniImage
} from "vue-unicons/src/icons";

Unicon.add([
  uniArrowLeft,
  uniArrowRight,
  uniShoppingCartAlt,
  uniUserSquare,
  uniFileCheckAlt,
  uniDropbox,
  uniCog,
  uniFolderCheck,
  uniTicket,
  uniUsersAlt,
  uniChartPieAlt,
  uniBox,
  uniDropbox,
  uniCopy,
  uniAngleUp,
  uniAngleDown,
  uniSearch,
  uniLayersAlt,
  uniEnvelope,
  uniEnvelopeEdit,
  uniFilter,
  uniTrashAlt,
  uniColumns,
  uniUpload,
  uniEllipsisV,
  uniGripHorizontalLine,
  uniCheck,
  uniTimes,
  uniInvoice,
  uniLabelAlt,
  uniClock,
  uniParagraph,
  uniPlus,
  uniTagAlt,
  uniCommentAltShare,
  uniMoneyBill,
  uniMapMarkerAlt,
  uniCrosshair,
  uniSitemap,
  uniLayerGroup,
  uniAnalysis,
  uniFileMedicalAlt,
  uniHeartRate,
  uniKeyholeCircle,
  uniUser,
  uniUserCircle,
  uniLock,
  uniShield,
    uniCalendarAlt,
    uniVenus,
  uniMapMarker,
  uniCloudUpload,
    uniCloudDownload,
  uniFileAlt,
  uniFilePlusAlt,
  uniFileMinusAlt,
  uniPrint,
  uniTruck,
  uniFastMail,
    uniStopwatch,
  uniImport,
  uniExport,
  uniExclamationTriangle,
    uniPackage,
  uniPricetagAlt,
    uniPhone,
    uniImage
]);
Vue.use(Unicon);

// Automagically registers base components (prefixed by "Base")
let requireComponent = require.context(
  "@ticketengine/components/src/components",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );

  Vue.component(componentName, componentConfig.default || componentConfig);
});

// Register VueFormGenerator
import FormDateTime from "@ticketengine/components/src/components/Form/FormDateTime";
Vue.component("fieldTeDateTime", FormDateTime);
import FormCapacityLocationSelector from "@ticketengine/components/src/components/Form/FormCapacityLocationSelector";
Vue.component("fieldTeCapacityLocationSelector", FormCapacityLocationSelector);
import FormConditionEditor from "@ticketengine/components/src/components/Form/FormConditionEditor";
Vue.component("fieldTeConditionEditor", FormConditionEditor);
import FormCapacityEditor from "@ticketengine/components/src/components/Form/FormCapacityEditor";
Vue.component("fieldTeCapacityEditor", FormCapacityEditor);
import FormCapacityTimeSlotEditor from "@ticketengine/components/src/components/Form/FormCapacityTimeSlotEditor";
Vue.component("fieldTeCapacityTimeSlotEditor", FormCapacityTimeSlotEditor);
import FormTag from "@ticketengine/components/src/components/Form/FormTag";
Vue.component("fieldTeTag", FormTag);
import FormCodeEditor from "@ticketengine/components/src/components/Form/FormCodeEditor";
Vue.component("fieldTeCodeEditor", FormCodeEditor);
import FormDeliveryEditor from "@ticketengine/components/src/components/Form/FormDeliveryEditor";
Vue.component("fieldTeDeliveryEditor", FormDeliveryEditor);
import FormOrderTimeoutEditor from "@ticketengine/components/src/components/Form/FormOrderTimeoutEditor";
Vue.component("fieldTeOrderTimeoutEditor", FormOrderTimeoutEditor);
import FormMembershipValidityPeriodEditor from "@ticketengine/components/src/components/Form/FormMembershipValidityPeriodEditor";
Vue.component("fieldTeMembershipValidityPeriodEditor", FormMembershipValidityPeriodEditor);
import FormTextTranslation from "@ticketengine/components/src/components/Form/FormTextTranslation";
Vue.component("fieldTeTextTranslation", FormTextTranslation);
Vue.use(VueFormGenerator);





Vue.directive('click-outside', {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  }
});




//
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
