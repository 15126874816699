var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'button-icon',
    {
      'button-icon--is-active': _vm.isActive,
      'button-icon--alternative': _vm.alternative,
      'button-icon--is-disabled': _vm.isDisabled,
      'button-icon--has-blended-background': _vm.blendBackground
    }
  ],attrs:{"aria-label":_vm.label},on:{"click":_vm.onClick}},[_c('unicon',{attrs:{"name":_vm.icon,"fill":"red","width":_vm.size,"height":_vm.size}}),(_vm.count > 0)?_c('span',{staticClass:"button-icon__count"},[_vm._v(_vm._s(_vm.count))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }