export default {

  removeConditionIds (condition) {
    // let c = _.cloneDeep(config); // clone model to prevent mutating vuex state
    //
    // // translate option labels
    // c.options = this.translateLabels(c.options);
    //
    // // translate selected values
    // c.selected = this.translateArray(c.selected);
    // return c;

    const self = this;

    if(condition && condition.id) {
      delete condition.id;
    }

    if(condition && condition.conditions && Array.isArray(condition.conditions) && condition.conditions.length > 0) {
      condition.conditions = condition.conditions.map(c => {
        return self.removeConditionIds(c);
      });
    }
    return condition;

  },


}
