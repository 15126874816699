export const EVENT_TEMPLATE_ERROR = "EVENT_TEMPLATE_ERROR";
export const FLUSH_EVENT_TEMPLATE_ERROR = "FLUSH_EVENT_TEMPLATE_ERROR";

export const START_EVENT_TEMPLATE_JOB = "START_EVENT_TEMPLATE_JOB";
export const END_EVENT_TEMPLATE_JOB = "END_EVENT_TEMPLATE_JOB";

export const SELECT_EVENT_TEMPLATE_LIST_COLUMNS = "SELECT_EVENT_TEMPLATE_LIST_COLUMNS";
export const SET_EVENT_TEMPLATE_LIST_ROWS = "SET_EVENT_TEMPLATE_LIST_ROWS";
export const SET_EVENT_TEMPLATE_LIST_SORT = "SET_EVENT_TEMPLATE_LIST_SORT";
export const FILTER_EVENT_TEMPLATE_LIST = "FILTER_EVENT_TEMPLATE_LIST";

export const RECEIVED_EVENT_TEMPLATES = "RECEIVED_EVENT_TEMPLATES";
export const RECEIVED_EVENT_TEMPLATE = "RECEIVED_EVENT_TEMPLATE";
export const RECEIVED_EVENT_TEMPLATE_APPLIED_ON_EVENTS = "RECEIVED_EVENT_TEMPLATE_APPLIED_ON_EVENTS";
export const RECEIVED_EVENT_CAPACITY_TEMPLATES_OPTIONS = "RECEIVED_EVENT_CAPACITY_TEMPLATES_OPTIONS";
export const SAVED_EVENT_TEMPLATE = "SAVED_EVENT_TEMPLATE";
export const ADD_EVENT_TEMPLATE_ACCESS_DEFINITION = "ADD_EVENT_TEMPLATE_ACCESS_DEFINITION";
export const UPDATE_EVENT_TEMPLATE_ACCESS_DEFINITION = "UPDATE_EVENT_TEMPLATE_ACCESS_DEFINITION";
export const CREATED_EVENT_TEMPLATE = "CREATED_EVENT_TEMPLATE";
export const CLEAR_EVENT_TEMPLATES = "CLEAR_EVENT_TEMPLATES";
export const CLEAR_EVENT_TEMPLATE = "CLEAR_EVENT_TEMPLATE";
export const CLEAR_EVENT_TEMPLATE_APPLIED_ON_EVENTS = "CLEAR_EVENT_TEMPLATE_APPLIED_ON_EVENTS";

