import * as types from "../mutation-types";
import _ from "lodash";

export default {
    [types.PRODUCT_DEFINITION_ERROR](state, error) {
        state.error = { message: error };
    },

    [types.FLUSH_PRODUCT_DEFINITION_ERROR](state) {
        state.error = { message: null };
    },

    [types.START_PRODUCT_DEFINITION_JOB](state, key) {
        state.jobs.push(key);
    },

    [types.END_PRODUCT_DEFINITION_JOB](state, key) {
        state.jobs = state.jobs.filter(job => job !== key);
    },

    [types.RECEIVED_PRODUCT_DEFINITIONS](state, { productDefinitions }) {
        state.productDefinitions = productDefinitions;
    },

    [types.RECEIVED_PRODUCT_DEFINITION](state, { productDefinition }) {
        state.productDefinition = productDefinition;
    },

    [types.SAVED_PRODUCT_DEFINITION](state, commandData) {
        state.productDefinition = _.merge(state.productDefinition, commandData);
    },

    [types.CREATED_PRODUCT_DEFINITION](state, data) {
        state.productDefinition = data;
    },

    [types.CLEAR_PRODUCT_DEFINITION](state) {
        state.productDefinition = {};
    },

    [types.RECEIVED_PRODUCTS](state, {products}) {
        state.products = products;
    },

    [types.RECEIVED_CREDIT_ACCOUNT_CURRENCIES](state, {creditAccountCurrencies}) {
        state.creditAccountCurrencies = creditAccountCurrencies;
    },

    [types.RECEIVED_PACKAGE_DEFINITIONS](state, {packageDefinitions}) {
        state.packageDefinitions = packageDefinitions;
    },

};
