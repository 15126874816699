export const ACCOUNT_ERROR = "ACCOUNT_ERROR";
export const FLUSH_ACCOUNT_ERROR = "FLUSH_ACCOUNT_ERROR";

export const START_ACCOUNT_JOB = "START_ACCOUNT_JOB";
export const END_ACCOUNT_JOB = "END_ACCOUNT_JOB";

export const RECEIVED_ACCOUNT = "RECEIVED_ACCOUNT";
export const CLEAR_ACCOUNT = "CLEAR_ACCOUNT";

export const RECEIVED_BATCH_OPERATION = "RECEIVED_BATCH_OPERATION";
export const CLEAR_BATCH_OPERATION = "CLEAR_BATCH_OPERATION";
export const RECEIVED_BATCH_OPERATIONS = "RECEIVED_BATCH_OPERATIONS";
export const CLEAR_BATCH_OPERATIONS = "CLEAR_BATCH_OPERATIONS";

