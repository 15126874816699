export default [
    {
        path: "/product",
        name: "product-definition-root",
        component: () =>
            import(/* webpackChunkName: "product" */ "@/modules/product/views/ProductListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },

    {
        path: "/product/new",
        name: "product-definition-new",
        component: () =>
            import(/* webpackChunkName: "product-new" */ "@/modules/product/views/ProductNewView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },

    {
        path: "/product/:id",
        name: "product-definition-detail-root",
        component: () =>
            import(/* webpackChunkName: "product-detail" */ "@/modules/product/views/ProductDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "product-definition-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "product-detail" */ "@/modules/product/components/ProductDefinitionDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
            {
                path: "issued",
                name: "issued-product-list",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "product-detail" */ "@/modules/product/components/IssuedProductList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
        ]
    }
];
