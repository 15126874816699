import * as mutationTypes from "../mutation-types";
import BackendService from "@/services/backend.service";
import GraphqlQueryBuilder from "@/services/GraphqlQueryBuilder.service";
import validationService from "@/services/validation.service";
import * as jobTypes from "../../credit/job-types";
import router from '@/router'
const backoffice = new BackendService();


export default {

    async getProductDefinitions({ commit, state }, {rows, sort, columns, filters}) {
        // const c = {options: [], selected: columns.selected};
        const c = {options: columns.options.filter(o => !o.key.includes('.')), selected: columns.selected};

        // build query
        const builder = new GraphqlQueryBuilder();
        builder.setType("productDefinitions");
        builder.setRows(rows);
        builder.setSort(sort);
        // builder.setColumns(columns, ["id", "apiConfig{... on CreditAccountApiConfig{source}, ... on MembershipApiConfig{source}, ... on DonationApiConfig{source}}"]);
        builder.setColumns(c, ["id", "name", "description", "status", "apiConfig{... on CreditAccountApiConfig{source}, ... on MembershipApiConfig{source}, ... on PackageApiConfig{source}, ... on DonationApiConfig{source}}"]);
        if( validationService.validateDatatableFilters(filters) ) {
            builder.setFilters(filters);
        }
        const query = builder.build();

        //
        commit(mutationTypes.START_PRODUCT_DEFINITION_JOB, "get-product-definition");
        const result = await backoffice.sendQuery(query);
        if (result.errors) {
            commit(mutationTypes.PRODUCT_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, "get-product-definition");
        } else {
            commit(mutationTypes.RECEIVED_PRODUCT_DEFINITIONS, { productDefinitions: result.data.productDefinitions });
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, "get-product-definition");
        }
    },

    async getProductDefinition({ commit, state }, {id}) {
        commit(mutationTypes.START_PRODUCT_DEFINITION_JOB, "get-product-definition");
        commit(mutationTypes.CLEAR_PRODUCT_DEFINITION);
        const query = `query { productDefinition(id: "${id}"){ id,name,description,status,conditionsString,tags{id,name},apiConfig{... on CreditAccountApiConfig{source,currencyCode,amount}, ... on MembershipApiConfig{source}, ... on PackageApiConfig{source}, ... on DonationApiConfig{source}} }}`;
        const result = await backoffice.sendQuery(query);
        if (result.error) {
            commit(mutationTypes.PRODUCT_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, "get-product-definition");
        } else {
            if (result.data.productDefinition && result.data.productDefinition.conditionsString) {
                result.data.productDefinition.productConditions = JSON.parse(result.data.productDefinition.conditionsString);
            }
            commit(mutationTypes.RECEIVED_PRODUCT_DEFINITION, { productDefinition: result.data.productDefinition });
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, "get-product-definition");
        }
    },

    async getIssuedProducts({ commit, state }, {productDefinitionId}) {
        commit(mutationTypes.START_PRODUCT_DEFINITION_JOB, "get-product");
        // const query = `query { products(filters: {productDefinitionId: {equals: "${productDefinitionId}"}}){ id,productDefinition{id,name,apiConfig{... on CreditAccountApiConfig{source,currencyCode,amount}}},orderId,orderLineItemId,productVariantId,status,conditionsString,reference,satisfiedConditions }}`;
        const query = `query { orderLineItems(filters: {productDefinitionId: {equals: "${productDefinitionId}"}, status: {notContains: ["removed", "returned"]}}){... on ProductLineItem{id,orderId,type,status,price,tax,currency{name,code,exponent,symbol},name,customer{id,fullName},product{id,reference},order{id,email,createDate}}}}`;
        const result = await backoffice.sendQuery(query);
        if (result.error) {
            commit(mutationTypes.PRODUCT_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, "get-product");
        } else {
            commit(mutationTypes.RECEIVED_PRODUCTS, { products: result.data.orderLineItems });
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, "get-product");
        }
    },

    async saveProductDefinitionMutations({ commit, state }, mutations) {
        commit(mutationTypes.START_PRODUCT_DEFINITION_JOB, "save-product-definition");
        const result = await backoffice.sendCommandBatch(mutations);
        if (result.error) {
            commit(mutationTypes.PRODUCT_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, "save-product-definition");
        } else {
            commit(
                mutationTypes.SAVED_PRODUCT_DEFINITION,
                mutations.reduce(
                    (state, command) => Object.assign(state, command.data),
                    {}
                )
            );
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, "save-product-definition");
        }
    },

    async createProductDefinition({ commit, state }, {productDefinition}) {
        const jobType = 'save-product-definition';
        commit(mutationTypes.START_PRODUCT_DEFINITION_JOB, jobType);
        const result = await backoffice.sendCommand('CreateProductDefinition', productDefinition);
        if (result.error) {
            commit(mutationTypes.PRODUCT_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobType);
        } else {
            commit(mutationTypes.CREATED_PRODUCT_DEFINITION, productDefinition);
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobType);
            // router.push({ name: "product-definition-detail", params: { id: result.data.productDefinitionId } }, () => {router.go()});
            router.push({ name: "product-definition-detail", params: { id: result.data.productDefinitionId } });
        }
    },

    async publishProductDefinition({ commit, state }, {productDefinitionId}) {
        const jobType = 'save-product-definition';
        commit(mutationTypes.START_PRODUCT_DEFINITION_JOB, jobType);
        let result = await backoffice.sendCommand("PublishProductDefinition", {aggregateId: productDefinitionId});
        if (result.error) {
            commit(mutationTypes.PRODUCT_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobType);
        } else {
            commit(mutationTypes.CLEAR_PRODUCT_DEFINITION);
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobType);
            router.go();
        }
    },

    async draftProductDefinition({ commit, state }, {productDefinitionId}) {
        const jobType = 'save-product-definition';
        commit(mutationTypes.START_PRODUCT_DEFINITION_JOB, jobType);
        let result = await backoffice.sendCommand("DraftProductDefinition", {aggregateId: productDefinitionId});
        if (result.error) {
            commit(mutationTypes.PRODUCT_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobType);
        } else {
            commit(mutationTypes.CLEAR_PRODUCT_DEFINITION);
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobType);
            router.go();
        }
    },

    async getCreditAccountCurrencies({ commit, state }) {
        const jobType = 'get-product';
        commit(mutationTypes.START_PRODUCT_DEFINITION_JOB, jobType);
        const query = `query { creditAccountCurrencies{id,code,name,exponent,symbol,receivingAccountNumber} }`;
        const result = await backoffice.sendQuery(query);
        if (result.error) {
            commit(mutationTypes.PRODUCT_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobType);
        } else {
            commit(mutationTypes.RECEIVED_CREDIT_ACCOUNT_CURRENCIES, { creditAccountCurrencies: result.data.creditAccountCurrencies });
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobType);
        }
    },

    async createCreditAccountCurrency({ commit, state }, {name, code, exponent, symbol}) {
        const jobType = 'save-product-definition';
        commit(mutationTypes.START_PRODUCT_DEFINITION_JOB, jobType);
        const result = await backoffice.sendCommand('CreateAccountCurrency', {name, code, exponent, symbol});
        if (result.error) {
            commit(mutationTypes.PRODUCT_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobType);
            return false;
        } else {
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobType);
            return true;
        }
    },

    async getCurrencyOptions({ commit, dispatch }) {
        const jobName = 'get-product-option';
        commit(mutationTypes.START_PRODUCT_DEFINITION_JOB, jobName);
        await dispatch('core/getCurrencyOptions', null, {root:true});
        commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobName);
    },

    async createMembershipDefinition({ commit, state }, {name, description, validityPeriod, tags}) {
        const jobType = 'save-product-definition';
        commit(mutationTypes.START_PRODUCT_DEFINITION_JOB, jobType);
        const result = await backoffice.sendCommand('CreateMembershipDefinition', {name, description, validityPeriod, tags});
        if (result.error) {
            commit(mutationTypes.PRODUCT_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobType);
            return false;
        } else {
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobType);
            return result.data.membershipDefinitionId;
        }
    },

    async getPackageDefinitions({ commit, state }) {
        const jobType = 'get-product';
        commit(mutationTypes.START_PRODUCT_DEFINITION_JOB, jobType);
        const query = `query { packageDefinitions(first: 0, offset: 500){ id,name,description }}`;
        const result = await backoffice.sendQuery(query);
        if (result.error) {
            commit(mutationTypes.PRODUCT_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobType);
        } else {
            commit(mutationTypes.RECEIVED_PACKAGE_DEFINITIONS, { packageDefinitions: result.data.packageDefinitions });
            commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobType);
        }
    },

    async startBatchOperation({ commit, dispatch }, operations) {
        const jobName = 'start-batch-operation';
        commit(mutationTypes.START_PRODUCT_DEFINITION_JOB, jobName);
        await dispatch('core/startBatchOperation', operations, {root:true});
        commit(mutationTypes.END_PRODUCT_DEFINITION_JOB, jobName);
    },

    clearProductDefinition({ commit, state }) {
        commit(mutationTypes.CLEAR_PRODUCT_DEFINITION);
    },


};
