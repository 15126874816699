export default {
  error: state => state.error,
  jobs: state => state.jobs,

  events: state => state.events,
  event: state => state.event,
  // isBasedOnTemplate: state => !!(state.event && state.event.eventTemplate && state.event.eventTemplate.id !== null),
  isBasedOnTemplate: (state) => {
    if(state.event && state.event.eventTemplate && state.event.eventTemplate.id !== null) {
      return true;
    }
    if(state.event && state.event.eventCapacityTemplate && state.event.eventCapacityTemplate.id !== null) {
      return true;
    }
    if(state.event && state.event.eventAccessTemplate && state.event.eventAccessTemplate.id !== null) {
      return true;
    }
    return false;
  },
  isBasedOnEventTemplate: (state) => {
    return state.event && state.event.eventTemplate && state.event.eventTemplate.id !== null;
  },
  isBasedOnCapacityTemplate: (state) => {
    return state.event && state.event.eventCapacityTemplate && state.event.eventCapacityTemplate.id !== null;
  },
  isBasedOnAccessTemplate: (state) => {
    return state.event && state.event.eventAccessTemplate && state.event.eventAccessTemplate.id !== null;
  },
  hasTimeSlots: (state) => {
    return state.event && state.event.hasTimeslots;
  },

  eventTemplateName: state => state.event && state.event.eventTemplate ? state.event.eventTemplate.name : null,
  eventTemplateId: state => state.event && state.event.eventTemplate ? state.event.eventTemplate.id : null,
  eventTemplateStatus: state => state.event ? state.event.eventTemplateApplyStatus : null,
  eventCapacityTemplateName: state => state.event && state.event.eventCapacityTemplate ? state.event.eventCapacityTemplate.name : null,
  eventCapacityTemplateId: state => state.event && state.event.eventCapacityTemplate ? state.event.eventCapacityTemplate.id : null,
  eventCapacityTemplateStatus: state => state.event ? state.event.eventCapacityTemplateApplyStatus : null,
  eventAccessTemplateName: state => state.event && state.event.eventAccessTemplate ? state.event.eventAccessTemplate.name : null,
  eventAccessTemplateId: state => state.event && state.event.eventAccessTemplate ? state.event.eventAccessTemplate.id : null,
  eventAccessTemplateStatus: state => state.event ? state.event.eventAccessTemplateApplyStatus : null,

  eventAttendees: state => state.eventAttendees,
  eventAttendeeImportPriceList: state => state.eventAttendeeImportPriceList,
  accessDefinitions: state => state.accessDefinitions,
  accessDefinition: state => state.accessDefinition,
  capacities: state => state.capacities,
  capacity: state => state.capacity,
  eventListView: state => state.eventListView,
  eventDetailView: state => state.eventDetailView,
  accessDefinitionListView: state => state.accessDefinitionListView,
  accessDefinitionDetailView: state => state.accessDefinitionDetailView,
  capacityDetailView: state => state.capacityDetailView,

  capacityLocationOptions: state => {
    let options = [];
    if(state.accessDefinitionDetailView.form.schema.groups) {
      state.accessDefinitionDetailView.form.schema.groups.forEach(group => {
        group.fields.forEach(field => {
          if (field.model === "capacityLocations")
            options = field.values;
        });
      });
    }
    return options;
  },

};
