export const PRODUCT_DEFINITION_ERROR = "PRODUCT_DEFINITION_ERROR";
export const FLUSH_PRODUCT_DEFINITION_ERROR = "FLUSH_PRODUCT_DEFINITION_ERROR";

export const START_PRODUCT_DEFINITION_JOB = "START_PRODUCT_DEFINITION_JOB";
export const END_PRODUCT_DEFINITION_JOB = "END_PRODUCT_DEFINITION_JOB";

export const RECEIVED_PRODUCT_DEFINITIONS = "RECEIVED_PRODUCT_DEFINITIONS";
export const RECEIVED_PRODUCT_DEFINITION = "RECEIVED_PRODUCT_DEFINITION";
export const SAVED_PRODUCT_DEFINITION = "SAVED_PRODUCT_DEFINITION";
export const CREATED_PRODUCT_DEFINITION = "CREATED_PRODUCT_DEFINITION";
export const CLEAR_PRODUCT_DEFINITION = "CLEAR_PRODUCT_DEFINITION";

export const RECEIVED_PRODUCTS = "RECEIVED_PRODUCTS";
export const RECEIVED_CREDIT_ACCOUNT_CURRENCIES = "RECEIVED_CREDIT_ACCOUNT_CURRENCIES";
export const RECEIVED_PACKAGE_DEFINITIONS = "RECEIVED_PACKAGE_DEFINITIONS";
