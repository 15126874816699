import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en";
import nl from "./nl";
// import enCountries from 'i18n-iso-countries/langs/en.json';
// import nlCountries from 'i18n-iso-countries/langs/nl.json';
//
// // add country names translations
// en.countries = enCountries.countries;
// nl.countries = nlCountries.countries;

//
Vue.use(VueI18n);

//
export default new VueI18n({
  locale: "en", // set locale
  messages: {
    en: en,
    nl: nl
  }
});

// export default new VueI18n({
//     locale: 'en', // set locale
//     messages: {
//         en: Object.assign(en, enLocale),
//         nl: Object.assign(nl, nlLocale),
//     }
// })
