import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  jobs: [],
  error: [],
  users: [],
  user: {},
  tags: [],
  tag: {},
  coupons: [],
  coupon: {},
  tokens: [],
  emailTemplates: [],
  emailTemplate: {},
  documentTemplates: [],
  documentTemplate: {},
  salesChannels: [],
  salesChannel: {},
  paymentMethods: [],
  paymentMethod: {},
  optInDefinitions: [],
  optInDefinition: {},
  orderMessageDefinitions: [],
  orderMessageDefinition: {},
  integration: {
    productDefinitions: [],
    prices: [],
    fiona: {
      proxy: {
        editionTypes: [],
        editions: [],
        guestbooks: [],
        accreditations: [],
        volunteerEditions: [],
        volunteers: [],
        shows: [],
        accreditationBadgeTypes: [],
        accreditationBadgeStatus: [],
        volunteerBadgeTypes: [],
      },
      config: {},
      entityMaps: [],
      entityMap: {},
      accreditations: [],
      volunteers: [],
      events: [],
      syncQueue: [],
    }
  }
};

//
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
