<script>
export default {
  name: "BaseHeading",
  render: function(createElement) {
    return createElement(
      `h${this.level}`,
      {
        class: `h${this.styleAs ? this.styleAs : this.level}`
      },
      this.$slots.default,
      `Header ${this.level}`
    );
  },
  props: {
    level: {
      type: Number,
      required: true,
      validator: value => {
        return [1, 2].includes(value);
      }
    },
    styleAs: {
      type: Number
    }
  }
};
</script>

<style scoped lang="scss">
$levels: 1 2;

@include includeType($levels, 'h');
</style>
