import * as mutationTypes from "../mutation-types";
import BackendService from "@/services/backend.service";
import GraphqlQueryBuilder from "@/services/GraphqlQueryBuilder.service";
import validationService from "@/services/validation.service";
import router from "@/router";

const backoffice = new BackendService();

export default {
  async getUsers({ commit, state }, query) {
    commit(mutationTypes.START_SYSTEM_JOB, "get-user");
    // const backoffice = new BackendService();
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "get-user");
    } else {
      commit(mutationTypes.RECEIVED_USERS, { users: result.data.users });
      commit(mutationTypes.END_SYSTEM_JOB, "get-user");
    }
  },

  async getUser({ commit, state }, {id}) {
    commit(mutationTypes.START_SYSTEM_JOB, "get-user");
    commit(mutationTypes.CLEAR_USER);
    // const result = await backoffice.sendQuery(query);
    const result = await backoffice.sendQuery(`query { user(id: "${id}"){id,username,scopes,allowedRegisters{id,name},disabled} }`);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "get-user");
    } else {
      commit(mutationTypes.RECEIVED_USER, { user: result.data.user });
      commit(mutationTypes.END_SYSTEM_JOB, "get-user");
    }
  },

  async saveUserMutations({ commit, state, dispatch }, mutations) {
    commit(mutationTypes.START_SYSTEM_JOB, "save-user");
    // const backoffice = new BackendService();
    const result = await backoffice.sendCommandBatch(mutations);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "save-user");
    } else {
      const userId = mutations[0].data.id;
      dispatch('getUser', {id: userId});
      commit(mutationTypes.END_SYSTEM_JOB, "save-user");
    }
  },

  async createNewUser({ commit, state }, command) {
    commit(mutationTypes.START_SYSTEM_JOB, "save-user");
    // const backoffice = new BackendService();
    let result = await backoffice.sendCommand("CreateUser", command.data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "save-user");
    } else {
      command.data.id = result.data.userId;
      commit(mutationTypes.SAVED_USER, command.data);
      commit(mutationTypes.END_SYSTEM_JOB, "save-user");
      router.push({
        name: "system-user-detail",
        params: {
          id: command.data.id
        }
      });
    }
  },

  clearUser({ commit, state }) {
    commit(mutationTypes.CLEAR_USER);
  },




  async getTags({ commit, state }, query) {
    commit(mutationTypes.START_SYSTEM_JOB, "get-tag");
    // const backoffice = new BackendService();
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "get-tag");
    } else {
      commit(mutationTypes.RECEIVED_TAGS, { tags: result.data.tags });
      commit(mutationTypes.END_SYSTEM_JOB, "get-tag");
    }
  },

  async getTag({ commit, state }, query) {
    commit(mutationTypes.START_SYSTEM_JOB, "get-tag");
    commit(mutationTypes.CLEAR_TAG);
    // const backoffice = new BackendService();
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "get-tag");
    } else {
      commit(mutationTypes.RECEIVED_TAG, { tag: result.data.tag });
      commit(mutationTypes.END_SYSTEM_JOB, "get-tag");
    }
  },

  async saveTagMutations({ commit, state }, mutations) {
    commit(mutationTypes.START_SYSTEM_JOB, "save-tag");
    // const backoffice = new BackendService();
    const result = await backoffice.sendCommandBatch(mutations);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "save-tag");
    } else {
      commit(
          mutationTypes.SAVED_TAG,
          mutations.reduce(
              (state, command) => Object.assign(state, command.data),
              {}
          )
      );
      commit(mutationTypes.END_SYSTEM_JOB, "save-tag");
    }
  },

  async createNewTag({ commit, state }, data) {
    commit(mutationTypes.START_SYSTEM_JOB, "save-tag");
    // const backoffice = new BackendService();
    let result = await backoffice.sendCommand("CreateTag", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "save-tag");
    } else {
      data.id = result.data.id;
      commit(mutationTypes.SAVED_TAG, data);
      commit(mutationTypes.END_SYSTEM_JOB, "save-tag");
      router.push({ name: "system-tag-detail", params: { id: data.id } });
    }
  },

  async removeTag({ commit, state }, data) {
    commit(mutationTypes.START_SYSTEM_JOB, "save-tag");
    // const backoffice = new BackendService();
    let result = await backoffice.sendCommand("RemoveTag", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "save-tag");
    } else {
      commit(mutationTypes.CLEAR_TAG);
      commit(mutationTypes.END_SYSTEM_JOB, "save-tag");
      router.push({
        name: "system-tag"
      });
    }
  },

  clearTag({ commit, state }) {
    commit(mutationTypes.CLEAR_TAG);
  },




  async getCoupons({ commit }, query) {
    commit(mutationTypes.START_SYSTEM_JOB, "get-coupon");
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "get-coupon");
    } else {
      commit(mutationTypes.RECEIVED_COUPONS, { coupons: result.data.tokenTypes });
      commit(mutationTypes.END_SYSTEM_JOB, "get-coupon");
    }
  },

  async getCoupon({ commit }, query) {
    commit(mutationTypes.START_SYSTEM_JOB, "get-coupon");
    commit(mutationTypes.CLEAR_COUPON);
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "get-coupon");
    } else {
      commit(mutationTypes.RECEIVED_COUPON, { coupon: result.data.tokenType });
      commit(mutationTypes.END_SYSTEM_JOB, "get-coupon");
    }
  },

  async changeCoupon({ commit }, data) {
    commit(mutationTypes.START_SYSTEM_JOB, "save-coupon");
    let result = await backoffice.sendCommand("ChangeCoupon", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "save-coupon");
    } else {
      commit(mutationTypes.SAVED_COUPON, data);
      commit(mutationTypes.END_SYSTEM_JOB, "save-coupon");
    }
  },

  async createCoupon({ commit }, data) {
    commit(mutationTypes.START_SYSTEM_JOB, "save-coupon");
    let result = await backoffice.sendCommand("CreateCoupon", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "save-coupon");
    } else {
      data.id = result.data.couponId;
      commit(mutationTypes.SAVED_COUPON, data);
      commit(mutationTypes.END_SYSTEM_JOB, "save-coupon");
      router.push({ name: "system-coupon-detail", params: { id: data.id } });
    }
  },

  async getTokens({ commit }, query) {
    commit(mutationTypes.START_SYSTEM_JOB, "get-coupon");
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "get-coupon");
    } else {
      commit(mutationTypes.RECEIVED_TOKENS, { tokens: result.data.tokens });
      commit(mutationTypes.END_SYSTEM_JOB, "get-coupon");
    }
  },

  async createTokens({ commit, dispatch }, data) {
    commit(mutationTypes.START_SYSTEM_JOB, "save-coupon");
    let result = await backoffice.sendCommand("CreateTokens", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "save-coupon");
    } else {
      commit(mutationTypes.CLEAR_COUPON);
      commit(mutationTypes.END_SYSTEM_JOB, "save-coupon");
      dispatch('getTokens', `query { tokens(filters: {typeId: {equals: "${data.typeId}"}}){id,typeId,token,used} }`);
    }
  },

  async removeToken({ commit, dispatch }, data) {
    commit(mutationTypes.START_SYSTEM_JOB, "save-coupon");
    let result = await backoffice.sendCommand("RemoveToken", data.ids);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, "save-coupon");
    } else {
      commit(mutationTypes.CLEAR_COUPON);
      commit(mutationTypes.END_SYSTEM_JOB, "save-coupon");
      // router.push({ name: "system-coupon" });
      dispatch('getTokens', `query { tokens(filters: {typeId: {equals: "${data.typeId}"}}){id,typeId,token,used} }`);
    }
  },

  clearCoupon({ commit }) {
    commit(mutationTypes.CLEAR_COUPON);
  },





  async getEmailTemplates({ commit }, query) {
    const jobName = 'get-email-template';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_EMAIL_TEMPLATES, { emailTemplates: result.data.emailTemplates });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getEmailTemplate({ commit }, id) {
    const query = `query { emailTemplate(id: "${id}"){id,from,name,subject,template,senderName,cc,bcc,tracking,attachments{template,filename},documentAttachments{id,documentTemplateId,documentTemplate{id,name},type,filename,priority,conditionsString,multiple},allowedRegisters{id,name},mutationDate} }`;
    const jobName = 'get-email-template';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    commit(mutationTypes.CLEAR_COUPON);
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      if(result.data.emailTemplate.documentAttachments) result.data.emailTemplate.documentAttachments = result.data.emailTemplate.documentAttachments.map(d => {
        d.conditions = d.conditionsString ? JSON.parse(d.conditionsString) : {};
        return d;
      });
      commit(mutationTypes.RECEIVED_EMAIL_TEMPLATE, { emailTemplate: result.data.emailTemplate });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async sendEmailTemplates({ commit }, data) {
    const jobName = 'send-email-template';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("SendEmailTemplate", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async saveEmailTemplate({ commit, state, dispatch }, data) {
    const jobName = 'save-email-template';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("EditEmailTemplate", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.SAVED_EMAIL_TEMPLATE, data);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      // dispatch('getEmailTemplate', data.id);
      // router.push({ name: "system-email-template-detail", params: { id: data.id } });
    }
  },

  async saveEmailTemplateDocumentAttachment({ commit, state, dispatch }, data) {
    const jobName = 'save-email-template';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("EditEmailTemplate", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.SAVED_EMAIL_TEMPLATE, data);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      dispatch('getEmailTemplate', data.id);
      router.push({ name: "system-email-template-document-attachment", params: { id: data.id } });
    }
  },

  async createEmailTemplate({ commit, state }, data) {
    const jobName = 'save-email-template';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("AddEmailTemplate", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      data.id = result.data.templateId;
      commit(mutationTypes.SAVED_EMAIL_TEMPLATE, data);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.push({ name: "system-email-template-detail", params: { id: data.id } });
    }
  },

  async removeEmailTemplate({ commit, state }, id) {
    const jobName = 'save-email-template';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("RemoveEmailTemplate", {id: id});
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.CLEAR_EMAIL_TEMPLATE);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.push({ name: "system-email-template" });
    }
  },

  clearEmailTemplate({ commit, state }) {
    commit(mutationTypes.CLEAR_EMAIL_TEMPLATE);
  },




  async getDocumentTemplates({ commit }, {rows, columns, filters, sort, alwaysIncludeFields}) {
    const jobName = 'get-document-template';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    commit(mutationTypes.CLEAR_DOCUMENT_TEMPLATE);

    // build query
    const builder = new GraphqlQueryBuilder();
    builder.setType('documentTemplates');
    builder.setRows(rows);
    builder.setSort(sort);
    builder.setColumns(columns, alwaysIncludeFields || ["id"]);
    if( validationService.validateDatatableFilters(filters) ) {
      builder.setFilters(filters);
    }
    const query = builder.build();

    //
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
    } else {
      commit(mutationTypes.RECEIVED_DOCUMENT_TEMPLATES, { documentTemplates: result.data.documentTemplates });
    }
    commit(mutationTypes.END_SYSTEM_JOB, jobName);
  },

  async getDocumentTemplate({ commit }, id) {
    const query = `query { documentTemplate(id: "${id}"){id,name,type,template,paperSize,paperOrientation,allowedRegisters{id,name}} }`;
    const jobName = 'get-document-template';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    commit(mutationTypes.CLEAR_DOCUMENT_TEMPLATE);
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_DOCUMENT_TEMPLATE, { documentTemplate: result.data.documentTemplate });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async saveDocumentTemplate({ commit, state, dispatch }, data) {
    const jobName = 'save-document-template';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("ChangeDocumentTemplate", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.SAVED_DOCUMENT_TEMPLATE, data);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      dispatch('getDocumentTemplate', data.id);
      // router.push({ name: "system-document-template-detail", params: { id: data.id } });
    }
  },

  async createDocumentTemplate({ commit, state }, data) {
    const jobName = 'save-document-template';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("CreateDocumentTemplate", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      data.id = result.data.templateId;
      commit(mutationTypes.SAVED_DOCUMENT_TEMPLATE, data);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.push({ name: "system-document-template-detail", params: { id: data.id } });
    }
  },

  async removeDocumentTemplate({ commit, state }, id) {
    const jobName = 'save-document-template';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("RemoveDocumentTemplate", {id: id});
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.CLEAR_EMAIL_TEMPLATE);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.push({ name: "system-document-template" });
    }
  },

  clearDocumentTemplate({ commit, state }) {
    commit(mutationTypes.CLEAR_DOCUMENT_TEMPLATE);
  },
  




  async getSalesChannels({ commit }, query) {
    const jobName = 'get-sales-channel';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_SALES_CHANNELS, { salesChannels: result.data.salesChannels });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getSalesChannel({ commit }, id) {
    // const query = `query { salesChannel(id: "${id}"){id,name,description,registers{id,name,description,deliveryDefinition{id,name,type,deliveryTemplate{... on EmailTemplate{id,name}}},orderTimeout{id,type,value}}} }`;
    const query = `query { salesChannel(id: "${id}"){id,name,description,registers{id,name,description,deliveryDefinitions{id,name,type,eventType,priority,deliveryConditionsString,deliveryTemplate{... on EmailTemplate{id,name}}},orderTimeout{id,type,value}}} }`;
    const jobName = 'get-sales-channel';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    commit(mutationTypes.CLEAR_COUPON);
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      const salesChannel = result.data.salesChannel;
      salesChannel.registers.map((r) => {
        r.deliveryDefinitions = r.deliveryDefinitions.map((dd) => {
          dd.deliveryConditions = dd.deliveryConditionsString ? JSON.parse(dd.deliveryConditionsString) : null;
          return dd;
        });
        return r;
      });
      // commit(mutationTypes.RECEIVED_SALES_CHANNEL, { salesChannel: result.data.salesChannel });
      commit(mutationTypes.RECEIVED_SALES_CHANNEL, { salesChannel: salesChannel });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async renameSalesChannel({ commit, state }, data) {
    const jobName = 'save-sales-channel';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("RenameSalesChannel", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.SAVED_SALES_CHANNEL, data);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async createSalesChannel({ commit, state }, data) {
    const jobName = 'save-sales-channel';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("CreateSalesChannel", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      data.id = result.data.salesChannelId;
      commit(mutationTypes.CREATED_SALES_CHANNEL, data);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.push({ name: "system-sales-channel-detail", params: { id: data.id } });
    }
  },

  async renameRegister({ commit, state }, data) {
    const jobName = 'save-sales-channel';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("RenameRegister", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.SAVED_REGISTER, data);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async createRegister({ commit, state }, data) {
    const jobName = 'save-sales-channel';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("AddRegister", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      data.id = result.data.registerId;
      commit(mutationTypes.CREATED_REGISTER, data);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.push({ name: "system-sales-channel-register-detail", params: { id: data.aggregateId, registerId: data.id } });
    }
  },

  async removeRegister({ commit, state }, {salesChannelId, registerId}) {
      const jobName = 'save-sales-channel';
      commit(mutationTypes.START_SYSTEM_JOB, jobName);
      let result = await backoffice.sendCommand("RemoveRegister", {aggregateId: salesChannelId, registerId});
      if (result.error) {
          commit(mutationTypes.SYSTEM_ERROR, result.error);
          commit(mutationTypes.END_SYSTEM_JOB, jobName);
      } else {
          commit(mutationTypes.REMOVED_REGISTER, {registerId});
          commit(mutationTypes.END_SYSTEM_JOB, jobName);
          router.push({ name: "system-sales-channel-register", params: { id: salesChannelId } });
      }
  },

  async deliveryDefinitionMutations({ commit, state }, {commands}) {
    const jobName = 'save-sales-channel';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommandBatch(commands);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      // commit(mutationTypes.CREATED_REGISTER, data);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.go();
    }
  },

  async addDeliveryDefinition({ commit, state }, {name, type, templateId, salesChannelId, registerId}) {
    const jobName = 'save-sales-channel';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("AddDeliveryDefinition", {name, type, templateId, salesChannelId, registerId, eventType: 'OrderCompleted'});
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      // commit(mutationTypes.CREATED_REGISTER, data);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.go();
    }
  },

  async changeDeliveryDefinition({ commit, state }, {id, name, type, templateId, salesChannelId, registerId}) {
    const jobName = 'save-sales-channel';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("EditDeliveryDefinition", {id, name, type, templateId, salesChannelId, registerId, eventType: 'OrderCompleted'});
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      // commit(mutationTypes.CREATED_REGISTER, data);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.go();
    }
  },

    async removeDeliveryDefinition({ commit, state }, {id}) {
        const jobName = 'save-sales-channel';
        commit(mutationTypes.START_SYSTEM_JOB, jobName);
        let result = await backoffice.sendCommand("RemoveDeliveryDefinition", {id});
        if (result.error) {
            commit(mutationTypes.SYSTEM_ERROR, result.error);
            commit(mutationTypes.END_SYSTEM_JOB, jobName);
        } else {
            // commit(mutationTypes.CREATED_REGISTER, data);
            commit(mutationTypes.END_SYSTEM_JOB, jobName);
            // router.go();
        }
    },



    async addOrderTimeout({ commit, state }, {type, value, salesChannelId, registerId}) {
        const jobName = 'save-sales-channel';
        commit(mutationTypes.START_SYSTEM_JOB, jobName);
        let result = await backoffice.sendCommand("AddTimeoutSetting", {salesChannelId, registerId, type, value});
        if (result.error) {
            commit(mutationTypes.SYSTEM_ERROR, result.error);
            commit(mutationTypes.END_SYSTEM_JOB, jobName);
        } else {
            commit(mutationTypes.SAVE_REGISTER_ORDER_TIMEOUT, {registerId, type, value, id: result.data.id});
            commit(mutationTypes.END_SYSTEM_JOB, jobName);
        }
    },

    async editOrderTimeout({ commit, state }, {id, type, value, salesChannelId, registerId}) {
        const jobName = 'save-sales-channel';
        commit(mutationTypes.START_SYSTEM_JOB, jobName);
        let result = await backoffice.sendCommand("ChangeTimeoutSetting", {id, salesChannelId, registerId, type, value});
        if (result.error) {
            commit(mutationTypes.SYSTEM_ERROR, result.error);
            commit(mutationTypes.END_SYSTEM_JOB, jobName);
        } else {
            commit(mutationTypes.SAVE_REGISTER_ORDER_TIMEOUT, {registerId, id, type, value});
            commit(mutationTypes.END_SYSTEM_JOB, jobName);
        }
    },

    async removeOrderTimeout({ commit, state }, {id, salesChannelId, registerId}) {
        const jobName = 'save-sales-channel';
        commit(mutationTypes.START_SYSTEM_JOB, jobName);
        let result = await backoffice.sendCommand("RemoveTimeoutSetting", {id});
        if (result.error) {
            commit(mutationTypes.SYSTEM_ERROR, result.error);
            commit(mutationTypes.END_SYSTEM_JOB, jobName);
        } else {
            commit(mutationTypes.REMOVE_REGISTER_ORDER_TIMEOUT, {registerId});
            commit(mutationTypes.END_SYSTEM_JOB, jobName);
        }
    },

  clearSalesChannel({ commit, state }) {
    commit(mutationTypes.CLEAR_SALES_CHANNEL);
  },







  async getPaymentMethods({ commit }, {rows, sort, columns, filters, alwaysIncludeFields}) {
    const jobName = 'get-payment-method';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);

    // build query
    const builder = new GraphqlQueryBuilder();
    builder.setType('alternativePaymentMethods');
    builder.setRows(rows);
    builder.setSort(sort);
    // builder.setColumns(columns, alwaysIncludeFields || ["id", "allowedRegisters{id,name}"]);
    builder.setColumns(columns, alwaysIncludeFields || ["id"]);
    if( validationService.validateDatatableFilters(filters) ) {
      builder.setFilters(filters);
    }
    const query = builder.build();

    // send query
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_PAYMENT_METHODS, { paymentMethods: result.data.alternativePaymentMethods });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getPaymentMethod({ commit }, {id}) {
    const query = `query { alternativePaymentMethod(id: "${id}"){id,name,key,status,allowedRegisters{id,name}}}`;
    const jobName = 'get-payment-method';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    commit(mutationTypes.CLEAR_PAYMENT_METHOD);
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_PAYMENT_METHOD, { paymentMethod: result.data.alternativePaymentMethod });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async savePaymentMethod({ commit, state, dispatch }, {commands, paymentMethodId}) {
    const jobName = 'save-payment-method';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.sendCommandBatch(commands);
    // let result = await backoffice.sendCommand("RenameSalesChannel", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.SAVED_PAYMENT_METHOD, commands.reduce((state, command) => Object.assign(state, command.data), {}));
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      dispatch('getPaymentMethod', {id: paymentMethodId});
    }
  },

  async createPaymentMethod({ commit, state, dispatch }, data) {
    const jobName = 'save-payment-method';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("CreateAlternativePaymentMethod", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      data.id = result.data.paymentMethodId;
      commit(mutationTypes.CREATED_PAYMENT_METHOD, data);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      dispatch('getPaymentMethod', {id: data.id});
      router.push({ name: "system-payment-method-detail", params: { id: data.id } });
    }
  },

  clearPaymentMethod({ commit, state }) {
    commit(mutationTypes.CLEAR_PAYMENT_METHOD);
  },





  async getOptInDefinitions({ commit }, {rows, sort, columns, filters, alwaysIncludeFields}) {
    const jobName = 'get-opt-in-definition';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);

    // build query
    const builder = new GraphqlQueryBuilder();
    builder.setType('optInDefinitions');
    builder.setRows(rows);
    builder.setSort(sort);
    builder.setColumns(columns, alwaysIncludeFields || ["id"]);
    if( validationService.validateDatatableFilters(filters) ) {
      builder.setFilters(filters);
    }
    const query = builder.build();

    // send query
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_OPT_IN_DEFINITIONS, { optInDefinitions: result.data.optInDefinitions });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getOptInDefinition({ commit }, {id}) {
    const query = `query { optInDefinition(id: "${id}"){id,question,conditionsString,textTranslations{languageCode,text}}}`;
    const jobName = 'get-opt-in-definition';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    commit(mutationTypes.CLEAR_OPT_IN_DEFINITION);
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      result.data.optInDefinition.conditions = result.data.optInDefinition && result.data.optInDefinition.conditionsString ? JSON.parse(result.data.optInDefinition && result.data.optInDefinition.conditionsString) : {};
      commit(mutationTypes.RECEIVED_OPT_IN_DEFINITION, { optInDefinition: result.data.optInDefinition });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async saveOptInDefinition({ commit, state, dispatch }, {commands, id}) {
    const jobName = 'save-opt-in-definition';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.sendCommandBatch(commands);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.SAVED_OPT_IN_DEFINITION, commands.reduce((state, command) => Object.assign(state, command.data), {}));
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      dispatch('getOptInDefinition', {id: id});
    }
  },

  async createOptInDefinition({ commit, state, dispatch }, data) {
    const jobName = 'save-opt-in-definition';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("CreateOptInDefinition", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      data.id = result.data.optInDefinitionId;
      commit(mutationTypes.CREATED_OPT_IN_DEFINITION, data);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      dispatch('getOptInDefinition', {id: data.id});
      router.push({ name: "system-opt-in-definition-detail", params: { id: data.id } });
    }
  },

  clearOptInDefinition({ commit, state }) {
    commit(mutationTypes.CLEAR_OPT_IN_DEFINITION);
  },






  async getOrderMessageDefinitions({ commit }, {rows, sort, columns, filters, alwaysIncludeFields}) {
    const jobName = 'get-order-message-definition';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);

    // build query
    const builder = new GraphqlQueryBuilder();
    builder.setType('orderMessageDefinitions');
    builder.setRows(rows);
    builder.setSort(sort);
    builder.setColumns(columns, alwaysIncludeFields || ["id"]);
    if( validationService.validateDatatableFilters(filters) ) {
      builder.setFilters(filters);
    }
    const query = builder.build();

    // send query
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_ORDER_MESSAGE_DEFINITIONS, { orderMessageDefinitions: result.data.orderMessageDefinitions });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getOrderMessageDefinition({ commit }, {id}) {
    const query = `query { orderMessageDefinition(id: "${id}"){id,message,stages,conditionsString,textTranslations{languageCode,text}}}`;
    const jobName = 'get-order-message-definition';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    commit(mutationTypes.CLEAR_ORDER_MESSAGE_DEFINITION);
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      result.data.orderMessageDefinition.conditions = result.data.orderMessageDefinition && result.data.orderMessageDefinition.conditionsString ? JSON.parse(result.data.orderMessageDefinition && result.data.orderMessageDefinition.conditionsString) : {};
      commit(mutationTypes.RECEIVED_ORDER_MESSAGE_DEFINITION, { orderMessageDefinition: result.data.orderMessageDefinition });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async saveOrderMessageDefinition({ commit, state, dispatch }, {commands, id}) {
    const jobName = 'save-order-message-definition';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.sendCommandBatch(commands);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.SAVED_ORDER_MESSAGE_DEFINITION, commands.reduce((state, command) => Object.assign(state, command.data), {}));
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      dispatch('getOrderMessageDefinition', {id: id});
    }
  },

  async createOrderMessageDefinition({ commit, state, dispatch }, data) {
    const jobName = 'save-order-message-definition';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("CreateOrderMessageDefinition", data);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      data.id = result.data.orderMessageDefinitionId;
      commit(mutationTypes.CREATED_ORDER_MESSAGE_DEFINITION, data);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      dispatch('getOrderMessageDefinition', {id: data.id});
      router.push({ name: "system-order-message-definition-detail", params: { id: data.id } });
    }
  },

  async removeOrderMessageDefinition({ commit, state, dispatch }, {id}) {
    const jobName = 'save-order-message-definition';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    let result = await backoffice.sendCommand("RemoveOrderMessageDefinition", {id});
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.push({ name: "system-order-message-definition"});
    }
  },

  clearOrderMessageDefinition({ commit, state }) {
    commit(mutationTypes.CLEAR_ORDER_MESSAGE_DEFINITION);
  },
  





  async getFionaConfig({ commit, state }) {
    const jobName = 'get-integration-fiona-config';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/config', null, null, null, 'GET');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_CONFIG, { config: result.data.config });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  // async getFionaEntityMaps({ commit, state }) {
  //   const jobName = 'get-integration-fiona';
  //   commit(mutationTypes.START_SYSTEM_JOB, jobName);
  //   const result = await backoffice.callAdminApi('order-sync/gateway/fiona/map/accreditation', null, null, null, 'GET');
  //   if (result.error) {
  //     commit(mutationTypes.SYSTEM_ERROR, result.error);
  //     commit(mutationTypes.END_SYSTEM_JOB, jobName);
  //   } else {
  //     commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_ENTITY_MAPS, { entityMaps: result.data.entityMaps });
  //     commit(mutationTypes.END_SYSTEM_JOB, jobName);
  //   }
  // },
  //
  // async getFionaEntityMap({ commit, state }, {id}) {
  //   const jobName = 'get-integration-fiona';
  //   commit(mutationTypes.START_SYSTEM_JOB, jobName);
  //   commit(mutationTypes.CLEAR_INTEGRATION_FIONA_ENTITY_MAP);
  //   const result = await backoffice.callAdminApi('integration/fiona/map/' + id, null, null, null, 'GET');
  //   if (result.error) {
  //     commit(mutationTypes.SYSTEM_ERROR, result.error);
  //     commit(mutationTypes.END_SYSTEM_JOB, jobName);
  //   } else {
  //     commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_ENTITY_MAP, { entityMap: result.data.entityMap });
  //     commit(mutationTypes.END_SYSTEM_JOB, jobName);
  //   }
  // },
  //
  // async editFionaEntityMap({ commit, state }, {id, entityMap}) {
  //   const jobName = 'save-integration-fiona';
  //   commit(mutationTypes.START_SYSTEM_JOB, jobName);
  //   const result = await backoffice.callAdminApi('integration/fiona/map/' + id, entityMap, null, null, 'PUT');
  //   if (result.error) {
  //     commit(mutationTypes.SYSTEM_ERROR, result.error);
  //     commit(mutationTypes.END_SYSTEM_JOB, jobName);
  //   } else {
  //     commit(mutationTypes.SAVED_INTEGRATION_FIONA_ENTITY_MAP, {entityMap});
  //     commit(mutationTypes.END_SYSTEM_JOB, jobName);
  //     router.push({ name: "system-integration-fiona-entity-map" });
  //   }
  // },
  //
  // async createFionaEntityMap({ commit, state }, {entityMap}) {
  //   const jobName = 'save-integration-fiona';
  //   commit(mutationTypes.START_SYSTEM_JOB, jobName);
  //   const result = await backoffice.callAdminApi('integration/fiona/map', entityMap);
  //   if (result.error) {
  //     commit(mutationTypes.SYSTEM_ERROR, result.error);
  //     commit(mutationTypes.END_SYSTEM_JOB, jobName);
  //   } else {
  //     // data.id = result.data.id;
  //     commit(mutationTypes.SAVED_INTEGRATION_FIONA_ENTITY_MAP, {entityMap});
  //     commit(mutationTypes.END_SYSTEM_JOB, jobName);
  //     // router.push({ name: "system-tag-detail", params: { id: data.id } });
  //     router.push({ name: "system-integration-fiona-entity-map" });
  //   }
  // },
  //
  // async removeFionaEntityMap({ commit, state }, {id}) {
  //   const jobName = 'get-integration-fiona';
  //   commit(mutationTypes.START_SYSTEM_JOB, jobName);
  //   const result = await backoffice.callAdminApi('integration/fiona/map/' + id, null, null, null, 'DELETE');
  //   if (result.error) {
  //     commit(mutationTypes.SYSTEM_ERROR, result.error);
  //     commit(mutationTypes.END_SYSTEM_JOB, jobName);
  //   } else {
  //     commit(mutationTypes.CLEAR_INTEGRATION_FIONA_ENTITY_MAP);
  //     commit(mutationTypes.END_SYSTEM_JOB, jobName);
  //     router.push({ name: "system-integration-fiona-entity-map" });
  //   }
  // },
  //
  // clearFionaEntityMap({ commit, state }) {
  //   commit(mutationTypes.CLEAR_INTEGRATION_FIONA_ENTITY_MAP);
  // },


  async getFionaAccreditationMaps({ commit, state }) {
    const jobName = 'get-integration-fiona';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    commit(mutationTypes.CLEAR_INTEGRATION_FIONA_ENTITY_MAPS);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/map/accreditation', null, null, null, 'GET');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_ENTITY_MAPS, { entityMaps: result.data.entityMaps });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getFionaAccreditationMap({ commit, state }, {id}) {
    const jobName = 'get-integration-fiona';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    commit(mutationTypes.CLEAR_INTEGRATION_FIONA_ENTITY_MAP);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/map/accreditation/' + id, null, null, null, 'GET');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_ENTITY_MAP, { entityMap: result.data.entityMap });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async createFionaAccreditationMap({ commit, state }, {entityMap}) {
    const jobName = 'save-integration-fiona';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/map/accreditation', entityMap);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.SAVED_INTEGRATION_FIONA_ENTITY_MAP, {entityMap});
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.push({ name: "system-integration-fiona-accreditation-map" });
    }
  },

  async editFionaAccreditationMap({ commit, state }, {id, entityMap}) {
    const jobName = 'save-integration-fiona';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/map/accreditation/' + id, entityMap, null, null, 'PUT');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.SAVED_INTEGRATION_FIONA_ENTITY_MAP, {entityMap});
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.push({ name: "system-integration-fiona-accreditation-map" });
    }
  },

  async removeFionaAccreditationMap({ commit, state }, {id}) {
    const jobName = 'get-integration-fiona';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/map/accreditation/' + id, null, null, null, 'DELETE');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.CLEAR_INTEGRATION_FIONA_ENTITY_MAP);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.push({ name: "system-integration-fiona-accreditation-map" });
    }
  },


  async getFionaVolunteerMaps({ commit, state }) {
    const jobName = 'get-integration-fiona';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    commit(mutationTypes.CLEAR_INTEGRATION_FIONA_ENTITY_MAPS);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/map/volunteer', null, null, null, 'GET');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_ENTITY_MAPS, { entityMaps: result.data.entityMaps });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getFionaVolunteerMap({ commit, state }, {id}) {
    const jobName = 'get-integration-fiona';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    commit(mutationTypes.CLEAR_INTEGRATION_FIONA_ENTITY_MAP);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/map/volunteer/' + id, null, null, null, 'GET');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_ENTITY_MAP, { entityMap: result.data.entityMap });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async createFionaVolunteerMap({ commit, state }, {entityMap}) {
    const jobName = 'save-integration-fiona';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/map/volunteer', entityMap);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.SAVED_INTEGRATION_FIONA_ENTITY_MAP, {entityMap});
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.push({ name: "system-integration-fiona-volunteer-map" });
    }
  },

  async editFionaVolunteerMap({ commit, state }, {id, entityMap}) {
    const jobName = 'save-integration-fiona';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/map/volunteer/' + id, entityMap, null, null, 'PUT');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.SAVED_INTEGRATION_FIONA_ENTITY_MAP, {entityMap});
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.push({ name: "system-integration-fiona-volunteer-map" });
    }
  },

  async removeFionaVolunteerMap({ commit, state }, {id}) {
    const jobName = 'get-integration-fiona';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/map/volunteer/' + id, null, null, null, 'DELETE');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.CLEAR_INTEGRATION_FIONA_ENTITY_MAP);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
      router.push({ name: "system-integration-fiona-volunteer-map" });
    }
  },

  clearFionaEntityMap({ commit, state }) {
    commit(mutationTypes.CLEAR_INTEGRATION_FIONA_ENTITY_MAP);
  },





  async getFionaSynchronizedAccreditations({ commit, state }, {max, from, searchTerm}) {
    const jobName = 'get-integration-fiona-accreditation';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);

    let query = '?source=FionaAccreditation';
    if(max) query += '&offset=' + max;
    if(from) query += '&first=' + from;
    if(searchTerm) query += '&search=' + searchTerm;
    const result = await backoffice.callAdminApi('order-sync/job' + query, null, null, null, 'GET');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_ACCREDITATIONS, { accreditations: result.data.syncJobs });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getFionaSynchronizedVolunteers({ commit, state }, {max, from, searchTerm}) {
    const jobName = 'get-integration-fiona-volunteer';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);

    let query = '?source=FionaVolunteer';
    if(max) query += '&offset=' + max;
    if(from) query += '&first=' + from;
    if(searchTerm) query += '&search=' + searchTerm;
    const result = await backoffice.callAdminApi('order-sync/job' + query, null, null, null, 'GET');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_VOLUNTEERS, { volunteers: result.data.syncJobs });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async queueFionaSyncJobs({ commit, state }, {jobs}) {
    const jobName = 'save-integration-fiona';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    // const result = await backoffice.callAdminApi('order-sync/job', {jobs: jobs}, null, null, 'POST');
    const result = await backoffice.callAdminApi('order-sync/job', jobs, null, null, 'POST');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getFionaProxyGuestbooks({ commit, state }) {
    const jobName = 'get-integration-fiona-guestbook';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/option/accreditation/guestbook', null, null, null, 'GET');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_PROXY_GUESTBOOKS, { guestbooks: result.data.guestbooks });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getFionaProxyAccreditations({ commit, state }, {guestbookId}) {
    const jobName = 'get-integration-fiona-accreditation';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/option/accreditation/guestbook/'+guestbookId, null, null, null, 'GET');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_PROXY_ACCREDITATIONS, { accreditations: result.data.accreditations });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getFionaProxyVolunteerEditions({ commit, state }) {
    const jobName = 'get-integration-fiona-volunteer';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/option/volunteer/edition', null, null, null, 'GET');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_PROXY_VOLUNTEER_EDITIONS, { volunteerEditions: result.data.volunteerEditions });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getFionaProxyVolunteers({ commit, state }, {volunteerEditionId}) {
    const jobName = 'get-integration-fiona-volunteer';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/option/volunteer/edition/'+volunteerEditionId, null, null, null, 'GET');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_PROXY_VOLUNTEERS, { volunteers: result.data.volunteers });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  // async getFionaProxyEditionTypes({ commit, state }) {
  //   const jobName = 'get-integration-fiona-edition-type';
  //   commit(mutationTypes.START_SYSTEM_JOB, jobName);
  //   const result = await backoffice.callAdminApi('integration/fiona/facade/editiontype', null, null, null, 'GET');
  //   if (result.error) {
  //     commit(mutationTypes.SYSTEM_ERROR, result.error);
  //     commit(mutationTypes.END_SYSTEM_JOB, jobName);
  //   } else {
  //     commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_PROXY_EDITION_TYPES, { editionTypes: result.data.editionTypes });
  //     commit(mutationTypes.END_SYSTEM_JOB, jobName);
  //   }
  // },

  // async getFionaProxyEditions({ commit, state }, {editionTypeId}) {
  //   const jobName = 'get-integration-fiona-edition';
  //   commit(mutationTypes.START_SYSTEM_JOB, jobName);
  //   const result = await backoffice.callAdminApi('integration/fiona/facade/editiontype/'+editionTypeId+'/edition', null, null, null, 'GET');
  //   if (result.error) {
  //     commit(mutationTypes.SYSTEM_ERROR, result.error);
  //     commit(mutationTypes.END_SYSTEM_JOB, jobName);
  //   } else {
  //     commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_PROXY_EDITIONS, { editions: result.data.editions });
  //     commit(mutationTypes.END_SYSTEM_JOB, jobName);
  //   }
  // },

  async getFionaProxyAccreditationBadgeTypes({ commit, state }, {guestBookId}) {
    const jobName = 'get-integration-fiona-badge-types';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/option/accreditation/guestbook/' + guestBookId + '/badge/type', null, null, null, 'GET');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_PROXY_ACCREDITATION_BADGE_TYPES, { badgeTypes: result.data.badgeTypes });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getFionaProxyAccreditationBadgeStatus({ commit, state }) {
    const jobName = 'get-integration-fiona-badge-status';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/option/accreditation/badge/status', null, null, null, 'GET');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_PROXY_ACCREDITATION_BADGE_STATUS, { badgeStatus: result.data.badgeStatus });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getFionaProxyVolunteerBadgeTypes({ commit, state }) {
    const jobName = 'get-integration-fiona-badge-types';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const result = await backoffice.callAdminApi('order-sync/gateway/fiona/option/volunteer/badge/type', null, null, null, 'GET');
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_FIONA_PROXY_VOLUNTEER_BADGE_TYPES, { badgeTypes: result.data.badgeTypes });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getProductDefinitions({ commit, state }) {
    const jobName = 'get-integration-product';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const query = 'query { productDefinitions{ id,name }}';
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_PRODUCT_DEFINITION, { productDefinitions: result.data.productDefinitions });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },

  async getProductPrices({ commit, state }, {productDefinitionId, salesChannelId}) {
    const jobName = 'get-integration-product-prices';
    commit(mutationTypes.START_SYSTEM_JOB, jobName);
    const query = `query { productPrices(productDefinitionId: "${productDefinitionId}", salesChannelId: "${salesChannelId}"){conditionId,price,currency{code,name,exponent,symbol},description,conditionPath} }`;
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.SYSTEM_ERROR, result.error);
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    } else {
      commit(mutationTypes.RECEIVED_INTEGRATION_PRICES, { prices: result.data.productPrices });
      commit(mutationTypes.END_SYSTEM_JOB, jobName);
    }
  },







  flushError({ commit, state }) {
    commit(mutationTypes.FLUSH_SYSTEM_ERROR);
  }
};
