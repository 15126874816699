export const CREDIT_ERROR = "CREDIT_ERROR";
export const FLUSH_CREDIT_ERROR = "FLUSH_CREDIT_ERROR";

export const START_CREDIT_JOB = "START_CREDIT_JOB";
export const END_CREDIT_JOB = "END_CREDIT_JOB";

export const RECEIVED_CREDIT_ACCOUNTS = "RECEIVED_CREDIT_ACCOUNTS";
export const RECEIVED_CREDIT_ACCOUNT = "RECEIVED_CREDIT_ACCOUNT";
export const SAVED_CREDIT_ACCOUNT = "SAVED_CREDIT_ACCOUNT";
export const CREATED_CREDIT_ACCOUNT = "CREATED_CREDIT_ACCOUNT";
export const CLEAR_CREDIT_ACCOUNT = "CLEAR_CREDIT_ACCOUNT";

export const RECEIVED_CREDIT_ACCOUNT_CURRENCIES = "RECEIVED_CREDIT_ACCOUNT_CURRENCIES";
export const RECEIVED_CREDIT_ACCOUNT_CURRENCY = "RECEIVED_CREDIT_ACCOUNT_CURRENCY";
export const SAVED_CREDIT_ACCOUNT_CURRENCY = "SAVED_CREDIT_ACCOUNT_CURRENCY";
export const CREATED_CREDIT_ACCOUNT_CURRENCY = "CREATED_CREDIT_ACCOUNT_CURRENCY";
export const CLEAR_CREDIT_ACCOUNT_CURRENCY = "CLEAR_CREDIT_ACCOUNT_CURRENCY";

