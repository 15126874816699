import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";


// initial state
const state = {
    error: [],
    jobs: [],
    account: {},
    batchOperation: {},
    batchOperations: [],


};

//
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
