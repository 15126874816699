import BackendService from "@/services/backend.service";
import * as mutationTypes from "../mutation-types";
import router from "@/router";
import GraphqlQueryBuilder from "@/services/GraphqlQueryBuilder.service";
import validationService from "@/services/validation.service";

const backoffice = new BackendService();

export default {
  /**
   * Get list of customers
   * @param commit
   * @param state
   * @param query
   * @returns {Promise<void>}
   */
  async getCustomers({ commit, state }, query) {
    // const result = {"total":2,"data":[{"id":"f3a76124-24af-4d57-a0f6-479d28b5e26a","fullName":"unknown","sortName":"unknown","firstName":"","lastName":"","birthDate":"","company":"","jobTitle":"","sex":"","email":"tt@xs4all.nl","phone":"","image":{},"addresses":[{"isDefaultBilling":true,"country":"nl","isDefaultHome":true,"locality":"Amsterdam","id":"558f9b8c-e7dd-47d3-8e65-670a73027280","isDefaultShipping":true,"line1":"Astreet 14"}],"active":true,"metadata":{"date":"2018-09-07T09:03:53.921Z","version":0,"clientKey":"default"}},{"id":"93d5e401-cb16-4d30-ae72-d72940424cb3","fullName":"Bertje Binnenband","sortName":"Binnenband, Bertje","firstName":"Bertje","lastName":"Binnenband","birthDate":"1971-04-20","company":"","jobTitle":"","sex":"male","email":"bertje@xs4all.nl","phone":"06123456","image":{},"addresses":[{"isDefaultBilling":false,"country":"nl","isDefaultHome":false,"locality":"Amsterdam","isDefaultShipping":false,"line1":"Astreet 14"},{"isDefaultBilling":true,"country":"nl","isDefaultHome":true,"locality":"Amsterdam","id":"fd92d4cb-990b-4129-9ecb-16c6d905b87e","isDefaultShipping":true,"line1":"Cstreet 11"}],"active":true,"metadata":{"date":"2018-09-07T09:03:53.921Z","version":4,"clientKey":"default"}}]};

    // const backoffice = new BackendService();
    // const result = await backoffice.sendQuery(query);
    // if (result.error) {
    //     commit(mutationTypes.CUSTOMER_QUERY_ERROR, { error: result.error });
    // } else {
    //     commit(mutationTypes.RECEIVED_CUSTOMERS, { customers: result.data });
    //     commit(mutationTypes.SET_CUSTOMER_LIST_ROWS, {total: result.total});
    // }

    commit(mutationTypes.START_CUSTOMER_JOB, "get-customer");
    // const backoffice = new BackendService();
    const result = await backoffice.sendQuery(query);
    if (result.errors) {
      commit(mutationTypes.CUSTOMER_ERROR, result.error);
      commit(mutationTypes.END_CUSTOMER_JOB, "get-customer");
    } else {
      commit(mutationTypes.RECEIVED_CUSTOMERS, {
        customers: result.data.customers
      });
      commit(mutationTypes.END_CUSTOMER_JOB, "get-customer");
      // commit(mutationTypes.SET_CUSTOMER_LIST_ROWS, {total: result.total});
    }
  },

  /**
   * Get single customer
   * @param commit
   * @param state
   * @param query
   * @returns {Promise<void>}
   */
  async getCustomer({ commit, state }, query) {
    // const result = {"data":{"id":"93d5e401-cb16-4d30-ae72-d72940424cb3","fullName":"Bertje Binnenband","sortName":"Binnenband, Bertje","firstName":"Bertje","lastName":"Binnenband","birthDate":"1971-04-20","company":"","jobTitle":"","sex":"male","email":"bertje@xs4all.nl","phone":"06123456","image":{},"addresses":[{"isDefaultBilling":false,"country":"nl","isDefaultHome":false,"locality":"Amsterdam","isDefaultShipping":false,"line1":"Astreet 14"},{"isDefaultBilling":true,"country":"nl","isDefaultHome":true,"locality":"Amsterdam","id":"fd92d4cb-990b-4129-9ecb-16c6d905b87e","isDefaultShipping":true,"line1":"Cstreet 11"}],"active":true,"metadata":{"date":"2018-09-07T09:03:53.921Z","version":4,"clientKey":"default"}}};

    // if (result.error) {
    //     commit(mutationTypes.CUSTOMER_QUERY_ERROR, { error: result.error });
    // } else {
    //     commit(mutationTypes.RECEIVED_CUSTOMER, { customer: result.data });
    // }

    commit(mutationTypes.START_CUSTOMER_JOB, "get-customer");
    commit(mutationTypes.CLEAR_CUSTOMER);
    // const backoffice = new BackendService();
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.CUSTOMER_ERROR, result.error);
      commit(mutationTypes.END_CUSTOMER_JOB, "get-customer");
    } else {
      commit(mutationTypes.RECEIVED_CUSTOMER, {
        customer: result.data.customer
      });
      commit(mutationTypes.END_CUSTOMER_JOB, "get-customer");
    }
  },

  // async getCustomerOrders({ commit, state }, {customerId}) {
  async getCustomerOrders({ commit, state }, {customerId, rows}) {
  // async getCustomerOrders({ commit, state }, {rows, sort, columns, filters}) {
    const jobType = 'get-customer';
    // const query = `query { orders(filters: {customerId: {equals: "${customerId}"}}){id,paymentStatus,requiredPayments{currencyCode,currency{name,code,exponent,symbol},amount},createDate,customer{id,fullName},salesChannel{id,name},status} }`;
    const query = `query { orders(filters: {customerId: {equals: "${customerId}"}}, first: ${rows.from}, offset: ${rows.max}, orderBy: createDate_DESC){id,paymentStatus,requiredPayments{currencyCode,currency{name,code,exponent,symbol},amount},createDate,customer{id,fullName},salesChannel{id,name},status,number} }`;

    // // build query
    // const builder = new GraphqlQueryBuilder();
    // builder.setType("orders");
    // builder.setRows(rows);
    // builder.setSort(sort);
    // builder.setColumns(columns, ["id", "name", "requiredPayments{currencyCode,currency{name,code,exponent,symbol},amount}", "customer{id,fullName}", "salesChannel{id,name}"]);
    // if( validationService.validateDatatableFilters(filters) ) {
    //     builder.setFilters(filters);
    // }
    // const query = builder.build();

    commit(mutationTypes.START_CUSTOMER_JOB, jobType);
    commit(mutationTypes.CLEAR_CUSTOMER);
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.CUSTOMER_ERROR, result.error);
      commit(mutationTypes.END_CUSTOMER_JOB, jobType);
    } else {
      commit(mutationTypes.RECEIVED_CUSTOMER_ORDERS, {orders: result.data.orders});
      commit(mutationTypes.END_CUSTOMER_JOB, jobType);
    }
  },

  async getCustomerAccess({ commit, state }, {customerId, rows}) {
    const jobType = 'get-customer';
    // const query = `query {accesses(filters: {customerId:{equals:"${customerId}"}}, first: ${rows.from}, offset: ${rows.max}, orderBy: version_DESC){id, status, location, orderId, orderLineItem{id,name,price,currencyCode}, event{id, name, description, start, location}}}`;
    const query = `query {accesses(filters: {customerId:{equals:"${customerId}"}, status: {contains: ["granted"]}}, first: ${rows.from}, offset: ${rows.max}, orderBy: version_DESC){id, status, location, orderId, orderLineItem{id,name,price,currencyCode}, event{id, name, description, start, location}}}`;
    commit(mutationTypes.START_CUSTOMER_JOB, jobType);
    commit(mutationTypes.CLEAR_CUSTOMER);
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.CUSTOMER_ERROR, result.error);
      commit(mutationTypes.END_CUSTOMER_JOB, jobType);
    } else {
      commit(mutationTypes.RECEIVED_CUSTOMER_ACCESS, {access: result.data.accesses});
      commit(mutationTypes.END_CUSTOMER_JOB, jobType);
    }
  },

  async getCustomerProducts({ commit, state }, {customerId, rows}) {
    const jobType = 'get-customer';
    const query = `query { products(filters: {customerId: {equals: "${customerId}"},status:{contains:["granted"]}}}){ id,productDefinition{id,name,apiConfig{... on CreditAccountApiConfig{source,currencyCode,amount}}},orderId,orderLineItemId,productVariantId,status,reference }}`;
    // const query = `query { products(filters: {customerId: {equals: "${customerId}"}}){ id,productDefinition{id,name}}}`;
    commit(mutationTypes.START_CUSTOMER_JOB, jobType);
    commit(mutationTypes.CLEAR_CUSTOMER);
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.CUSTOMER_ERROR, result.error);
      commit(mutationTypes.END_CUSTOMER_JOB, jobType);
    } else {
      commit(mutationTypes.RECEIVED_CUSTOMER_PRODUCTS, {products: result.data.products});
      commit(mutationTypes.END_CUSTOMER_JOB, jobType);
    }
  },

  async getCustomerMemberships({ commit, state }, {customerId, rows}) {
    const jobType = 'get-customer';
    // const query = `query { memberships(filters: {customerId: {equals: "${customerId}"}}){ id,status,orderId,orderLineItemId,grantDate,start,end,membershipDefinition{id,name,description} }}`;
    const query = `query { memberships(filters: {customerId: {equals: "${customerId}"}, status: {contains: ["granted"]}}, first: ${rows.from}, offset: ${rows.max}, orderBy: [end_DESC]){ id,status,orderId,orderLineItemId,grantDate,start,end,membershipDefinition{id,name,description} }}`;
    commit(mutationTypes.START_CUSTOMER_JOB, jobType);
    commit(mutationTypes.CLEAR_CUSTOMER);
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.CUSTOMER_ERROR, result.error);
      commit(mutationTypes.END_CUSTOMER_JOB, jobType);
    } else {
      commit(mutationTypes.RECEIVED_CUSTOMER_MEMBERSHIPS, {memberships: result.data.memberships});
      commit(mutationTypes.END_CUSTOMER_JOB, jobType);
    }
  },

  /**
   * Send customer command
   * @param commit
   * @param state
   * @param mutations
   * @returns {Promise<void>}
   */
  async saveCustomer({ commit, state }, mutations) {
    // const result = {status: 'success', data: []};

    // if (result.error) {
    //     commit(mutationTypes.CUSTOMER_COMMAND_ERROR, { error: result.error });
    // } else {
    //     commit(mutationTypes.SAVED_CUSTOMER, command);
    // }

    commit(mutationTypes.START_CUSTOMER_JOB, "save-customer");
    // const backoffice = new BackendService();
    const result = await backoffice.sendCommandBatch(mutations);
    if (result.error) {
      commit(mutationTypes.CUSTOMER_ERROR, result.error);
      commit(mutationTypes.END_CUSTOMER_JOB, "save-customer");
    } else {
      commit(
        mutationTypes.SAVED_CUSTOMER,
        mutations.reduce(
          (state, command) => Object.assign(state, command.data),
          {}
        )
      );
      commit(mutationTypes.END_CUSTOMER_JOB, "save-customer");
    }
  },

  async createNewCustomer({ commit, state }, data) {
    commit(mutationTypes.START_CUSTOMER_JOB, "save-customer");
    // const backoffice = new BackendService();
    let result = await backoffice.sendCommand("CreateCustomer", data);
    if (result.error) {
      commit(mutationTypes.CUSTOMER_ERROR, result.error);
      commit(mutationTypes.END_CUSTOMER_JOB, "save-customer");
    } else {
      data.customerId = result.data.customerId;
      commit(mutationTypes.SAVED_CUSTOMER, data);
      commit(mutationTypes.END_CUSTOMER_JOB, "save-customer");
      router.push({
        name: "customer-detail",
        params: { id: data.customerId }
      });
    }
  },

  async removeCustomer({ commit, state }, data) {
    commit(mutationTypes.START_CUSTOMER_JOB, "save-customer");
    // const backoffice = new BackendService();
    let result = await backoffice.sendCommand("RemoveCustomer", data);
    if (result.error) {
      commit(mutationTypes.CUSTOMER_ERROR, result.error);
      commit(mutationTypes.END_CUSTOMER_JOB, "save-customer");
    } else {
      commit(mutationTypes.CLEAR_CUSTOMER);
      commit(mutationTypes.END_CUSTOMER_JOB, "save-customer");
      router.push({
        name: "customer-root"
      });
    }
  },

  clearCustomer({ commit, state }) {
    commit(mutationTypes.CLEAR_CUSTOMER);
  },

  setCustomerListSelectedColumns({ commit, state }, selectedColumns) {
    commit(mutationTypes.SELECT_CUSTOMER_LIST_COLUMNS, selectedColumns);
  },

  setCustomerListRows({ commit, state }, rows) {
    commit(mutationTypes.SET_CUSTOMER_LIST_ROWS, rows);
  },

  setCustomerListSort({ commit, state }, sort) {
    commit(mutationTypes.SET_CUSTOMER_LIST_SORT, sort);
  },

  setCustomerListSelectedFilters({ commit, state }, selectedFilters) {
    commit(mutationTypes.FILTER_CUSTOMER_LIST, selectedFilters);
  },

  setCustomerListSearchTerm({ commit, state }, searchTerm) {
    commit(mutationTypes.SET_CUSTOMER_LIST_SEARCH_TERM, searchTerm);
  }
};
