import * as mutationTypes from "../mutation-types";
import BackendService from "@/services/backend.service";
import router from "@/router";

const backoffice = new BackendService();

export default {

    async getAccount({ commit, state }) {
        const query = 'query { me{id,username,scopes,disabled} }';
        const jobName = 'get-account';
        commit(mutationTypes.CLEAR_ACCOUNT);
        commit(mutationTypes.START_ACCOUNT_JOB, jobName);
        const result = await backoffice.sendQuery(query);
        if (result.error) {
            commit(mutationTypes.ACCOUNT_ERROR, result.error);
            commit(mutationTypes.END_ACCOUNT_JOB, jobName);
        } else {
            commit(mutationTypes.RECEIVED_ACCOUNT, { account: result.data.me });
            commit(mutationTypes.END_ACCOUNT_JOB, jobName);
        }
    },

    async getBatchOperation({ commit, state }, id) {
        const query = `query { batchOperation(id: "${id}"){id,status,total,success,error,createdOn,operations{ ... on CommandOperation{id,batchId,command,request{body,headers{name,value}},response{status,body{status,data},headers{name,value}},reference,createdOn,startOn,endOn} }} }`;
        const jobName = 'get-batch-operation';
        commit(mutationTypes.CLEAR_BATCH_OPERATION);
        commit(mutationTypes.START_ACCOUNT_JOB, jobName);
        const result = await backoffice.sendQuery(query);
        if (result.error) {
            commit(mutationTypes.ACCOUNT_ERROR, result.error);
            commit(mutationTypes.END_ACCOUNT_JOB, jobName);
        } else {
            commit(mutationTypes.RECEIVED_BATCH_OPERATION, { batchOperation: result.data.batchOperation });
            commit(mutationTypes.END_ACCOUNT_JOB, jobName);
        }
    },

    async getBatchOperations({ commit, state }, {userId, first, offset}) {
        const f = first ? first : 0;
        const o = offset ? offset : 20;
        const query = `query { batchOperations(filters: {userId: {equals: "${userId}"}}, first: ${f}, offset: ${o}, orderBy: [createdOn_DESC]){id,status,total,success,error,createdOn} }`;
        const jobName = 'get-batch-operation';
        commit(mutationTypes.CLEAR_BATCH_OPERATIONS);
        commit(mutationTypes.START_ACCOUNT_JOB, jobName);
        const result = await backoffice.sendQuery(query);
        if (result.error) {
            commit(mutationTypes.ACCOUNT_ERROR, result.error);
            commit(mutationTypes.END_ACCOUNT_JOB, jobName);
        } else {
            commit(mutationTypes.RECEIVED_BATCH_OPERATIONS, { batchOperations: result.data.batchOperations });
            commit(mutationTypes.END_ACCOUNT_JOB, jobName);
        }
    },

    async resetPassword({ commit, state }, {password}) {
        const jobName = 'reset-password';
        commit(mutationTypes.START_ACCOUNT_JOB, jobName);
        let result = await backoffice.sendCommand("ResetUserPassword", {password: password});
        if (result.error) {
            commit(mutationTypes.ACCOUNT_ERROR, result.error);
            commit(mutationTypes.END_ACCOUNT_JOB, jobName);
        } else {
            commit(mutationTypes.END_ACCOUNT_JOB, jobName);
        }
    },

};
