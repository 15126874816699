export const CORE_ERROR = "CORE_ERROR";
export const FLUSH_CORE_ERROR = "FLUSH_CORE_ERROR";
export const START_CORE_JOB = "START_CORE_JOB";
export const END_CORE_JOB = "END_CORE_JOB";
export const REGISTER_CORE_JOB_TOAST = "REGISTER_CORE_JOB_TOAST";
export const FINISH_BATCH_OPERATION = "FINISH_BATCH_OPERATION";

export const RECEIVED_CURRENCY_OPTIONS = "RECEIVED_CURRENCY_OPTIONS";
export const RECEIVED_TAG_OPTIONS = "RECEIVED_TAG_OPTIONS";

export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_OUT = "LOGGED_OUT";
export const RECEIVED_SEARCH_RESULT = "RECEIVED_SEARCH_RESULT";

export const RECEIVED_NAME_SUGGESTIONS = "RECEIVED_NAME_SUGGESTIONS";
export const CLEAR_NAME_SUGGESTIONS = "CLEAR_NAME_SUGGESTIONS";

export const RECEIVED_USER = "RECEIVED_USER";
export const CLEAR_USER = "CLEAR_USER";
