import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
// import moment from "moment";
// import formatService from "@/services/format.service";

// initial state
const state = {
    jobs: [],
    error: [],
    productDefinitions: [],
    productDefinition: {},
    products: [],
    creditAccountCurrencies: [],
    // membershipDefinitions: [],
    packageDefinitions: [],
};

//
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
