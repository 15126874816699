import * as mutationTypes from "../mutation-types";
import BackendService from "@/services/backend.service";
import GraphqlQueryBuilder from "@/services/GraphqlQueryBuilder.service";
import validationService from "@/services/validation.service";
import router from "@/router";

const backoffice = new BackendService();

export default {

    async getEventTemplates({ commit, state }, {rows, sort, columns, filters, templateType, alwaysIncludeFields}) {
        // build query
        let type = 'eventTemplates';
        if(templateType === 'eventCapacityTemplate') {
            type = 'eventCapacityTemplates';
        }
        if(templateType === 'eventAccessTemplate') {
            type = 'eventAccessTemplates';
        }

        //
        const builder = new GraphqlQueryBuilder();
        builder.setType(type);
        builder.setRows(rows);
        builder.setSort(sort);
        // builder.setColumns(columns, ["id", "capacityString", "accessDefinitions{id}"]);
        builder.setColumns(columns, alwaysIncludeFields || ["id"]);
        if( validationService.validateDatatableFilters(filters) ) {
            builder.setFilters(filters);
        }
        const query = builder.build();

        //
        const jobName = 'get-event-template';
        commit(mutationTypes.CLEAR_EVENT_TEMPLATES, jobName);
        commit(mutationTypes.START_EVENT_TEMPLATE_JOB, jobName);
        const result = await backoffice.sendQuery(query);
        if (result.errors) {
            commit(mutationTypes.EVENT_TEMPLATE_ERROR, result.error);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        } else {
            // commit(mutationTypes.RECEIVED_EVENT_TEMPLATES, { eventTemplates: result.data.eventTemplates });
            commit(mutationTypes.RECEIVED_EVENT_TEMPLATES, { eventTemplates: result.data[type] });
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        }
    },

    async getEventTemplate({ commit, state }, {id, templateType}) {
        //
        let query = 'query { eventTemplate(id: "' + id + '"){id,version,name,description,capacityString,accessDefinitions{id,templateId,name,description,start,end,conditionsString,conditionsStringResolvedEntities,capacityLocations,capacityLocationAllocation,useLimit}} }';
        if(templateType === 'eventCapacityTemplate') {
            query = 'query { eventCapacityTemplate(id: "' + id + '"){id,version,name,description,capacityString} }';
        }
        if(templateType === 'eventAccessTemplate') {
            query = 'query { eventAccessTemplate(id: "' + id + '"){id,version,name,description,accessDefinitions{id,templateId,name,description,start,end,conditionsString,conditionsStringResolvedEntities,capacityLocations,capacityLocationAllocation,useLimit}} }';
        }

        //
        const jobName = 'get-event-template';
        commit(mutationTypes.CLEAR_EVENT_TEMPLATE);
        commit(mutationTypes.CLEAR_EVENT_TEMPLATE_APPLIED_ON_EVENTS);
        commit(mutationTypes.START_EVENT_TEMPLATE_JOB, jobName);
        const result = await backoffice.sendQuery(query);
        if (result.error) {
            commit(mutationTypes.EVENT_TEMPLATE_ERROR, result.error);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        } else {
            // decode json string fields
            // const eventTemplate = result.data.eventTemplate;
            const eventTemplate = result.data[templateType];
            if(eventTemplate.capacityString) eventTemplate.capacity = eventTemplate.capacityString ? JSON.parse(eventTemplate.capacityString) : null;
            if(eventTemplate.accessDefinitions) eventTemplate.accessDefinitions = eventTemplate.accessDefinitions.map(ad => {
                ad.accessConditions = ad.conditionsString ? JSON.parse(ad.conditionsString) : null;
                ad.accessConditionsResolvedEntities = ad.conditionsStringResolvedEntities ? JSON.parse(ad.conditionsStringResolvedEntities) : null;
                return ad;
            });

            commit(mutationTypes.RECEIVED_EVENT_TEMPLATE, { eventTemplate });
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        }
    },

    async getEventTemplateAppliedOnEvents({ commit, state }, {id, rows, filters, templateType}) {
        const from = rows && rows.from ? rows.from : 0;
        const max = rows && rows.max ? rows.max : 20;

        let query = `query { events(filters: {eventTemplateId: {equals: "${id}"}}, first: ${from}, offset: ${max}){id,eventManagerId,name,location,start,end,status,eventTemplateApplyStatus,eventTemplateVersion,appliedEventTemplateVersion} }`;
        // let templateIdField = 'eventTemplateId';
        if(templateType === 'eventCapacityTemplate') {
            // templateIdField = 'eventCapacityTemplateId';
            query = `query { events(filters: {eventCapacityTemplateId: {equals: "${id}"}}, first: ${from}, offset: ${max}){id,eventManagerId,name,location,start,end,status,eventCapacityTemplateApplyStatus,eventCapacityTemplateVersion,appliedEventCapacityTemplateVersion} }`;
        }
        if(templateType === 'eventAccessTemplate') {
            // templateIdField = 'eventAccessTemplateId';
            query = `query { events(filters: {eventAccessTemplateId: {equals: "${id}"}}, first: ${from}, offset: ${max}){id,eventManagerId,name,location,start,end,status,eventAccessTemplateApplyStatus,eventAccessTemplateVersion,appliedEventAccessTemplateVersion} }`;
        }


        // const query = `query { events(filters: {${templateIdField}: {equals: "${id}"}}, first: ${from}, offset: ${max}){id,eventManagerId,name,location,start,end,status,eventTemplateApplyStatus,eventTemplateVersion,appliedEventTemplateVersion} }`;
        const jobName = 'get-event-template-applied-events';
        commit(mutationTypes.CLEAR_EVENT_TEMPLATE_APPLIED_ON_EVENTS);
        commit(mutationTypes.START_EVENT_TEMPLATE_JOB, jobName);
        const result = await backoffice.sendQuery(query);
        if (result.errors) {
            commit(mutationTypes.EVENT_TEMPLATE_ERROR, result.error);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        } else {
            commit(mutationTypes.RECEIVED_EVENT_TEMPLATE_APPLIED_ON_EVENTS, { events: result.data.events });
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        }
    },

    async getEventCapacityTemplatesOptions({ commit, state }) {
        const query = `query { eventCapacityTemplates(first:0, offset: 250){id,name,capacityString} }`;
        const jobName = 'get-event-capacity-template';
        // commit(mutationTypes.CLEAR_EVENT_TEMPLATES, jobName);
        commit(mutationTypes.START_EVENT_TEMPLATE_JOB, jobName);
        const result = await backoffice.sendQuery(query);
        if (result.errors) {
            commit(mutationTypes.EVENT_TEMPLATE_ERROR, result.error);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        } else {
            commit(mutationTypes.RECEIVED_EVENT_CAPACITY_TEMPLATES_OPTIONS, { templates: result.data.eventCapacityTemplates });
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        }
    },

    async saveEventTemplateMutations({ commit, dispatch, state }, {commands, updateAppliedEvents, eventTemplateId, templateType}) {
        const jobName = 'save-event-template';
        commit(mutationTypes.START_EVENT_TEMPLATE_JOB, jobName);
        const result = await backoffice.sendCommandBatch(commands);
        if (result.error) {
            commit(mutationTypes.EVENT_TEMPLATE_ERROR, result.error);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        } else {
            commit(
                mutationTypes.SAVED_EVENT_TEMPLATE,
                commands.reduce(
                    (state, command) => Object.assign(state, command.data),
                    {}
                )
            );
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);

            // update events where template is applied to
            if(updateAppliedEvents && eventTemplateId) {
                dispatch('updateAllEventWithAppliedTemplate', {eventTemplateId, templateType});
            }

            // get updated template data
            dispatch('getEventTemplate', {id: eventTemplateId, templateType});
        }
    },

    async createNewEventTemplate({ commit, state }, {data, templateType}) {
        let command = 'CreateEventTemplate';
        let routeName = 'event-template-event-detail';
        let key = 'eventTemplateId';
        if(templateType === 'eventCapacityTemplate') {
            command = 'CreateEventCapacityTemplate';
            routeName = 'event-template-capacity-detail';
            key = 'eventCapacityTemplateId';
        }
        if(templateType === 'eventAccessTemplate') {
            command = 'CreateEventAccessTemplate';
            routeName = 'event-template-access-detail';
            key = 'eventAccessTemplateId';
        }

        const jobName = 'save-event-template';
        commit(mutationTypes.START_EVENT_TEMPLATE_JOB, jobName);
        commit(mutationTypes.CLEAR_EVENT_TEMPLATE);
        let result = await backoffice.sendCommand(command, data);
        if (result.error) {
            commit(mutationTypes.EVENT_TEMPLATE_ERROR, result.error);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        } else {
            // data.id = result.data.eventTemplateId;
            data.id = result.data[key];
            commit(mutationTypes.SAVED_EVENT_TEMPLATE, data);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
            // router.push({ name: "event-template-detail", params: { id: data.id } });
            router.push({ name: routeName, params: { id: data.id } });
            dispatch('getEventTemplate', {id: data.id, templateType});
        }
    },

    async saveEventTemplateAccessDefinitionMutations({ commit, dispatch, state }, {commands, updateAppliedEvents, eventTemplateId, templateType}) {
        const jobName = 'save-event-template';
        commit(mutationTypes.START_EVENT_TEMPLATE_JOB, jobName);
        const result = await backoffice.sendCommandBatch(commands);
        if (result.error) {
            commit(mutationTypes.EVENT_TEMPLATE_ERROR, result.error);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        } else {
            commit(
                mutationTypes.UPDATE_EVENT_TEMPLATE_ACCESS_DEFINITION,
                commands.reduce(
                    (state, command) => Object.assign(state, command.data),
                    {}
                )
            );
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);

            // update events where template is applied to
            if(updateAppliedEvents && eventTemplateId) {
                dispatch('updateAllEventWithAppliedTemplate', {eventTemplateId, templateType});
            }

            // get updated template data
            // dispatch('getEventTemplate', {id: eventTemplateId, templateType});

            // go to access definition list
            let routeName = 'event-template-event-access-definition-list';
            if(templateType === 'eventAccessTemplate') routeName = 'event-template-access-access-definition-list';
            router.push({ name: routeName, params: { id: eventTemplateId } });
        }
    },

    async duplicateEventTemplate({ commit, state, dispatch }, {data, templateType}) {
        let command = 'DuplicateEventTemplate';
        let routeName = 'event-template-event-detail';
        let key = 'eventTemplateId';
        if(templateType === 'eventCapacityTemplate') {
            command = 'DuplicateEventCapacityTemplate';
            routeName = 'event-template-capacity-detail';
            key = 'eventCapacityTemplateId';
        }
        if(templateType === 'eventAccessTemplate') {
            command = 'DuplicateEventAccessTemplate';
            routeName = 'event-template-access-detail';
            key = 'eventAccessTemplateId';
        }

        const jobName = 'save-event-template';
        commit(mutationTypes.START_EVENT_TEMPLATE_JOB, jobName);
        commit(mutationTypes.CLEAR_EVENT_TEMPLATE);
        let result = await backoffice.sendCommand(command, data);
        if (result.error) {
            commit(mutationTypes.EVENT_TEMPLATE_ERROR, result.error);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        } else {
            // data.id = result.data.eventTemplateId;
            data.id = result.data[key];
            commit(mutationTypes.SAVED_EVENT_TEMPLATE, data);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
            router.push({ name: routeName, params: { id: data.id } });
            dispatch('getEventTemplate', {id: data.id, templateType});
        }
    },

    async createNewEventTemplateCapacity({ commit, dispatch, state }, {data, updateAppliedEvents, eventTemplateId, templateType}) {
        let command = 'AddEventTemplateCapacity';
        let routeName = 'event-template-event-capacity-detail';
        if(templateType === 'eventCapacityTemplate') {
            command = 'AddEventCapacityTemplateCapacity';
            routeName = 'event-template-capacity-capacity-detail';
        }

        const jobName = 'save-event-template';
        commit(mutationTypes.START_EVENT_TEMPLATE_JOB, jobName);
        let result = await backoffice.sendCommand(command, data);
        if (result.error) {
            commit(mutationTypes.EVENT_TEMPLATE_ERROR, result.error);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        } else {
            // commit(mutationTypes.ADD_EVENT_TEMPLATE_ACCESS_DEFINITION, data);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);

            // update events where template is applied to
            if(updateAppliedEvents && eventTemplateId) {
                dispatch('updateAllEventWithAppliedTemplate', {eventTemplateId, templateType});
            }

            // get updated template data
            dispatch('getEventTemplate', {id: eventTemplateId, templateType});

            // refresh
            // router.go();
        }
    },

    async createNewEventTemplateAccessDefinition({ commit, dispatch, state }, {data, updateAppliedEvents, eventTemplateId, templateType}) {
        let command = 'AddEventTemplateAccessDefinition';
        // let routeName = 'event-template-event-access-definition-detail';
        let routeName = 'event-template-event-access-definition-list';
        if(templateType === 'eventAccessTemplate') {
            command = 'AddEventAccessTemplateAccessDefinition';
            // routeName = 'event-template-access-access-definition-detail';
            routeName = 'event-template-access-access-definition-list';
        }

        const jobName = 'save-event-template';
        commit(mutationTypes.START_EVENT_TEMPLATE_JOB, jobName);
        let result = await backoffice.sendCommand(command, data);
        if (result.error) {
            commit(mutationTypes.EVENT_TEMPLATE_ERROR, result.error);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        } else {
            data.accessDefinitionId = result.data.accessDefinitionId;
            commit(mutationTypes.ADD_EVENT_TEMPLATE_ACCESS_DEFINITION, data);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);

            // update events where template is applied to
            if(updateAppliedEvents && eventTemplateId) {
                dispatch('updateAllEventWithAppliedTemplate', {eventTemplateId, templateType});
            }

            // get updated template data
            dispatch('getEventTemplate', {id: eventTemplateId, templateType});

            // navigate to newly created access definition
            // router.push({
            //     // name: "event-template-access-definition-detail",
            //     name: routeName,
            //     params: {
            //         id: data.aggregateId,
            //         accessDefinitionId: data.accessDefinitionId
            //     }
            // });
            router.push({
                // name: "event-template-access-definition-detail",
                name: routeName,
                params: {
                    id: data.aggregateId,
                    accessDefinitionId: data.accessDefinitionId
                }
            });
        }
    },

    async deleteEventTemplateAccessDefinition({ commit, dispatch, state }, {eventTemplateId, accessDefinitionId, updateAppliedEvents, templateType}) {
        let command = 'RemoveEventTemplateAccessDefinition';
        let routeName = 'event-template-event-access-definition-list';
        if(templateType === 'eventAccessTemplate') {
            command = 'RemoveEventAccessTemplateAccessDefinition';
            routeName = 'event-template-access-access-definition-list';
        }

        const jobName = 'save-event-template';
        commit(mutationTypes.START_EVENT_TEMPLATE_JOB, jobName);
        let result = await backoffice.sendCommand(command, {aggregateId: eventTemplateId, accessDefinitionId: accessDefinitionId});
        if (result.error) {
            commit(mutationTypes.EVENT_TEMPLATE_ERROR, result.error);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        } else {
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);

            // update events where template is applied to
            if(updateAppliedEvents && eventTemplateId) {
                dispatch('updateAllEventWithAppliedTemplate', {eventTemplateId, templateType});
            }

            // navigate to access definition list
            router.push({
                // name: "event-template-access-definition-list",
                name: routeName,
                params: {
                    id: eventTemplateId,
                }
            });
        }
    },


    async duplicateEventTemplateAccessDefinition({ commit, dispatch, state }, {eventTemplateId, accessDefinitionId, templateType}) {
        let command = 'DuplicateEventTemplateAccessDefinition';
        let routeName = 'event-template-event-access-definition-list';
        if(templateType === 'eventAccessTemplate') {
            command = 'DuplicateEventAccessTemplateAccessDefinition';
            routeName = 'event-template-access-access-definition-list';
        }

        const jobName = 'save-event-template';
        commit(mutationTypes.START_EVENT_TEMPLATE_JOB, jobName);
        let result = await backoffice.sendCommand(command, {aggregateId: eventTemplateId, accessDefinitionId: accessDefinitionId});
        if (result.error) {
            commit(mutationTypes.EVENT_TEMPLATE_ERROR, result.error);
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        } else {
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);

            // navigate to access definition list
            router.push({
                // name: "event-template-access-definition-list",
                name: routeName,
                params: {
                    id: eventTemplateId,
                }
            });
        }
    },

    clearEventTemplate({ commit, state }) {
        commit(mutationTypes.CLEAR_EVENT_TEMPLATE);
    },

    clearEventTemplates({ commit, state }) {
        commit(mutationTypes.CLEAR_EVENT_TEMPLATES);
    },

    async startBatchOperation({ commit, dispatch }, operations) {
        if(operations && operations.length > 0) {
            const jobName = 'start-batch-operation';
            commit(mutationTypes.START_EVENT_TEMPLATE_JOB, jobName);
            await dispatch('core/startBatchOperation', operations, {root:true});
            commit(mutationTypes.END_EVENT_TEMPLATE_JOB, jobName);
        }
    },


    async applyEventTemplateToEvents({ commit, state, dispatch }, {eventTemplateId, events, templateType}) {
        let command = 'ApplyEventTemplate';
        if(templateType === 'eventCapacityTemplate') {
            command = 'ApplyEventCapacityTemplate';
        }
        if(templateType === 'eventAccessTemplate') {
            command = 'ApplyEventAccessTemplate';
        }

        const operations = events.map(event => {
            return {
                command: command,
                body: {
                    aggregateId: event.eventManagerId,
                    eventId: event.id,
                    templateId: eventTemplateId,
                }
            }
        });
        dispatch('startBatchOperation', operations);
    },

    /**
     * Update all event where provided template is applied to
     * Re apply the template to these events
     * @param state
     * @param dispatch
     * @param eventTemplateId
     * @param templateType
     * @returns {Promise<void>}
     */
    async updateAllEventWithAppliedTemplate({ state, dispatch }, {eventTemplateId, templateType}) {
        await dispatch('getEventTemplateAppliedOnEvents', {id: eventTemplateId, templateType});
        dispatch('applyEventTemplateToEvents', {eventTemplateId, events: state.appliedOnEvents, templateType});
    },




    // setEventTemplateListSelectedColumns({ commit, state }, selectedColumns) {
    //     commit(mutationTypes.SELECT_EVENT_TEMPLATE_LIST_COLUMNS, selectedColumns);
    // },
    //
    // setEventTemplateListRows({ commit, state }, rows) {
    //     commit(mutationTypes.SET_EVENT_TEMPLATE_LIST_ROWS, rows);
    // },
    //
    // setEventTemplateListSort({ commit, state }, sort) {
    //     commit(mutationTypes.SET_EVENT_TEMPLATE_LIST_SORT, sort);
    // },
    //
    // setEventTemplateListSelectedFilters({ commit, state }, selectedFilters) {
    //     commit(mutationTypes.FILTER_EVENT_TEMPLATE_LIST, selectedFilters);
    // },



};
