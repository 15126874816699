// import EventList from "@/modules/event/views/EventListView.vue";
// import EventDetailView from "@/modules/event/views/EventDetailView.vue";

export default [
  {
    path: "/event",
    name: "event-root",
    // title: "Events",
    // component: EventList
    component: () =>
      import(/* webpackChunkName: "event-list" */ "@/modules/event/views/EventListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
  },

  {
    path: "/event/new",
    name: "event-new",
    component: () =>
      import(/* webpackChunkName: "event-list" */ "@/modules/event/views/EventNewView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
  },

  {
    path: "/event/:id/scanner",
    name: "event-scanner-config",
    component: () =>
        import(/* webpackChunkName: "event-list" */ "@/modules/event/views/ScannerConfig.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
  },

  {
    path: "/event/:id",
    name: "event-detail-root",
    // component: EventDetailView,
    component: () =>
      import(/* webpackChunkName: "event-detail" */ "@/modules/event/views/EventDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    // title: "Events Detail",
    // redirect: { name: "event-detail" },
    children: [
      {
        path: "detail",
        name: "event-detail",
        components: {
          detail: () =>
            import(/* webpackChunkName: "event-detail" */ "@/modules/event/components/EventDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "capacity",
        name: "capacity-detail",
        components: {
          detail: () =>
            import(/* webpackChunkName: "event-detail" */ "@/modules/event/components/EventCapacityDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "access",
        name: "access-definition-list",
        components: {
          detail: () =>
            import(/* webpackChunkName: "event-detail" */ "@/modules/event/components/EventAccessList.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "access/:accessDefinitionId",
        name: "access-definition-detail",
        components: {
          detail: () =>
            import(/* webpackChunkName: "event-detail" */ "@/modules/event/components/EventAccessDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "attendee",
        name: "attendee-list",
        components: {
          detail: () =>
            import(/* webpackChunkName: "event-detail" */ "@/modules/event/components/EventAttendees.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      }
    ]
  },

];
