import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import moment from "moment";
import formatService from "@/services/format.service";
import optionService from "@/services/option.service";

// initial state
const state = {
  //
  jobs: [],

  //
  error: [],

  //
  orders: [],

  //
  order: {},

  //
  orderEmails: [],

  // order list view settings
  orderListView: {
    columns: {
      options: [
        // all available columns
        {
            key: 'id',
            label: 'common.id',
            // link: {routeName: 'order-detail', params: [{paramKey: 'id', valueKey: 'id'}]}, // route params contains a params map for mapping row values to params properties
        },
        {
          key: "createDate",
          label: "order.createDate",
          link: {
            routeName: "order-detail",
            params: [{ paramKey: "id", valueKey: "id" }]
          }, // route params contains a params map for mapping row values to params properties
          format: function(value) {
            return moment(value).format("DD MMM YYYY @ HH:mm");
          },
          filter: {
            type: "date"
          }
        },
        {
          key: "status",
          label: "order.status",
          format: function(value) {
            return `<span class="status status--${value}">${value}</span>`;
          },
            filter: {
              type: "enum",
              options: [{label: 'Pending', value: 'pending'}, {label: 'CheckOut', value: 'checkOut'}, {label: 'Reserved', value: 'reserved'}, {label: 'Completed', value: 'completed'}, {label: 'Failed', value: 'failed'}, {label: 'Canceled', value: 'canceled'}, {label: 'Timeout', value: 'timeout'}]
            }
        },
        {
          key: "totalPrice",
          label: "order.price",
          format: function(value, key, row, column) {
            return `<span>${formatService.formatPriceFromRequiredPayments(row.requiredPayments)}</span>`;
          },
          // filter: {
          //   type: "number"
          // }
        },
        // {
        //   key: "totalTax",
        //   label: "order.tax",
        //   format: function(value) {
        //     return `<span>${formatService.formatPrice(value, 'EUR')}</span>`
        //   },
        //   filter: {
        //     type: "number"
        //   }
        // },
        {
          key: "customer.fullName",
          // key: "customer",
          label: "order.customer",
          format: function(value) {
            // return value ? value.fullName : "Anonymous";
            return value ? value : "Anonymous";
          }
          // filter: {
          //     type: 'text'
          // }
        },
        {
          key: "paymentStatus",
          label: "order.paymentStatus",
          format: function(value) {
            return value ? value.charAt(0).toUpperCase() + value.slice(1) : '-';
          },
          filter: {
            type: "enum",
            options: [{label: 'Paid', value: 'paid'}, {label: 'Pending', value: 'pending'}, {label: 'None', value: 'none'}]
          }
        },
        {
          key: "salesChannel.name",
          label: "order.salesChannel",
          filter: {
            key: "salesChannelId", // overrule filter field, use different field for filtering than display field
            type: "enum",
            operators: ['equals', 'notEquals', 'isNull', 'notNull'], // overrule default operators
            options: optionService.salesChannels
          }
        },
        {
          key: "email",
          label: "person.email",
        },
        {
          key: "number",
          label: "order.number",
        },
        {
          key: "alertsCount",
          label: "common.alerts",
          filter: {
            type: "number"
          }
        },

      ],
      selected: [
        "order.createDate",
        "order.number",
        "order.price",
        "order.status",
        "order.customer",
        "order.paymentStatus",
      ] // labels of columns that are displayed
    },
    rows: {
      total: 0, // total rows in search result
      max: 20, // maximum number of rows to display at once, elastic search query size
      from: 0 // show rows from row number, elastic search query from
    },
    searchTerm: "",
    searchFields: [],
    filters: {
      // selected: []
      selected: [{key: "createDate", label: "Create date", operator: "gt", value: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString().replace('Z', '+02:00')}] // last 90 days
    },
    sort: {
      key: "createDate",
      direction: "desc"
    },
    bulkActions: []
  },

  // order detail view settings
  orderDetailView: {
    tabs: [
        { routeName: "order-detail", label: "common.details" },
        { routeName: "order-access", label: "common.access" },
        { routeName: "order-product", label: "common.product" },
        { routeName: "order-payment", label: "order.payment" },
        { routeName: "order-refund", label: "order.refund" },
        { routeName: "order-delivery", label: "order.delivery" },
    ],

    menu: [
      [
        {
          name: "cancel-order",
          label: "order.cancel",
          icon: "times"
        },
        {
          name: "send-email",
          label: "order.sendEmail",
          icon: "envelope"
        }
      ]
    ],

    form: {
      schema: {},
      options: {},
      commandDataMap: []
    }
  }
};

//
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
