export default class GraphqlQueryBuilder {
  constructor() {
    this.ignoreColumns = [];
  }

  setType(type) {
    this.type = type;
  }

  setId(id) {
    this.id = id;
  }

  setFilters(filters) {
    this.filters = filters;
  }

  setColumns(columns, alwaysIncludeFields = null) {
    this.columns = columns;
    this.fields = alwaysIncludeFields || [];
  }

  setIgnoreColumns(ignoreColumns) {
    this.ignoreColumns = ignoreColumns || [];
  }

  setFields(fields) {
    this.fields = fields;
  }

  setRows(rows) {
    this.rows = rows;
  }

  setSort(sort) {
    this.sort = sort;
  }

  build() {
    const queryArguments = [];
    let args = "";
    let fields = "";
    if (this.id) {
      queryArguments.push(`id: "${this.id}"`);
    }
    if (this.filters) {
      const f = this._getFilters(
        this._getFilterOptions(this.columns),
        this.filters.selected
      );
      if (f) {
        queryArguments.push(f);
      }
    }
    if (this.rows) {
      const p = this._getPagination(this.rows, this.sort);
      if (p) {
        queryArguments.push(p);
      }
    }
    if (this.columns && this.fields) {
      fields = this._getFields(
        this.columns.options,
        this.columns.selected,
        this.fields
      );
    }
    if (this.fields && !this.columns) {
      fields = this._formatFieldString(this.fields);
    }
    if (queryArguments.length > 0) {
      args = `(${queryArguments.join(", ")})`;
    }

    return `query { ${this.type}${args}${fields} }`;
  }

  _getFilters(filterOptions, selectedFilters) {
    let filters = "";
    selectedFilters.forEach(filter => {
      const option = this._getOptionByKey(filterOptions, filter.key);
      if (option) {
        if (filters !== "") filters += ", ";
        // filters += `${option.filterKey}: {${filter.operator}: ${this._getFilterValue(option.type, filter.value)}}`;
        const operator = this._getFilterOperator(filter.operator);
        filters += `${option.filterKey}: {${operator}: ${this._getFilterValue(option.type, filter.value, operator)}}`;
      }
    });
    if (filters === "") {
      return null;
    }
    return `filters: {${filters}}`;
  }

  _getFilterOptions(columns) {
    const options = [];
    columns.options.forEach(option => {
      if (option.filter && option.filter.type) {
        options.push({
          key: option.key, // the key of the display label
          filterKey: option.filter.key ? option.filter.key : option.key, // the key to filter on, default use same as display key
          label: option.label,
          type: option.filter.type
        });
      }
    });
    return options;
  }

  _getFilterOperator(operator) {
    if (operator === 'isNull') {
      return 'equals';
    }
    if (operator === 'notNull') {
      return 'notEquals';
    }
    return operator;
  }

  _getFilterValue(type, value, operator) {
    if (type === "number" || value === null) {
      return value;
    }
    if(type === "enum" && Array.isArray(value)) {
      return `[${value.map(v => '"' + v + '"')}]`;
    }
    if(type === 'date' && operator === 'between') {
      const values = String(value).split(',');
      const start = values.length > 1 ? values[0] : null;
      const end = values.length > 1 ? values[1] : null;
      return `{start: ${start}, end: ${end}}`;
    }
    return `"${value}"`;
  }

  _getFields(columnOptions, selectedColumns, alwaysIncludeFields = []) {
    const self = this;
    // const fields = alwaysIncludeFields;
    // selectedColumns.forEach((label) => {
    //     const option = this._getOptionByKey(columnOptions, label);
    //     if(option && option.key && fields.indexOf(label) === -1) {
    //         fields.push(option.key);
    //     }
    // });

    // !!!!!!!!! TMP: always return all fields now
    // const fields = [];
    const fields = alwaysIncludeFields;
    columnOptions.forEach(option => {
      // if(option.key && option.key.includes('.')) {
      //   // add field with sub fields
      //   const field = option.key.split('.').reduce((acc, key, index, src) => {
      //     if(src.length-1 === index) { // check if is last element
      //       // if last element, add field key and closing brackets
      //       acc += key;
      //       for (let i = 0; i++; i <= index) {
      //         acc += '}';
      //       }
      //     } else {
      //       acc += `${key}{`;
      //     }
      //   });
      //   fields.push(field);
      // } else {
      //   // add field
      //   fields.push(option.key);
      // }

      const fieldName = self._getFieldName(option.key);
      // if(!fields.includes(fieldName)) {
      if(!fields.includes(fieldName) && !this.ignoreColumns.includes(fieldName)) {
        fields.push(fieldName);
        // fields.unshift(fieldName);
      }
      // fields.push(self._getFieldName(option.key));
    });
    return this._formatFieldString(fields);
  }

  _getFieldName(key) {
    let name = key;
    if(key && key.includes('.')) {
      name = '';
      // add field with sub fields
      key.split('.').forEach((key, index, src) => {
        if(src.length-1 === index) { // check if is last element
          // if last element, add field key and closing brackets
          name += key;
          for (let i = 0; i < index; i++) {
            name += '}';
          }
        } else {
          name += `${key}{`;
        }
      });
    }
    return name;
  }

  _formatFieldString(fields) {
    return `{${fields.join(",")}}`;
  }

  _getOptionByKey(options, key) {
    for (let i = 0; i < options.length; i++) {
      if (options[i]["key"] === key) {
        return options[i];
      }
    }
    return null;
  }

  _getPagination(rows, sort) {
    let pagination = [];
    if((rows && rows.max !== null && rows.from !== null)) {
      pagination.push(`first: ${rows.from}`);
      pagination.push(`offset: ${rows.max}`);
    }
    if(sort && sort.key && sort.direction) {
      pagination.push(`orderBy: [${sort.key}_${sort.direction.toUpperCase()}]`);
    }
    if (pagination.length === 0) {
      return null;
    }
    return `${pagination.join(', ')}`;
  }


}
