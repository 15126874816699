import * as mutationTypes from "../mutation-types";
import BackendService from "@/services/backend.service";
import GraphqlQueryBuilder from "@/services/GraphqlQueryBuilder.service";
import validationService from "@/services/validation.service";
import * as jobTypes from "../job-types";
import router from '@/router'
const backoffice = new BackendService();


export default {

    async getOfferDefinitions({ commit, state }, {rows, sort, columns, filters}) {
        // const c = {options: [], selected: columns.selected}; // create custom column selection because different types of validityPeriod doest not work with query builder

        // // build query
        // const builder = new GraphqlQueryBuilder();
        // builder.setType("offerDefinitions");
        // builder.setRows(rows);
        // builder.setSort(sort);
        // builder.setColumns(c, ["id", "name", "content{... on OfferContentAccess{id,eventManagerId,eventId,accessDefinitionId,requestedConditionPath,capacityLocationPath}, ... on OfferContentProduct{id,productDefinitionId,productVariantId,requestedConditionPath}}"]);
        // if( validationService.validateDatatableFilters(filters) ) {
        //     builder.setFilters(filters);
        // }
        // const query = builder.build();

        const first = rows && rows.from ? rows.from : 0;
        const offset = rows && rows.max ? rows.max : 20;
        const query = `query { offerDefinitions(first: ${first}, offset: ${offset}){ ... on ConditionalAccessCrossSell{id,type,name,conditionsString,eventManagerId,eventId,accessDefinitionId,requestedConditionPath,capacityLocationPath,nameTranslations{languageCode,text},event{name,start,location}}, ... on ConditionalProductCrossSell{id,type,name,conditionsString,productDefinitionId,productVariantId,requestedConditionPath,nameTranslations{languageCode,text},productDefinition{name}} }}`;

        //
        commit(mutationTypes.START_OFFER_DEFINITION_JOB, jobTypes.GET_OFFER_DEFINITION);
        const result = await backoffice.sendQuery(query);
        if (result.errors) {
            commit(mutationTypes.OFFER_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_OFFER_DEFINITION_JOB, jobTypes.GET_OFFER_DEFINITION);
        } else {
            commit(mutationTypes.RECEIVED_OFFER_DEFINITIONS, { offerDefinitions: result.data.offerDefinitions });
            commit(mutationTypes.END_OFFER_DEFINITION_JOB, jobTypes.GET_OFFER_DEFINITION);
        }
    },

    async getOfferDefinition({ commit, state }, {id}) {
        commit(mutationTypes.START_OFFER_DEFINITION_JOB, jobTypes.GET_OFFER_DEFINITION);
        commit(mutationTypes.CLEAR_OFFER_DEFINITION);
        const query = `query { offerDefinition(id: "${id}"){ ... on ConditionalAccessCrossSell{id,type,name,conditionsString,eventManagerId,eventId,accessDefinitionId,requestedConditionPath,capacityLocationPath,nameTranslations{languageCode,text},event{name,start,location}}, ... on ConditionalProductCrossSell{id,type,name,conditionsString,productDefinitionId,productVariantId,requestedConditionPath,nameTranslations{languageCode,text},productDefinition{name}} }}`;
        const result = await backoffice.sendQuery(query);
        if (result.error) {
            commit(mutationTypes.OFFER_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_OFFER_DEFINITION_JOB, jobTypes.GET_OFFER_DEFINITION);
        } else {
            result.data.offerDefinition.conditions = result.data.offerDefinition && result.data.offerDefinition.conditionsString ? JSON.parse(result.data.offerDefinition && result.data.offerDefinition.conditionsString) : {};
            commit(mutationTypes.RECEIVED_OFFER_DEFINITION, { offerDefinition: result.data.offerDefinition });
            commit(mutationTypes.END_OFFER_DEFINITION_JOB, jobTypes.GET_OFFER_DEFINITION);
        }
    },

    async saveOfferDefinitionMutations({ commit, state, dispatch }, {commands, id}) {
        commit(mutationTypes.START_OFFER_DEFINITION_JOB, jobTypes.SAVE_OFFER_DEFINITION);
        const result = await backoffice.sendCommandBatch(commands);
        if (result.error) {
            commit(mutationTypes.OFFER_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_OFFER_DEFINITION_JOB, jobTypes.SAVE_OFFER_DEFINITION);
        } else {
            commit(
                mutationTypes.SAVED_OFFER_DEFINITION,
                commands.reduce(
                    (state, command) => Object.assign(state, command.data),
                    {}
                )
            );
            commit(mutationTypes.END_OFFER_DEFINITION_JOB, jobTypes.SAVE_OFFER_DEFINITION);

            if(id) dispatch('getOfferDefinition', {id: id});
        }
    },

    async createOfferDefinition({ commit, state, dispatch }, {offerDefinition}) {
        commit(mutationTypes.START_OFFER_DEFINITION_JOB, jobTypes.SAVE_OFFER_DEFINITION);
        const result = await backoffice.sendCommand('CreateOffer', offerDefinition);
        if (result.error) {
            commit(mutationTypes.OFFER_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_OFFER_DEFINITION_JOB, jobTypes.SAVE_OFFER_DEFINITION);
        } else {
            commit(mutationTypes.SAVED_OFFER_DEFINITION, offerDefinition);
            commit(mutationTypes.END_OFFER_DEFINITION_JOB, jobTypes.SAVE_OFFER_DEFINITION);
            dispatch('getOfferDefinition', {id: result.data.offerId});
            router.push({ name: "offer-definition-detail", params: { id: result.data.offerId } });
        }
    },

    async removeOfferDefinition({ commit, state, dispatch }, {id}) {
        commit(mutationTypes.START_OFFER_DEFINITION_JOB, jobTypes.SAVE_OFFER_DEFINITION);
        const result = await backoffice.sendCommand('RemoveOffer', {id});
        if (result.error) {
            commit(mutationTypes.OFFER_DEFINITION_ERROR, result.error);
            commit(mutationTypes.END_OFFER_DEFINITION_JOB, jobTypes.SAVE_OFFER_DEFINITION);
        } else {
            commit(mutationTypes.END_OFFER_DEFINITION_JOB, jobTypes.SAVE_OFFER_DEFINITION);
            router.push({ name: "offer-root" });
        }
    },

    async startBatchOperation({ commit, dispatch }, operations) {
        const jobName = 'start-batch-operation';
        commit(mutationTypes.START_OFFER_DEFINITION_JOB, jobName);
        await dispatch('core/startBatchOperation', operations, {root:true});
        commit(mutationTypes.END_OFFER_DEFINITION_JOB, jobName);
    },

    clearOfferDefinition({ commit, state }) {
        commit(mutationTypes.CLEAR_OFFER_DEFINITION);
    },


};
