import * as types from "../mutation-types";
import _ from "lodash";
// import i18n from "@/locals/index";

export default {
    [types.CORE_ERROR](state, error) {
        state.error = { message: error };
    },

    [types.FLUSH_CORE_ERROR](state) {
        state.error = { message: null };
    },

    [types.START_CORE_JOB](state, job) {
        state.jobs.push(job);
    },

    [types.END_CORE_JOB](state, job) {
        // state.jobs = state.jobs.filter(j => j.key !== job.key);
        state.jobs = state.jobs.filter(j => (j.id && j.id === job.id) || (!j.id && j.key !== job.key));
    },

    [types.FINISH_BATCH_OPERATION](state, job) {
        const j = state.jobs.find(j => j.id === job.id);
        if(j && j.toast) {
            j.toast.text('Finished batch operation.').goAway(2000);
        }
        state.jobs = state.jobs.filter(j => (j.id && j.id === job.id) || (!j.id && j.key !== job.key));
    },

    [types.REGISTER_CORE_JOB_TOAST](state, {job, toast}) {
        for (let i in state.jobs) {
            if (state.jobs[i].id === job.id) {
                state.jobs[i].toast = toast;
                break;
            }
        }
    },

    // [types.LOGGED_IN](state, {user}) {
    //     state.user = user;
    // },
    // [types.LOGGED_OUT](state) {
    //     state.user = {};
    // },


    [types.RECEIVED_USER](state, {user}) {
        state.user = user;
    },
    [types.CLEAR_USER](state) {
        state.user = null;
    },


    [types.RECEIVED_SEARCH_RESULT](state, searchResults) {
        state.searchResults = searchResults;
    },
    [types.RECEIVED_CURRENCY_OPTIONS](state, currencies) {
        state.options.conditions.price.currency = currencies;
    },
    [types.RECEIVED_TAG_OPTIONS](state, tags) {
        state.options.tags = tags;
    },

    // name suggestions
    [types.RECEIVED_NAME_SUGGESTIONS](state, { suggestions }) {
        state.nameSuggestions = suggestions;
    },
    [types.CLEAR_NAME_SUGGESTIONS](state) {
        state.nameSuggestions = [];
    },

};
