<script>
export default {
  name: "Type",
  render: function(createElement) {
    return createElement(
      this.element,
      {
        class: `t${this.level}`
      },
      this.$slots.default,
      `Type ${this.level}`
    );
  },
  props: {
    element: {
      type: String,
      default: "p"
    },
    level: {
      type: Number,
      required: true,
      validator: value => {
        return [1, 2, 3].includes(value);
      }
    }
  }
};
</script>

<style scoped lang="scss">
$levels: 1 2 3;

@include includeType($levels, 't');
</style>
