import Vue from "vue";
import Vuex from "vuex";
import core from "@/modules/core/store/index";
import event from "@/modules/event/store/index";
import eventTemplate from "@/modules/event-template/store/index";
import customer from "@/modules/customer/store/index";
import order from "@/modules/order/store/index";
import system from "@/modules/system/store/index";
import account from "@/modules/account/store/index";
import product from "@/modules/product/store/index";
import credit from "@/modules/credit/store/index";
import membership from "@/modules/membership/store/index";
import tePackage from "@/modules/package/store/index";
import offer from "@/modules/offer/store/index";
import report from "@/modules/report/store/index";

Vue.use(Vuex);

// export default new Vuex.Store({
//   state: {},
//   mutations: {},
//   actions: {}
// });

// set debug mode
const debug = process.env.NODE_ENV !== 'production';

//
export default new Vuex.Store({
  modules: {
    core,
    event,
    eventTemplate,
    customer,
    order,
    system,
    account,
    product,
    credit,
    membership,
    'package': tePackage,
    offer,
    report
  },
  strict: debug
});
