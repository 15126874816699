import * as mutationTypes from "../mutation-types";
import BackendService from "@/services/backend.service";
import GraphqlQueryBuilder from "@/services/GraphqlQueryBuilder.service";
import validationService from "@/services/validation.service";
import * as jobTypes from "../job-types";
import router from '@/router'
const backoffice = new BackendService();


export default {

    async getLedger({ commit, state }, {rows, sort, columns, filters}) {
        // const c = {options: [], selected: columns.selected}; // create custom column selection because different types of validityPeriod doest not work with query builder

        // build query
        const builder = new GraphqlQueryBuilder();
        builder.setType("ledgerEntries");
        builder.setRows(rows);
        builder.setSort(sort);
        // builder.setColumns(c, ["id", "name", "content{... on OfferContentAccess{id,eventManagerId,eventId,accessDefinitionId,requestedConditionPath,capacityLocationPath}, ... on OfferContentProduct{id,productDefinitionId,productVariantId,requestedConditionPath}}"]);
        builder.setColumns(columns, ["id", "currency{code,name,symbol,exponent}"]);
        builder.setIgnoreColumns(["salesChannelId", "registerId", "userId"]);
        if( validationService.validateDatatableFilters(filters) ) {
            builder.setFilters(filters);
        }
        const query = builder.build();

        // const first = rows && rows.from ? rows.from : 0;
        // const offset = rows && rows.max ? rows.max : 20;
        // const query = `query { offerDefinitions(first: ${first}, offset: ${offset}){ ... on ConditionalAccessCrossSell{id,type,name,conditionsString,eventManagerId,eventId,accessDefinitionId,requestedConditionPath,capacityLocationPath,nameTranslations{languageCode,text},event{name,start,location}}, ... on ConditionalProductCrossSell{id,type,name,conditionsString,productDefinitionId,productVariantId,requestedConditionPath,nameTranslations{languageCode,text},productDefinition{name}} }}`;

        //
        commit(mutationTypes.START_REPORT_JOB, jobTypes.GET_LEDGER);
        const result = await backoffice.sendQuery(query);
        if (result.errors) {
            commit(mutationTypes.REPORT_ERROR, result.error);
            commit(mutationTypes.END_REPORT_JOB, jobTypes.GET_LEDGER);
        } else {
            commit(mutationTypes.RECEIVED_REPORT_LEDGER, { ledger: result.data.ledgerEntries });
            commit(mutationTypes.END_REPORT_JOB, jobTypes.GET_LEDGER);
        }
    },

    clearLedger({ commit, state }) {
        commit(mutationTypes.CLEAR_REPORT_LEDGER);
    },


};
