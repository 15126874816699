export default [
    // {
    //     path: "/offer",
    //     name: "offer-root",
    //     redirect: { name: 'package-definition-list' }
    // },

    {
        path: "/offer",
        name: "offer-root",
        component: () =>
            import(/* webpackChunkName: "offer" */ "@/modules/offer/views/OfferDefinitionListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    },

    {
        path: "/offer/:id",
        name: "offer-definition-detail-root",
        component: () =>
            import(/* webpackChunkName: "offer" */ "@/modules/offer/views/OfferDefinitionDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        children: [
            {
                path: "detail",
                name: "offer-definition-detail",
                components: {
                    detail: () =>
                        import(/* webpackChunkName: "offer" */ "@/modules/offer/components/OfferDefinitionDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
                }
            },
        ]
    },

];
