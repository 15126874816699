import * as types from "../mutation-types";
import _ from "lodash";

export default {
  // jobs and error
  [types.EVENT_ERROR](state, error) {
    state.error = { message: error };
  },
  [types.FLUSH_EVENT_ERROR](state) {
    state.error = { message: null };
  },
  [types.START_EVENT_JOB](state, key) {
    state.jobs.push(key);
  },
  [types.END_EVENT_JOB](state, key) {
    // _.remove(state.jobs, k => k === key);
    state.jobs = state.jobs.filter(job => job !== key);
  },


  // event
  [types.RECEIVED_EVENTS](state, { events }) {
    state.events = events;
  },
  [types.RECEIVED_EVENT](state, { event }) {
    state.event = event;
  },
  [types.SAVED_EVENT](state, commandData) {
    state.event = _.merge(state.event, commandData);
  },
  [types.CREATED_EVENT](state, data) {
    state.event = data;
  },
  [types.CLEAR_EVENT](state) {
    state.event = {};
  },


  //
  [types.SELECT_EVENT_LIST_COLUMNS](state, selectedColumns) {
    state.eventListView.columns.selected = selectedColumns;
  },
  [types.SET_EVENT_LIST_ROWS](state, rows) {
    state.eventListView.rows = _.merge(state.eventListView.rows, rows);
  },
  [types.SET_EVENT_LIST_SORT](state, sort) {
    state.eventListView.sort = _.merge(state.eventListView.sort, sort);
  },
  [types.FILTER_EVENT_LIST](state, selectedFilters) {
    state.eventListView.filters.selected = selectedFilters;
  },
  [types.SET_EVENT_LIST_SEARCH_TERM](state, searchTerm) {
    state.eventListView.searchTerm = searchTerm;
  },


  // access definition
  [types.RECEIVED_ACCESS_DEFINITIONS](state, accessDefinitions) {
    state.accessDefinitions = accessDefinitions;
  },
  [types.RECEIVED_ACCESS_DEFINITION](state, accessDefinition) {
    state.accessDefinition = accessDefinition;
  },
  [types.SAVED_ACCESS_DEFINITION](state, commandData) {
    state.accessDefinition = _.merge(state.accessDefinition, commandData);
  },
  [types.CREATED_ACCESS_DEFINITION](state, data) {
    state.accessDefinition = data;
  },
  [types.CLEAR_ACCESS_DEFINITIONS](state) {
    state.accessDefinitions = [];
  },
  [types.CLEAR_ACCESS_DEFINITION](state) {
    state.accessDefinition = {};
  },
  [types.SET_ACCESS_DEFINITION_FORM_OPTIONS](
    state,
    { tags, capacityLocations, rules, price, postponed, limit, capacityGroups }
  ) {
    state.accessDefinitionDetailView.form.schema.groups.forEach(group => {
      group.fields.forEach(field => {
        if (tags && field.model === "tags") field.values = tags;
        if (capacityLocations && field.model === "capacityLocations")
          field.values = capacityLocations;
        if (tags && field.model === "accessConditions")
          field.conditionOptions.tags = tags;
        if (rules && field.model === "accessConditions")
          field.conditionOptions.rules = rules;
        if (price && field.model === "accessConditions")
          field.conditionOptions.price = price;
        if (postponed && field.model === "accessConditions")
          field.conditionOptions.postponed = postponed;
        if (limit && field.model === "accessConditions")
          field.conditionOptions.limit = limit;
        if (capacityGroups && field.model === "accessConditions")
          field.conditionOptions.capacityGroups = capacityGroups;
      });
    });
  },


  // capacity
  [types.RECEIVED_CAPACITIES](state, capacities) {
    state.capacities = capacities;
  },
  [types.RECEIVED_CAPACITY](state, capacity) {
    state.capacity = capacity;
  },
  [types.SAVED_CAPACITY](state, capacity) {
    // state.capacity = _.merge(state.capacity, commandData);
    // if (state.capacities[0]) {
    //   state.capacities[0] = _.merge(state.capacities[0], capacity);
    // }
    if (state.capacities) {
      state.capacities[0] = _.merge(state.capacities[0], capacity);
    }
  },
  [types.CREATED_CAPACITY](state, data) {
    state.capacities.push(data);
  },
  [types.CLEAR_CAPACITY](state) {
    state.capacities = [];
  },


  // event attendees
  [types.RECEIVED_EVENT_ATTENDEES](state, { attendees }) {
    state.eventAttendees = attendees;
  },
  [types.CLEAR_EVENT_ATTENDEES](state) {
    state.eventAttendees = [];
  },
  [types.RECEIVED_EVENT_ATTENDEE_PRICE_LIST](state, { eventPrices }) {
    state.eventAttendeeImportPriceList = eventPrices;
  },
  [types.CLEAR_EVENT_ATTENDEE_PRICE_LIST](state) {
    state.eventAttendeeImportPriceList = [];
  },




};





