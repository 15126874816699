import * as types from "../mutation-types";
import _ from "lodash";

export default {
  [types.SYSTEM_ERROR](state, error) {
    state.error = { message: error };
  },

  [types.FLUSH_SYSTEM_ERROR](state) {
    state.error = { message: null };
  },

  [types.START_SYSTEM_JOB](state, key) {
    state.jobs.push(key);
  },

  [types.END_SYSTEM_JOB](state, key) {
    state.jobs = state.jobs.filter(job => job !== key);
  },


  [types.RECEIVED_USERS](state, { users }) {
    state.users = users;
  },

  [types.RECEIVED_USER](state, { user }) {
    state.user = user;
  },

  [types.SAVED_USER](state, commandData) {
    state.user = _.merge(state.user, commandData);
  },

  [types.CREATED_USER](state, data) {
    state.user = data;
  },

  [types.CLEAR_USER](state) {
    state.user = {};
  },


  [types.RECEIVED_TAGS](state, { tags }) {
    state.tags = tags;
  },

  [types.RECEIVED_TAG](state, { tag }) {
    state.tag = tag;
  },

  [types.SAVED_TAG](state, commandData) {
    state.tag = _.merge(state.tag, commandData);
  },

  [types.CREATED_TAG](state, data) {
    state.tag = data;
  },

  [types.CLEAR_TAG](state) {
    state.tag = {};
  },


  [types.RECEIVED_COUPONS](state, { coupons }) {
    state.coupons = coupons;
  },

  [types.RECEIVED_COUPON](state, { coupon }) {
    state.coupon = coupon;
  },

  [types.SAVED_COUPON](state, commandData) {
    state.coupon = _.merge(state.coupon, commandData);
  },

  [types.CREATED_COUPON](state, data) {
    state.coupon = data;
  },

  [types.CLEAR_COUPON](state) {
    state.coupon = {};
  },

  [types.RECEIVED_TOKENS](state, { tokens }) {
    state.tokens = tokens;
  },



  [types.RECEIVED_EMAIL_TEMPLATES](state, { emailTemplates }) {
    state.emailTemplates = emailTemplates;
  },

  [types.RECEIVED_EMAIL_TEMPLATE](state, { emailTemplate }) {
    state.emailTemplate = emailTemplate;
  },

  [types.SAVED_EMAIL_TEMPLATE](state, data) {
    state.emailTemplate = data;
  },

  [types.CLEAR_EMAIL_TEMPLATE](state) {
    state.emailTemplate = {};
  },



  [types.RECEIVED_DOCUMENT_TEMPLATES](state, { documentTemplates }) {
    state.documentTemplates = documentTemplates;
  },

  [types.RECEIVED_DOCUMENT_TEMPLATE](state, { documentTemplate }) {
    state.documentTemplate = documentTemplate;
  },

  [types.SAVED_DOCUMENT_TEMPLATE](state, data) {
    state.documentTemplate = data;
  },
  
  [types.CLEAR_DOCUMENT_TEMPLATE](state) {
    state.documentTemplate = {};
  },



  [types.RECEIVED_SALES_CHANNELS](state, { salesChannels }) {
    state.salesChannels = salesChannels;
  },

  [types.RECEIVED_SALES_CHANNEL](state, { salesChannel }) {
    state.salesChannel = salesChannel;
  },

  [types.SAVED_SALES_CHANNEL](state, data) {
    state.salesChannel = _.merge(state.salesChannel, data);
  },

  [types.CREATED_SALES_CHANNEL](state, data) {
    state.salesChannel = data;
  },

  [types.CLEAR_SALES_CHANNEL](state) {
    state.salesChannel = {};
  },



  [types.CREATED_REGISTER](state, data) {
    state.salesChannel.registers.push(data);
  },

  [types.SAVED_REGISTER](state, data) {
    state.salesChannel.registers = state.salesChannel.registers.map(r => {
      if(r.id === data.registerId) {
        return {
          id: data.registerId,
          name: data.name,
          description: data.description,
          deliveryDefinition: data.deliveryDefinition,
          orderTimeout: data.orderTimeout,
        }
      }
      return r
    });
  },

  [types.REMOVED_REGISTER](state, {registerId}) {
    state.salesChannel.registers = state.salesChannel.registers.filter(r => r.id !== registerId);
  },

  [types.SAVE_REGISTER_ORDER_TIMEOUT](state, {registerId, id, type, value}) {
    state.salesChannel.registers = state.salesChannel.registers.map(r => {
      if(r.id === registerId) {
        r.orderTimeout = {
          id: id,
          type: type,
          value: value,
        }
      }
      return r
    });
  },

  [types.REMOVE_REGISTER_ORDER_TIMEOUT](state, {registerId}) {
    state.salesChannel.registers = state.salesChannel.registers.map(r => {
      if(r.id === registerId) {
        r.orderTimeout = null
      }
      return r
    });
  },





  [types.RECEIVED_PAYMENT_METHODS](state, { paymentMethods }) {
    state.paymentMethods = paymentMethods;
  },

  [types.RECEIVED_PAYMENT_METHOD](state, { paymentMethod }) {
    state.paymentMethod = paymentMethod;
  },

  [types.SAVED_PAYMENT_METHOD](state, data) {
    state.paymentMethod = _.merge(state.paymentMethod, data);
  },

  [types.CREATED_PAYMENT_METHOD](state, data) {
    state.paymentMethod = data;
  },

  [types.CLEAR_PAYMENT_METHOD](state) {
    state.paymentMethod = {};
  },





  [types.RECEIVED_OPT_IN_DEFINITIONS](state, { optInDefinitions }) {
    state.optInDefinitions = optInDefinitions;
  },

  [types.RECEIVED_OPT_IN_DEFINITION](state, { optInDefinition }) {
    state.optInDefinition = optInDefinition;
  },

  [types.SAVED_OPT_IN_DEFINITION](state, data) {
    state.optInDefinition = _.merge(state.optInDefinition, data);
  },

  [types.CREATED_OPT_IN_DEFINITION](state, data) {
    state.optInDefinition = data;
  },

  [types.CLEAR_OPT_IN_DEFINITION](state) {
    state.optInDefinition = {};
  },





  [types.RECEIVED_ORDER_MESSAGE_DEFINITIONS](state, { orderMessageDefinitions }) {
    state.orderMessageDefinitions = orderMessageDefinitions;
  },

  [types.RECEIVED_ORDER_MESSAGE_DEFINITION](state, { orderMessageDefinition }) {
    state.orderMessageDefinition = orderMessageDefinition;
  },

  [types.SAVED_ORDER_MESSAGE_DEFINITION](state, data) {
    state.orderMessageDefinition = _.merge(state.orderMessageDefinition, data);
  },

  [types.CREATED_ORDER_MESSAGE_DEFINITION](state, data) {
    state.orderMessageDefinition = data;
  },

  [types.CLEAR_ORDER_MESSAGE_DEFINITION](state) {
    state.orderMessageDefinition = {};
  },





  [types.RECEIVED_INTEGRATION_PRODUCT_DEFINITION](state, { productDefinitions }) {
    state.integration.productDefinitions = productDefinitions;
  },
  [types.RECEIVED_INTEGRATION_PRICES](state, { prices }) {
    state.integration.prices = prices;
  },

  [types.RECEIVED_INTEGRATION_FIONA_CONFIG](state, { config }) {
    state.integration.fiona.config = config;
  },
  [types.SAVED_INTEGRATION_FIONA_CONFIG](state, { config }) {
    state.integration.fiona.config = config;
  },
  [types.CLEAR_INTEGRATION_FIONA_CONFIG](state) {
    state.integration.fiona.config = {};
  },

  [types.RECEIVED_INTEGRATION_FIONA_ENTITY_MAPS](state, { entityMaps }) {
    state.integration.fiona.entityMaps = entityMaps;
  },
  [types.RECEIVED_INTEGRATION_FIONA_ENTITY_MAP](state, { entityMap }) {
    state.integration.fiona.entityMap = entityMap;
  },
  [types.SAVED_INTEGRATION_FIONA_ENTITY_MAP](state, { entityMaps }) {
    state.integration.fiona.entityMaps = entityMaps;
  },
  [types.CLEAR_INTEGRATION_FIONA_ENTITY_MAPS](state) {
    state.integration.fiona.entityMaps = [];
  },
  [types.CLEAR_INTEGRATION_FIONA_ENTITY_MAP](state) {
    state.integration.fiona.entityMap = {};
  },

  [types.RECEIVED_INTEGRATION_FIONA_ACCREDITATIONS](state, { accreditations }) {
    state.integration.fiona.accreditations = accreditations;
  },
  [types.CLEAR_INTEGRATION_FIONA_ACCREDITATIONS](state) {
    state.integration.fiona.accreditations = [];
  },

  [types.RECEIVED_INTEGRATION_FIONA_VOLUNTEERS](state, { volunteers }) {
    state.integration.fiona.volunteers = volunteers;
  },
  [types.CLEAR_INTEGRATION_FIONA_VOLUNTEERS](state) {
    state.integration.fiona.volunteers = [];
  },

  [types.RECEIVED_INTEGRATION_FIONA_EVENTS](state, { events }) {
    state.integration.fiona.events = events;
  },
  [types.CLEAR_INTEGRATION_FIONA_EVENTS](state) {
    state.integration.fiona.events = [];
  },

  [types.RECEIVED_INTEGRATION_FIONA_SYNC_QUEUE](state, { syncJobs }) {
    state.integration.fiona.syncQueue = syncJobs;
  },
  [types.CLEAR_INTEGRATION_FIONA_SYNC_QUEUE](state) {
    state.integration.fiona.syncQueue = {};
  },

  [types.RECEIVED_INTEGRATION_FIONA_PROXY_EDITIONS](state, { editions }) {
    state.integration.fiona.proxy.editions = editions;
  },
  [types.RECEIVED_INTEGRATION_FIONA_PROXY_EDITION_TYPES](state, { editionTypes }) {
    state.integration.fiona.proxy.editionTypes = editionTypes;
  },
  [types.RECEIVED_INTEGRATION_FIONA_PROXY_GUESTBOOKS](state, { guestbooks }) {
    state.integration.fiona.proxy.guestbooks = guestbooks;
  },
  [types.RECEIVED_INTEGRATION_FIONA_PROXY_SHOWS](state, { shows }) {
    state.integration.fiona.proxy.shows = shows;
  },
  [types.RECEIVED_INTEGRATION_FIONA_PROXY_ACCREDITATIONS](state, { accreditations }) {
    state.integration.fiona.proxy.accreditations = accreditations;
  },
  [types.RECEIVED_INTEGRATION_FIONA_PROXY_VOLUNTEER_EDITIONS](state, { volunteerEditions }) {
    state.integration.fiona.proxy.volunteerEditions = volunteerEditions;
  },
  [types.RECEIVED_INTEGRATION_FIONA_PROXY_VOLUNTEERS](state, { volunteers }) {
    state.integration.fiona.proxy.volunteers = volunteers;
  },
  [types.RECEIVED_INTEGRATION_FIONA_PROXY_ACCREDITATION_BADGE_TYPES](state, { badgeTypes }) {
    state.integration.fiona.proxy.accreditationBadgeTypes = badgeTypes;
  },
  [types.RECEIVED_INTEGRATION_FIONA_PROXY_ACCREDITATION_BADGE_STATUS](state, { badgeStatus }) {
    state.integration.fiona.proxy.accreditationBadgeStatus = badgeStatus;
  },
  [types.RECEIVED_INTEGRATION_FIONA_PROXY_VOLUNTEER_BADGE_TYPES](state, { badgeTypes }) {
    state.integration.fiona.proxy.volunteerBadgeTypes = badgeTypes;
  },

};
