import { WebClient } from "ticketengine-sdk";
import router from "@/router";

export default class BackendService {
  constructor() {
    // this.client = new WebClient(null, null, "http://127.0.0.1:8000", "http://127.0.0.1:8000/graph");
    this.client = new WebClient({
      // authUrl: process.env.VUE_APP_AUTH_URL || "https://auth.ticketengine.io",
      // adminApiUrl: process.env.VUE_APP_ADMIN_API_URL || "https://admin-api.ticketengine.io",
      // graphApiUrl: process.env.VUE_APP_GRAPH_API_URL || "https://graph-api.ticketengine.io"
      authUrl: config.VUE_APP_AUTH_URL || process.env.VUE_APP_AUTH_URL,
      adminApiUrl: config.VUE_APP_ADMIN_API_URL || process.env.VUE_APP_ADMIN_API_URL,
      graphApiUrl: config.VUE_APP_GRAPH_API_URL || process.env.VUE_APP_GRAPH_API_URL
    });
  }

  async getAuthToken(data) {
    try {
      return await this.client.getAuthToken(data);
    } catch (e) {
      const message = (e.response && e.response.data && e.response.data.data && e.response.data.data.message) ? e.response.data.data.message : (e.response && e.response.data && e.response.data.message) ? e.response.data.message : (e.message) ? e.message : 'Oops something went wrong...' ;
      return { error: message };
    }
  }

  async callAdminApi(query, data, headers, retryPolicy, method) {
    try {
      return await this.client.callAdminApi(query, data, headers, retryPolicy, method);
    } catch (e) {
      if(e.response && e.response.status && (e.response.status === 401 || e.response.status === 403)) router.push('/login');
      const message = (e.response && e.response.data && e.response.data.data && e.response.data.data.message) ? e.response.data.data.message : (e.response && e.response.data && e.response.data.message) ? e.response.data.message : (e.message) ? e.message : 'Oops something went wrong...' ;
      return { error: message };
    }
  }

  async sendQuery(query) {
      try {
          return await this.client.query(query);
      } catch (e) {
          if(e.response && e.response.status && (e.response.status === 401 || e.response.status === 403)) router.push('/login');
          const message = (e.response && e.response.data && e.response.data.data && e.response.data.data.message) ? e.response.data.data.message : (e.response && e.response.data && e.response.data.message) ? e.response.data.message : (e.message) ? e.message : 'Oops something went wrong...' ;
          return { error: message };
      }
  }

  /**
   * Send a command.
   * Eg. The command RenameEvent will have to call the function renameEvent in the access context of the client. This will result
   * in a call to the function: this.client.access.renameEvent()
   * @param type
   * @param data
   * @returns {Promise<*>}
   */
  async sendCommand(type, data) {
    const commandFunction = type.charAt(0).toLowerCase() + type.slice(1); // name of the command function to look for in the context
    const context = this._findCommandContext(commandFunction); // get the command context
    // return this.client[context][commandFunction](data); // send command
    try {
      return await this.client[context][commandFunction](data); // send command
    } catch (e) {
      console.error(e);
      if(e.response && e.response.status && (e.response.status === 401 || e.response.status === 403)) router.push('/login');
        // return { error: e.message };
        const message = (e.response && e.response.data && e.response.data.data && e.response.data.data.message) ? e.response.data.data.message : (e.response && e.response.data && e.response.data.message) ? e.response.data.message : (e.message) ? e.message : 'Oops something went wrong...' ;
        return { error: message };
    }
  }

  /**
   * Send all commands received in batch array
   * @param batch                     [{type: '', data: {}}]
   * @returns {Promise<any[]>}
   */
  async sendCommandBatch(batch) {
    // return Promise.all(
    //   batch.map(command => {
    //     return this.sendCommand(command.type, command.data);
    //   })
    // );

    // execute all commands
    const results = await Promise.all(
      batch.map(command => {
        return this.sendCommand(command.type, command.data);
      })
    );

    // check if there are commands that return an error
    let e = null;
    results.forEach(result => {
      if (result.error) {
        e = result.error;
      }
    });

    // return
    if (e) {
      return { error: e };
    }
    return results;
  }

  _findCommandContext(commandFunction) {
    const commandContexts = [
      "access",
      "order",
      "email",
      "document",
      "payment",
      "salesChannel",
      "customer",
      "user",
      "tag",
      "tokens",
      "batchOperation",
      "product",
      "credit",
      "membership",
      "package",
      "optIn",
      "orderMessage",
      "offer",
    ]; // list of available command contexts
    for (let i = 0; i < commandContexts.length; i++) {
      if (
        Object.keys(this.client[commandContexts[i]]).indexOf(
          commandFunction
        ) !== -1
      ) {
        return commandContexts[i];
      }
    }
  }
}


