export const OFFER_DEFINITION_ERROR = "OFFER_DEFINITION_ERROR";
export const FLUSH_OFFER_DEFINITION_ERROR = "FLUSH_OFFER_DEFINITION_ERROR";

export const START_OFFER_DEFINITION_JOB = "START_OFFER_DEFINITION_JOB";
export const END_OFFER_DEFINITION_JOB = "END_OFFER_DEFINITION_JOB";

export const RECEIVED_OFFER_DEFINITIONS = "RECEIVED_OFFER_DEFINITIONS";
export const RECEIVED_OFFER_DEFINITION = "RECEIVED_OFFER_DEFINITION";
export const SAVED_OFFER_DEFINITION = "SAVED_OFFER_DEFINITION";
export const CLEAR_OFFER_DEFINITION = "CLEAR_OFFER_DEFINITION";

