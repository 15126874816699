export const CUSTOMER_ERROR = "CUSTOMER_ERROR";
export const FLUSH_CUSTOMER_ERROR = "FLUSH_CUSTOMER_ERROR";
export const START_CUSTOMER_JOB = "START_CUSTOMER_JOB";
export const END_CUSTOMER_JOB = "END_CUSTOMER_JOB";

export const RECEIVED_CUSTOMERS = "RECEIVED_CUSTOMERS";
export const RECEIVED_CUSTOMER = "RECEIVED_CUSTOMER";
export const RECEIVED_CUSTOMER_ORDERS = "RECEIVED_CUSTOMER_ORDERS";
export const RECEIVED_CUSTOMER_ACCESS = "RECEIVED_CUSTOMER_ACCESS";
export const RECEIVED_CUSTOMER_PRODUCTS = "RECEIVED_CUSTOMER_PRODUCTS";
export const RECEIVED_CUSTOMER_MEMBERSHIPS = "RECEIVED_CUSTOMER_MEMBERSHIPS";
export const SAVED_CUSTOMER = "SAVED_CUSTOMER";
export const CREATED_CUSTOMER = "CREATED_CUSTOMER";
export const CLEAR_CUSTOMER = "CLEAR_CUSTOMER";

// export const SET_CUSTOMER_LIST_QUERY = 'SET_CUSTOMER_LIST_QUERY';
// export const SET_CUSTOMER_LIST_TOTAL_ROWS = 'SET_CUSTOMER_LIST_TOTAL_ROWS';
export const SELECT_CUSTOMER_LIST_COLUMNS = "SELECT_CUSTOMER_LIST_COLUMNS";
export const SET_CUSTOMER_LIST_ROWS = "SET_CUSTOMER_LIST_ROWS";
export const SET_CUSTOMER_LIST_SORT = "SET_CUSTOMER_LIST_SORT";
export const FILTER_CUSTOMER_LIST = "FILTER_CUSTOMER_LIST";
export const SET_CUSTOMER_LIST_SEARCH_TERM = "SET_CUSTOMER_LIST_SEARCH_TERM";
