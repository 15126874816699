import * as types from "../mutation-types";
import _ from "lodash";

export default {
    [types.OFFER_DEFINITION_ERROR](state, error) {
        state.error = { message: error };
    },

    [types.FLUSH_OFFER_DEFINITION_ERROR](state) {
        state.error = { message: null };
    },

    [types.START_OFFER_DEFINITION_JOB](state, key) {
        state.jobs.push(key);
    },

    [types.END_OFFER_DEFINITION_JOB](state, key) {
        state.jobs = state.jobs.filter(job => job !== key);
    },

    [types.RECEIVED_OFFER_DEFINITIONS](state, { offerDefinitions }) {
        state.offerDefinitions = offerDefinitions;
    },

    [types.RECEIVED_OFFER_DEFINITION](state, { offerDefinition }) {
        state.offerDefinition = offerDefinition;
    },

    [types.SAVED_OFFER_DEFINITION](state, commandData) {
        state.offerDefinition = _.merge(state.offerDefinition, commandData);
    },

    [types.CLEAR_OFFER_DEFINITION](state) {
        state.offerDefinition = {};
    },


};
