import * as mutationTypes from "../mutation-types";
// import backendService from "@/modules/core/services/backend.service";
import BackendService from "@/services/backend.service";
import eventUtilService from "@/services/eventUtil.service";
import GraphqlQueryBuilder from "@/services/GraphqlQueryBuilder.service";
import validationService from "@/services/validation.service";
// import {WebClient} from 'ticketengine-sdk';
import router from "@/router";

const backoffice = new BackendService();

export default {
  /**
   * Get list of events
   * @param commit
   * @param state
   * @param query             GraphQL query string
   * @returns {Promise<void>}
   */
  async getEvents({ commit, state }, query) {
    commit(mutationTypes.START_EVENT_JOB, "get-event");
    commit(mutationTypes.CLEAR_EVENT);
    // const backoffice = new BackendService();
    const result = await backoffice.sendQuery(query);
    if (result.errors) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "get-event");
    } else {
      commit(mutationTypes.RECEIVED_EVENTS, { events: result.data.events });
      commit(mutationTypes.END_EVENT_JOB, "get-event");
      commit(mutationTypes.SET_EVENT_LIST_ROWS, { total: result.total });
    }
  },

  /**
   * Get single event
   * @param commit
   * @param state
   * @param query             GraphQL query string
   * @returns {Promise<void>}
   */
  async getEvent({ commit, state }, {id}) {
  // async getEvent({ commit, state }, query) {
    commit(mutationTypes.START_EVENT_JOB, "get-event");
    // commit(mutationTypes.CLEAR_EVENT);
    // const backoffice = new BackendService();
    const query = `query { event(id: "${id}"){id,eventManagerId,status,name,description,location,start,end,doorsOpen,doorsClose,alerts,totalCapacity,availableCapacity,reservedCapacity,grantedCapacity,tags{id,name},eventTemplateApplyStatus,eventTemplate{id,name},eventCapacityTemplateApplyStatus,eventCapacityTemplate{id,name},eventAccessTemplateApplyStatus,eventAccessTemplate{id,name},capacityLocationSummery{capacityLocationPath,name,capacity,issued,reserved,granted,available,used,start,end},hasTimeslots} }`;
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "get-event");
    } else {
      commit(mutationTypes.RECEIVED_EVENT, { event: result.data.event });
      commit(mutationTypes.END_EVENT_JOB, "get-event");
    }
  },

  // async getEventAttendees({ commit, state }, {eventId, from, max}) {
  async getEventAttendees({ commit, state }, query) {
    commit(mutationTypes.START_EVENT_JOB, "get-event");
    commit(mutationTypes.CLEAR_EVENT_ATTENDEES);
    // const query = `query { eventAttendees(id: "${eventId}", first: ${from}, offset: ${max}){id,orderId,type,status,price,tax,currency{code,symbol},name,order{id,email,number,createDate,remark},capacityLocationPath,customer{id,fullName},access{tokens}}}`;
    const result = await backoffice.sendQuery(query);
    if (result.errors) {
      commit(mutationTypes.EVENT_ERROR, { error: result.errors });
      commit(mutationTypes.END_EVENT_JOB, "get-event");
    } else {
      commit(mutationTypes.RECEIVED_EVENT_ATTENDEES, {
        attendees: result.data.eventAttendees
      });
      commit(mutationTypes.END_EVENT_JOB, "get-event");
    }
  },

  async getEventAttendeeImportPriceList({ commit, state }, {eventId, salesChannelId}) {
    commit(mutationTypes.START_EVENT_JOB, "get-event");
    commit(mutationTypes.CLEAR_EVENT_ATTENDEE_PRICE_LIST);
    const query = `query { eventPrices(eventId: "${eventId}", salesChannelId: "${salesChannelId}"){conditionId,price,currencyCode,currency{code,name,exponent,symbol},tax,description,conditionPath,limit,accessDefinition{id,name,description,capacityLocations}} }`;
    const result = await backoffice.sendQuery(query);
    if (result.errors) {
      commit(mutationTypes.EVENT_ERROR, { error: result.errors });
      commit(mutationTypes.END_EVENT_JOB, "get-event");
    } else {
      commit(mutationTypes.RECEIVED_EVENT_ATTENDEE_PRICE_LIST, {
        eventPrices: result.data.eventPrices
      });
      commit(mutationTypes.END_EVENT_JOB, "get-event");
    }
  },


  /**
   * Send event command
   * @param commit
   * @param state
   * @param mutations                     [ { type: '', data: {} } ]
   * @returns {Promise<void>}
   */
  async saveEventMutations({ commit, state }, mutations) {
    commit(mutationTypes.START_EVENT_JOB, "save-event");
    // const backoffice = new BackendService();
    const result = await backoffice.sendCommandBatch(mutations);
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "save-event");
    } else {
      commit(
        mutationTypes.SAVED_EVENT,
        mutations.reduce(
          (state, command) => Object.assign(state, command.data),
          {}
        )
      );
      commit(mutationTypes.END_EVENT_JOB, "save-event");
    }
  },

  async createNewEvent({ commit, state }, data) {
    commit(mutationTypes.START_EVENT_JOB, "save-event");
    // const backoffice = new BackendService();
    let result = await backoffice.sendCommand("CreateEventManager", {
      capacityAllocation: "static"
    });
    if (!result.error) {
      data.aggregateId = result.data.eventManagerId;
      result = await backoffice.sendCommand("PlanEvent", data);
    }

    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "save-event");
    } else {
      data.id = result.data.eventId;
      data.eventManagerId = data.aggregateId;
      commit(mutationTypes.SAVED_EVENT, data);
      commit(mutationTypes.END_EVENT_JOB, "save-event");
      router.push({ name: "event-detail", params: { id: data.id } });
    }
  },

  async cancelEvent({ commit, state }, {eventManagerId, eventId}) {
    commit(mutationTypes.START_EVENT_JOB, "save-event");
    let result = await backoffice.sendCommand("CancelEvent", {aggregateId: eventManagerId, eventId: eventId});
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "save-event");
    } else {
      commit(mutationTypes.CLEAR_EVENT);
      commit(mutationTypes.END_EVENT_JOB, "save-event");
      // router.push({ name: "event-root" });
      router.go();
    }
  },

  async publishEvent({ commit, state }, {eventManagerId, eventId}) {
    commit(mutationTypes.START_EVENT_JOB, "save-event");
    let result = await backoffice.sendCommand("PublishEvent", {aggregateId: eventManagerId, eventId: eventId});
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "save-event");
    } else {
      commit(mutationTypes.CLEAR_EVENT);
      commit(mutationTypes.END_EVENT_JOB, "save-event");
      router.go();
    }
  },

  async draftEvent({ commit, state }, {eventManagerId, eventId}) {
    commit(mutationTypes.START_EVENT_JOB, "save-event");
    let result = await backoffice.sendCommand("DraftEvent", {aggregateId: eventManagerId, eventId: eventId});
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "save-event");
    } else {
      commit(mutationTypes.CLEAR_EVENT);
      commit(mutationTypes.END_EVENT_JOB, "save-event");
      router.go();
    }
  },

  async applyEventTemplate({ commit, state }, {eventManagerId, eventId, templateId, templateType}) {
    let command = 'ApplyEventTemplate';
    if(templateType === 'eventCapacityTemplate') {
      command = 'ApplyEventCapacityTemplate';
    }
    if(templateType === 'eventAccessTemplate') {
      command = 'ApplyEventAccessTemplate';
    }

    commit(mutationTypes.START_EVENT_JOB, "save-event");
    // let result = await backoffice.sendCommand("ApplyEventTemplate", {aggregateId: eventManagerId, eventId: eventId, templateId: templateId});
    let result = await backoffice.sendCommand(command, {aggregateId: eventManagerId, eventId: eventId, templateId: templateId});
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "save-event");
    } else {
      commit(mutationTypes.CLEAR_EVENT);
      commit(mutationTypes.END_EVENT_JOB, "save-event");
      router.go();
    }
  },

  async detachEventTemplate({ commit, state }, {eventManagerId, eventId, templateId, templateType}) {
    let command = 'DetachEventTemplate';
    if(templateType === 'eventCapacityTemplate') {
      command = 'DetachEventCapacityTemplate';
    }
    if(templateType === 'eventAccessTemplate') {
      command = 'DetachEventAccessTemplate';
    }

    commit(mutationTypes.START_EVENT_JOB, "save-event");
    // let result = await backoffice.sendCommand("DetachEventTemplate", {aggregateId: eventManagerId, eventId: eventId, templateId: templateId});
    let result = await backoffice.sendCommand(command, {aggregateId: eventManagerId, eventId: eventId, templateId: templateId});
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "save-event");
    } else {
      commit(mutationTypes.CLEAR_EVENT);
      commit(mutationTypes.END_EVENT_JOB, "save-event");
      router.go();
    }
  },

  async getAccessDefinitions({ commit, state }, query) {
    commit(mutationTypes.START_EVENT_JOB, "get-access-definition");
    commit(mutationTypes.CLEAR_ACCESS_DEFINITIONS);
    // const bs = new BackendService();
    const result = await backoffice.sendQuery(query);
    if (result.errors) {
      commit(mutationTypes.EVENT_ERROR, { error: result.errors });
      commit(mutationTypes.END_EVENT_JOB, "get-access-definition");
    } else {
      commit(
        mutationTypes.RECEIVED_ACCESS_DEFINITIONS,
        result.data.accessDefinitions.map((ad) => {
          ad.accessConditions = JSON.parse(ad.conditionsString);
          ad.accessConditionsResolvedEntities = ad.conditionsStringResolvedEntities ? JSON.parse(ad.conditionsStringResolvedEntities) : null;
          return ad;
        })
      );
      commit(mutationTypes.SET_EVENT_LIST_ROWS, { total: result.total });
      commit(mutationTypes.END_EVENT_JOB, "get-access-definition");
    }
  },

  async getAccessDefinition({ commit, state }, query) {
    commit(mutationTypes.START_EVENT_JOB, "get-access-definition");
    commit(mutationTypes.CLEAR_ACCESS_DEFINITION);
    // const bs = new BackendService();
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "get-access-definition");
    } else {
      if (result.data.accessDefinition && result.data.accessDefinition.conditionsString)
        result.data.accessDefinition.accessConditions = JSON.parse(
          result.data.accessDefinition.conditionsString
        );
      commit(
        mutationTypes.RECEIVED_ACCESS_DEFINITION,
        result.data.accessDefinition
      );
      commit(mutationTypes.END_EVENT_JOB, "get-access-definition");
    }
  },

  async saveAccessDefinitionMutations({ commit, state }, mutations) {
    commit(mutationTypes.START_EVENT_JOB, "save-access-definition");
    // const bs = new BackendService();
    const result = await backoffice.sendCommandBatch(mutations);
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "save-access-definition");
    } else {
      commit(
        mutationTypes.SAVED_ACCESS_DEFINITION,
        mutations.reduce(
          (state, command) => Object.assign(state, command.data),
          {}
        )
      );
      commit(mutationTypes.END_EVENT_JOB, "save-access-definition");

      // navigate to access overview
      const id = (mutations[0] && mutations[0].data && mutations[0].data.eventId) ? mutations[0].data.eventId : null;
      if(id) {
        router.push({ name: 'access-definition-list', params: { id: id } });
      }
    }
  },

  async createNewAccessDefinition({ commit, state }, data) {
    commit(mutationTypes.START_EVENT_JOB, "save-access-definition");
    // const backoffice = new BackendService();
    let result = await backoffice.sendCommand("AddAccessDefinition", data);
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "save-access-definition");
    } else {
      data.accessDefinitionId = result.data.accessDefinitionId;
      commit(mutationTypes.SAVED_ACCESS_DEFINITION, data);
      commit(mutationTypes.END_EVENT_JOB, "save-access-definition");
      // router.push({
      //   name: "access-definition-detail",
      //   params: {
      //     eventId: data.eventId,
      //     accessDefinitionId: data.accessDefinitionId
      //   }
      // });
      router.push({ name: "access-definition-list", params: { id: data.eventId } });
    }
  },

  async removeAccessDefinition({ commit, state }, {eventManagerId, eventId, accessDefinitionId}) {
    commit(mutationTypes.START_EVENT_JOB, "save-access-definition");
    let result = await backoffice.sendCommand("RemoveAccessDefinition", {aggregateId: eventManagerId, eventId, accessDefinitionId});
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "save-access-definition");
    } else {
      commit(mutationTypes.END_EVENT_JOB, "save-access-definition");
      router.push({
        name: "access-definition-list",
        params: {
          eventId: eventId,
        }
      });
    }
  },


  async duplicateAccessDefinition({ commit, state }, {eventManagerId, eventId, accessDefinitionId}) {
    commit(mutationTypes.START_EVENT_JOB, "save-access-definition");
    let result = await backoffice.sendCommand("DuplicateAccessDefinition", {aggregateId: eventManagerId, eventId, accessDefinitionId});
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "save-access-definition");
    } else {
      commit(mutationTypes.END_EVENT_JOB, "save-access-definition");
      router.push({
        name: "access-definition-list",
        params: {
          eventId: eventId,
        }
      });
    }
  },

  async getCapacities({ commit, state }, query) {
    commit(mutationTypes.START_EVENT_JOB, "get-capacity");
    commit(mutationTypes.CLEAR_CAPACITY);
    // const bs = new BackendService();
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "get-capacity");
    } else {
      result.data.capacityDefinitions.forEach(cd => {
        cd.capacity = JSON.parse(cd.capacity);
      });
      commit(mutationTypes.RECEIVED_CAPACITIES, result.data.capacityDefinitions);
      if (result.data.capacityDefinitions[0] && result.data.capacityDefinitions[0].capacity)
        commit(mutationTypes.SET_ACCESS_DEFINITION_FORM_OPTIONS, {
          // capacityLocations: eventUtilService.getCapacityLocations(result.data.capacityDefinitions[0].capacity)
          capacityLocations: eventUtilService.getCapacityLocationOptions(result.data.capacityDefinitions[0].capacity)
        });
      commit(mutationTypes.END_EVENT_JOB, "get-capacity");
    }
  },

  async getCapacity({ commit, state }, query) {
    commit(mutationTypes.START_EVENT_JOB, "get-capacity");
    commit(mutationTypes.CLEAR_CAPACITY);
    // const bs = new BackendService();
    const result = await backoffice.sendQuery(query);
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "get-capacity");
    } else {
      if (result.data.capacityDefinitions.capacity)
        result.data.capacityDefinitions.capacity = JSON.parse(
          result.data.capacityDefinitions.capacity
        );
      commit(mutationTypes.RECEIVED_CAPACITY, result.data.capacityDefinition);
      commit(mutationTypes.END_EVENT_JOB, "get-capacity");
    }
  },

  async saveCapacityMutations({ commit, state, dispatch }, {mutations, eventId, isNew}) {
    commit(mutationTypes.START_EVENT_JOB, "save-capacity");
    // const bs = new BackendService();
    const result = await backoffice.sendCommandBatch(mutations);
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "save-capacity");
    } else {
      const capacity = mutations.reduce(
          (state, command) => Object.assign(state, command.data),
          {}
      );
      const capacityId = result.reduce(
          (state, r) => state = state === null && r && r.data && r.data.capacityId ? r.data.capacityId : null,
          null
      );
      capacity.id = capacityId;
      capacity.eventManagerId = capacity.aggregateId;
      commit(mutationTypes.SAVED_CAPACITY, capacity);
      commit(mutationTypes.END_EVENT_JOB, "save-capacity");
      // if(isNew) {
      //   dispatch('getCapacities', `query { capacityDefinitions(filters: {eventId: {equals: "${eventId}"}}) {id,eventManagerId,capacity} }`);
      // }
      router.go();
    }
  },

  async createNewCapacity({ commit, state, dispatch }, {data, eventId}) {
    commit(mutationTypes.START_EVENT_JOB, "save-capacity");
    // const backoffice = new BackendService();
    let result = await backoffice.sendCommand("AddCapacity", data);
    if (result.error) {
      commit(mutationTypes.EVENT_ERROR, result.error);
      commit(mutationTypes.END_EVENT_JOB, "save-capacity");
    } else {
      data.capacityId = result.data.capacityId;
      commit(mutationTypes.SAVED_CAPACITY, data);
      commit(mutationTypes.END_EVENT_JOB, "save-capacity");
      if(eventId) dispatch('getEvent', {id: eventId});
      router.go();
    }
  },

  async importEventAttendees({ commit, state, dispatch }, {salesChannelId, registerId, eventManagerId, eventId, accessDefinitionId, requestedConditionPath, quantity, reserve, timeoutOn, emails}) {
    const batchOperations = [];
    emails.forEach((email) => {
      // command on items reserved
      let commandOnItemsReserved = {
        command: 'ReserveOrder',
        data: {
          timeout: {type: 'on', on: timeoutOn},
          customerEmail: email
        }
      };
      if(reserve === false) {
        commandOnItemsReserved = {
          command: 'CheckOutOrder',
          data: {
            customerEmail: email
          }
        };
      }

      // cart operations
      const cartOperations = [];
      for (let i = 0; i < quantity; i++) {
        cartOperations.push({
          operation: 'AddAccessItem',
          data: {
            eventManagerId,
            eventId,
            accessDefinitionId,
            requestedConditionPath,
          }
        });
      }

      //
      batchOperations.push({
        command: 'CreateOrderAndBatchAddItem',
        body: {
          salesChannelId: salesChannelId,
          registerId: registerId,
          operations: cartOperations,
          commandOnItemsReserved: commandOnItemsReserved
        }
      });
    });

    dispatch('startBatchOperation', batchOperations);
  },









  async startBatchOperation({ commit, dispatch }, operations) {
    const jobName = 'start-batch-operation';
    commit(mutationTypes.START_EVENT_JOB, jobName);
    await dispatch('core/startBatchOperation', operations, {root:true});
    commit(mutationTypes.END_EVENT_JOB, jobName);
  },

  setAccessDefinitionFormOptions({ commit, state }, options) {
    commit(mutationTypes.SET_ACCESS_DEFINITION_FORM_OPTIONS, options);
  },

  setEventListSelectedColumns({ commit, state }, selectedColumns) {
    commit(mutationTypes.SELECT_EVENT_LIST_COLUMNS, selectedColumns);
  },

  setEventListRows({ commit, state }, rows) {
    commit(mutationTypes.SET_EVENT_LIST_ROWS, rows);
  },

  setEventListSort({ commit, state }, sort) {
    commit(mutationTypes.SET_EVENT_LIST_SORT, sort);
  },

  setEventListSelectedFilters({ commit, state }, selectedFilters) {
    commit(mutationTypes.FILTER_EVENT_LIST, selectedFilters);
  },

  setEventListSearchTerm({ commit, state }, searchTerm) {
    commit(mutationTypes.SET_EVENT_LIST_SEARCH_TERM, searchTerm);
  },

  clearEvent({ commit, state }) {
    commit(mutationTypes.CLEAR_EVENT);
  },

  clearCapacity({ commit, state }) {
    commit(mutationTypes.CLEAR_CAPACITY);
  },

  clearAccessDefinition({ commit, state }) {
    commit(mutationTypes.CLEAR_ACCESS_DEFINITION);
  },


};
