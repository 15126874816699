import * as types from "../mutation-types";
import _ from "lodash";

export default {
  [types.ORDER_ERROR](state, error) {
    state.error = { message: error };
  },

  [types.FLUSH_ORDER_ERROR](state) {
    state.error = { message: null };
  },

  [types.START_ORDER_JOB](state, key) {
    state.jobs.push(key);
  },

  [types.END_ORDER_JOB](state, key) {
    state.jobs = state.jobs.filter(job => job !== key);
  },

  [types.RECEIVED_ORDERS](state, { orders }) {
    state.orders = orders;
  },

  [types.RECEIVED_ORDER](state, { order }) {
    state.order = order;
  },

  [types.RECEIVED_ORDER_EMAILS](state, { emails }) {
    state.orderEmails = emails;
  },

  [types.SAVED_ORDER](state, commandData) {
    state.order = _.merge(state.order, commandData);
  },

  [types.CREATED_ORDER](state, data) {
    state.order = data;
  },

  [types.CLEAR_ORDER](state) {
    state.order = {};
  },

  [types.SELECT_ORDER_LIST_COLUMNS](state, selectedColumns) {
    state.orderListView.columns.selected = selectedColumns;
  },

  [types.FILTER_ORDER_LIST](state, selectedFilters) {
    state.orderListView.filters.selected = selectedFilters;
  },

  [types.SET_ORDER_LIST_ROWS](state, rows) {
    state.orderListView.rows = _.merge(state.orderListView.rows, rows);
  },

  [types.SORTED_ORDER_LIST](state, rows) {
    state.orderListView.sort = _.merge(state.orderListView.sort, rows);
  },

};
