import * as types from "../mutation-types";
import _ from "lodash";

export default {
  [types.CUSTOMER_ERROR](state, error) {
    state.error = { message: error };
  },

  [types.FLUSH_CUSTOMER_ERROR](state) {
    state.error = { message: null };
  },

  [types.START_CUSTOMER_JOB](state, key) {
    state.jobs.push(key);
  },

  [types.END_CUSTOMER_JOB](state, key) {
    state.jobs = state.jobs.filter(job => job !== key);
  },

  [types.RECEIVED_CUSTOMERS](state, { customers }) {
    state.customers = customers;
  },

  [types.RECEIVED_CUSTOMER](state, { customer }) {
    state.customer = customer;
  },

  [types.RECEIVED_CUSTOMER_ORDERS](state, { orders }) {
    state.customerOrders = orders;
  },
  [types.RECEIVED_CUSTOMER_ACCESS](state, { access }) {
    state.customerAccess = access;
  },
  [types.RECEIVED_CUSTOMER_PRODUCTS](state, { products }) {
    state.customerProducts = products;
  },
  [types.RECEIVED_CUSTOMER_MEMBERSHIPS](state, { memberships }) {
    state.customerMemberships = memberships;
  },

  [types.SAVED_CUSTOMER](state, commandData) {
    state.customer = _.merge(state.customer, commandData);
  },

  [types.CREATED_CUSTOMER](state, data) {
    state.customer = data;
  },

  [types.CLEAR_CUSTOMER](state) {
    state.customer = {};
  },

  [types.SELECT_CUSTOMER_LIST_COLUMNS](state, selectedColumns) {
    state.customerListView.columns.selected = selectedColumns;
  },

  [types.SET_CUSTOMER_LIST_ROWS](state, rows) {
    state.customerListView.rows = _.merge(state.customerListView.rows, rows);
  },

  [types.SET_CUSTOMER_LIST_SORT](state, sort) {
    state.customerListView.sort = _.merge(state.customerListView.sort, sort);
  },

  [types.FILTER_CUSTOMER_LIST](state, selectedFilters) {
    state.customerListView.filters.selected = selectedFilters;
  },

  [types.SET_CUSTOMER_LIST_SEARCH_TERM](state, searchTerm) {
    state.customerListView.searchTerm = searchTerm;
  }
};
