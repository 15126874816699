export const SYSTEM_ERROR = "SYSTEM_ERROR";
export const FLUSH_SYSTEM_ERROR = "FLUSH_SYSTEM_ERROR";
export const START_SYSTEM_JOB = "START_SYSTEM_JOB";
export const END_SYSTEM_JOB = "END_SYSTEM_JOB";

export const RECEIVED_USERS = "RECEIVED_USERS";
export const RECEIVED_USER = "RECEIVED_USER";
export const SAVED_USER = "SAVED_USER";
export const CREATED_USER = "CREATED_USER";
export const CLEAR_USER = "CLEAR_USER";

export const RECEIVED_TAGS = "RECEIVED_TAGS";
export const RECEIVED_TAG = "RECEIVED_TAG";
export const SAVED_TAG = "SAVED_TAG";
export const CREATED_TAG = "CREATED_TAG";
export const CLEAR_TAG = "CLEAR_TAG";

export const RECEIVED_COUPONS = "RECEIVED_COUPONS";
export const RECEIVED_COUPON = "RECEIVED_COUPON";
export const SAVED_COUPON = "SAVED_COUPON";
export const CREATED_COUPON = "CREATED_COUPON";
export const CLEAR_COUPON = "CLEAR_COUPON";
export const RECEIVED_TOKENS = "RECEIVED_TOKENS";

export const RECEIVED_EMAIL_TEMPLATES = "RECEIVED_EMAIL_TEMPLATES";
export const RECEIVED_EMAIL_TEMPLATE = "RECEIVED_EMAIL_TEMPLATE";
export const SAVED_EMAIL_TEMPLATE = "SAVED_EMAIL_TEMPLATE";
export const CLEAR_EMAIL_TEMPLATE = "CLEAR_EMAIL_TEMPLATE";

export const RECEIVED_DOCUMENT_TEMPLATES = "RECEIVED_DOCUMENT_TEMPLATES";
export const RECEIVED_DOCUMENT_TEMPLATE = "RECEIVED_DOCUMENT_TEMPLATE";
export const SAVED_DOCUMENT_TEMPLATE = "SAVED_DOCUMENT_TEMPLATE";
export const CLEAR_DOCUMENT_TEMPLATE = "CLEAR_DOCUMENT_TEMPLATE";

export const RECEIVED_SALES_CHANNELS = "RECEIVED_SALES_CHANNELS";
export const RECEIVED_SALES_CHANNEL = "RECEIVED_SALES_CHANNEL";
export const SAVED_SALES_CHANNEL = "SAVED_SALES_CHANNEL";
export const CREATED_SALES_CHANNEL = "CREATED_SALES_CHANNEL";
export const CLEAR_SALES_CHANNEL = "CLEAR_SALES_CHANNEL";
export const SAVED_REGISTER = "SAVED_REGISTER";
export const CREATED_REGISTER = "CREATED_REGISTER";
export const REMOVED_REGISTER = "REMOVED_REGISTER";
export const SAVE_REGISTER_ORDER_TIMEOUT = "SAVE_REGISTER_ORDER_TIMEOUT";
export const REMOVE_REGISTER_ORDER_TIMEOUT = "REMOVE_REGISTER_ORDER_TIMEOUT";

export const RECEIVED_PAYMENT_METHODS = "RECEIVED_PAYMENT_METHODS";
export const RECEIVED_PAYMENT_METHOD = "RECEIVED_PAYMENT_METHOD";
export const SAVED_PAYMENT_METHOD = "SAVED_PAYMENT_METHOD";
export const CREATED_PAYMENT_METHOD = "CREATED_PAYMENT_METHOD";
export const CLEAR_PAYMENT_METHOD = "CLEAR_PAYMENT_METHOD";

export const RECEIVED_OPT_IN_DEFINITIONS = "RECEIVED_OPT_IN_DEFINITIONS";
export const RECEIVED_OPT_IN_DEFINITION = "RECEIVED_OPT_IN_DEFINITION";
export const SAVED_OPT_IN_DEFINITION = "SAVED_OPT_IN_DEFINITION";
export const CREATED_OPT_IN_DEFINITION = "CREATED_OPT_IN_DEFINITION";
export const CLEAR_OPT_IN_DEFINITION = "CLEAR_OPT_IN_DEFINITION";

export const RECEIVED_ORDER_MESSAGE_DEFINITIONS = "RECEIVED_ORDER_MESSAGE_DEFINITIONS";
export const RECEIVED_ORDER_MESSAGE_DEFINITION = "RECEIVED_ORDER_MESSAGE_DEFINITION";
export const SAVED_ORDER_MESSAGE_DEFINITION = "SAVED_ORDER_MESSAGE_DEFINITION";
export const CREATED_ORDER_MESSAGE_DEFINITION = "CREATED_ORDER_MESSAGE_DEFINITION";
export const CLEAR_ORDER_MESSAGE_DEFINITION = "CLEAR_ORDER_MESSAGE_DEFINITION";

export const RECEIVED_INTEGRATION_PRODUCT_DEFINITION = "RECEIVED_INTEGRATION_PRODUCT_DEFINITION";
export const RECEIVED_INTEGRATION_PRICES = "RECEIVED_INTEGRATION_PRICES";
export const RECEIVED_INTEGRATION_FIONA_ENTITY_MAPS = "RECEIVED_INTEGRATION_FIONA_ENTITY_MAPS";
export const RECEIVED_INTEGRATION_FIONA_ENTITY_MAP = "RECEIVED_INTEGRATION_FIONA_ENTITY_MAP";
export const SAVED_INTEGRATION_FIONA_ENTITY_MAP = "SAVED_INTEGRATION_FIONA_ENTITY_MAP";
export const CLEAR_INTEGRATION_FIONA_ENTITY_MAPS = "CLEAR_INTEGRATION_FIONA_ENTITY_MAPS";
export const CLEAR_INTEGRATION_FIONA_ENTITY_MAP = "CLEAR_INTEGRATION_FIONA_ENTITY_MAP";
export const RECEIVED_INTEGRATION_FIONA_CONFIG = "RECEIVED_INTEGRATION_FIONA_CONFIG";
export const SAVED_INTEGRATION_FIONA_CONFIG = "SAVED_INTEGRATION_FIONA_CONFIG";
export const CLEAR_INTEGRATION_FIONA_CONFIG = "CLEAR_INTEGRATION_FIONA_CONFIG";
export const RECEIVED_INTEGRATION_FIONA_ACCREDITATIONS = "RECEIVED_INTEGRATION_FIONA_ACCREDITATIONS";
export const CLEAR_INTEGRATION_FIONA_ACCREDITATIONS = "CLEAR_INTEGRATION_FIONA_ACCREDITATIONS";
export const RECEIVED_INTEGRATION_FIONA_VOLUNTEERS = "RECEIVED_INTEGRATION_FIONA_VOLUNTEERS";
export const CLEAR_INTEGRATION_FIONA_VOLUNTEERS = "CLEAR_INTEGRATION_FIONA_VOLUNTEERS";
export const RECEIVED_INTEGRATION_FIONA_EVENTS = "RECEIVED_INTEGRATION_FIONA_EVENTS";
export const CLEAR_INTEGRATION_FIONA_EVENTS = "CLEAR_INTEGRATION_FIONA_EVENTS";
export const RECEIVED_INTEGRATION_FIONA_SYNC_QUEUE = "RECEIVED_INTEGRATION_FIONA_SYNC_QUEUE";
export const CLEAR_INTEGRATION_FIONA_SYNC_QUEUE = "CLEAR_INTEGRATION_FIONA_SYNC_QUEUE";
export const RECEIVED_INTEGRATION_FIONA_PROXY_EDITIONS = "RECEIVED_INTEGRATION_FIONA_PROXY_EDITIONS";
export const RECEIVED_INTEGRATION_FIONA_PROXY_EDITION_TYPES = "RECEIVED_INTEGRATION_FIONA_PROXY_EDITION_TYPES";
export const RECEIVED_INTEGRATION_FIONA_PROXY_GUESTBOOKS = "RECEIVED_INTEGRATION_FIONA_PROXY_GUESTBOOKS";
export const RECEIVED_INTEGRATION_FIONA_PROXY_ACCREDITATIONS = "RECEIVED_INTEGRATION_FIONA_PROXY_ACCREDITATIONS";
export const RECEIVED_INTEGRATION_FIONA_PROXY_VOLUNTEERS = "RECEIVED_INTEGRATION_FIONA_PROXY_VOLUNTEERS";
export const RECEIVED_INTEGRATION_FIONA_PROXY_VOLUNTEER_EDITIONS = "RECEIVED_INTEGRATION_FIONA_PROXY_VOLUNTEER_EDITIONS";
export const RECEIVED_INTEGRATION_FIONA_PROXY_SHOWS = "RECEIVED_INTEGRATION_FIONA_PROXY_SHOWS";
export const RECEIVED_INTEGRATION_FIONA_PROXY_ACCREDITATION_BADGE_TYPES = "RECEIVED_INTEGRATION_FIONA_PROXY_ACCREDITATION_BADGE_TYPES";
export const RECEIVED_INTEGRATION_FIONA_PROXY_ACCREDITATION_BADGE_STATUS = "RECEIVED_INTEGRATION_FIONA_PROXY_ACCREDITATION_BADGE_STATUS";
export const RECEIVED_INTEGRATION_FIONA_PROXY_VOLUNTEER_BADGE_TYPES = "RECEIVED_INTEGRATION_FIONA_PROXY_VOLUNTEER_BADGE_TYPES";


