import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import moment from "moment";
import formatService from "@/services/format.service";
import optionService from "@/services/option.service";
import validationService from "@/services/validation.service";
import validators from 'vue-form-generator/src/utils/validators'

// initial state
const state = {
    error: [],
    jobs: [],
    eventTemplates: [],
    eventTemplate: {},
    appliedOnEvents: [],
    eventCapacityTemplatesOptions: [],

    // // event template list view settings
    // eventTemplateListView: {
    //     columns: {
    //         options: [
    //             // all available columns
    //             {
    //                 key: "name",
    //                 label: "common.name", // translation key for the header label
    //                 link: {
    //                   routeName: "event-template-detail",
    //                   params: [{ paramKey: "id", valueKey: "id" }]
    //                 } // route params contains a params map for mapping row values to params properties
    //             },
    //             {
    //                 key: "id",
    //                 label: "common.id"
    //             },
    //             {
    //                 key: "description",
    //                 label: "common.description"
    //             },
    //         ],
    //         selected: [ // labels of columns that are displayed
    //             "common.name",
    //         ]
    //     },
    //     rows: {
    //         total: 0, // total rows in search result
    //         max: 20, // maximum number of rows to display at once, elastic search query size
    //         from: 0 // show rows from row number, elastic search query from
    //     },
    //     filters: {
    //         selected: []
    //     },
    //     sort: {
    //         key: "name",
    //         direction: "asc"
    //     },
    // },


    // event detail view settings
    // eventTemplateDetailView: {
    //     // tabs: [
    //     //     { routeName: "event-template-detail", label: "common.details" },
    //     //     { routeName: "event-template-capacity-detail", label: "common.capacity" },
    //     //     { routeName: "event-template-access-definition-list", label: "common.access" },
    //     //     { routeName: "event-template-used-by-events", label: "eventTemplate.usedByEvents" },
    //     // ],
    //
    //     // menu: [
    //     //     [
    //     //         {
    //     //             name: "cancel-event",
    //     //             label: "event.cancel",
    //     //             icon: "times"
    //     //         },
    //     //     ]
    //     // ],
    //
    //     form: {
    //         schema: {
    //             groups: [
    //                 {
    //                     fields: [
    //                         {
    //                             type: "input",
    //                             inputType: "text",
    //                             label: "common.name",
    //                             icon: "label-alt",
    //                             model: "name",
    //                             command: "RenameEventTemplate",
    //                             placeholder: "",
    //                             required: true,
    //                             validator: "string"
    //                         },
    //                         {
    //                             type: "textArea",
    //                             rows: 3,
    //                             label: "common.description",
    //                             icon: "paragraph",
    //                             model: "description",
    //                             command: "RenameEventTemplate",
    //                             placeholder: ""
    //                         }
    //                     ]
    //                 }
    //             ]
    //         },
    //         options: {
    //             validateAfterLoad: true,
    //             validateAfterChanged: true
    //         },
    //         commandDataMap: [
    //             {
    //                 modelKey: "id",
    //                 commandKey: "aggregateId"
    //             }
    //         ]
    //     }
    // },


    // // access definition list view settings
    // accessDefinitionListView: {
    //     columns: {
    //         options: [
    //             // all available columns
    //             {
    //                 key: "name",
    //                 label: "common.name", // translation key for the header label
    //                 link: {
    //                     routeName: "event-template-access-definition-detail",
    //                     params: [
    //                         { paramKey: "accessDefinitionId", valueKey: "id" },
    //                         {
    //                             paramKey: "id",
    //                             valueKey: "templateId"
    //                         }
    //                     ]
    //                 } // route params contains a params map for mapping row values to params properties
    //             },
    //             {
    //                 key: "id",
    //                 label: "common.id"
    //             },
    //             {
    //                 key: "description",
    //                 label: "common.description"
    //             },
    //             {
    //                 key: "accessConditions",
    //                 label: "access.prices",
    //                 format: formatService.formatConditions
    //             },
    //             {
    //                 key: "capacityLocations",
    //                 label: "event.location"
    //             },
    //             {
    //                 key: "capacityLocationAllocation",
    //                 label: "event.allocation"
    //             },
    //         ],
    //         selected: ["common.name", "common.description", "access.prices"] // labels of columns that are displayed
    //     },
    //     rows: {
    //         total: 0, // total rows in search result
    //         max: 20, // maximum number of rows to display at once, elastic search query size
    //         from: 0 // show rows from row number, elastic search query from
    //     },
    //     searchTerm: "",
    //     searchFields: [],
    //     filters: {
    //         options: [],
    //         selected: []
    //     },
    //     sort: {
    //         key: "name",
    //         direction: "asc"
    //     }
    //     // bulkActions: ['actions.merge']
    // },

    // // access definition detail view settings
    // accessDefinitionDetailView: {
    //     form: {
    //         schema: {
    //             groups: [
    //                 {
    //                     fields: [
    //                         {
    //                             type: "input",
    //                             inputType: "text",
    //                             label: "common.name",
    //                             icon: "label-alt",
    //                             model: "name",
    //                             command: "RenameEventTemplateAccessDefinition",
    //                             placeholder: "",
    //                             required: true,
    //                             validator: "string"
    //                         },
    //                         {
    //                             type: "input",
    //                             inputType: "text",
    //                             label: "common.description",
    //                             icon: "paragraph",
    //                             model: "description",
    //                             command: "RenameEventTemplateAccessDefinition",
    //                             placeholder: ""
    //                         },
    //                         {
    //                             type: "vueMultiSelect",
    //                             label: "access.capacityLocations",
    //                             icon: "map-marker-alt",
    //                             model: "capacityLocations",
    //                             command: "AssignEventTemplateAccessDefinitionLocation",
    //                             format: (capacityLocations) => {
    //                                 return capacityLocations && capacityLocations.length > 0 ? capacityLocations.join(', ') : ''
    //                             },
    //                             placeholder: "",
    //                             // required: true,
    //                             values: [],
    //                             required: true,
    //                             validator: validationService.validateAccessCapacity,
    //                             // validator: validators.required,
    //                             selectOptions: {
    //                                 multiple: true,
    //                                 closeOnSelect: false,
    //                                 hideSelected: true,
    //                                 searchable: true,
    //                                 showLabels: false
    //                             }
    //                         },
    //                         {
    //                             type: "input",
    //                             inputType: "number",
    //                             label: "access.useLimit",
    //                             icon: "label-alt",
    //                             model: "useLimit",
    //                             command: "ChangeEventTemplateAccessDefinitionUseLimit",
    //                             // required: true,
    //                             validator: "integer",
    //                             min: 0,
    //                             placeholder: ""
    //                         },
    //                         {
    //                             type: "teTag",
    //                             label: "common.tags",
    //                             model: "tags",
    //                             icon: "tag-alt",
    //                             command: "ChangeEventTemplateAccessDefinitionTags",
    //                             format: formatService.formatTags,
    //                             searchTag: optionService.searchTag,
    //                             addTag: optionService.addTag,
    //                         },
    //                         {
    //                             type: "teConditionEditor",
    //                             label: "common.conditions",
    //                             icon: "sitemap",
    //                             model: "accessConditions",
    //                             command: "ChangeEventTemplateAccessDefinitionConditions",
    //                             placeholder: "",
    //                             conditionOptions: {
    //                                 rules: [],
    //                                 // tags: [],
    //                                 // price: [],
    //                                 price: {},
    //                                 postponed: [],
    //                                 limit: [],
    //                                 capacityGroups: [],
    //                                 searchTag: optionService.searchTag,
    //                                 addTag: optionService.addTag
    //                             },
    //                             format: formatService.formatConditions,
    //                             validator: validationService.validateConditions,
    //                         }
    //                     ]
    //                 }
    //             ]
    //         },
    //         options: {
    //             validateAfterLoad: true,
    //             validateAfterChanged: true
    //         },
    //         commandDataMap: [
    //             {
    //                 modelKey: "templateId",
    //                 commandKey: "aggregateId"
    //             },
    //             {
    //                 modelKey: "id",
    //                 commandKey: "accessDefinitionId"
    //             }
    //         ]
    //     }
    // },

    // // capacity detail view settings
    // capacityDetailView: {
    //     form: {
    //         schema: {
    //             groups: [
    //                 {
    //                     // legend: 'common.general',
    //                     fields: [
    //                         {
    //                             type: "teCapacityEditor",
    //                             label: "common.capacity",
    //                             model: "capacity",
    //                             command: "ChangeEventTemplateCapacity",
    //                             validator: validationService.validateEventCapacity
    //                         }
    //                     ]
    //                 }
    //             ]
    //         },
    //         options: {
    //             validateAfterLoad: true,
    //             validateAfterChanged: true
    //         },
    //         commandDataMap: [
    //             {
    //                 modelKey: "aggregateId",
    //                 commandKey: "aggregateId"
    //             }
    //         ]
    //     }
    // },


};

//
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
