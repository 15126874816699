import * as types from "../mutation-types";
import _ from "lodash";

export default {
    // jobs and error
    [types.ACCOUNT_ERROR](state, error) {
        state.error = { message: error };
    },
    [types.FLUSH_ACCOUNT_ERROR](state) {
        state.error = { message: null };
    },
    [types.START_ACCOUNT_JOB](state, key) {
        state.jobs.push(key);
    },
    [types.END_ACCOUNT_JOB](state, key) {
        state.jobs = state.jobs.filter(job => job !== key);
    },

    // account
    [types.RECEIVED_ACCOUNT](state, { account }) {
        state.account = account;
        localStorage.setItem('te-user-id', account.id);
    },
    [types.CLEAR_ACCOUNT](state) {
        state.account = {};
    },

    // batch operation
    [types.RECEIVED_BATCH_OPERATION](state, { batchOperation }) {
        state.batchOperation = batchOperation;
    },
    [types.CLEAR_BATCH_OPERATION](state) {
        state.batchOperation = {};
    },
    [types.RECEIVED_BATCH_OPERATIONS](state, { batchOperations }) {
        state.batchOperations = batchOperations;
    },
    [types.CLEAR_BATCH_OPERATIONS](state) {
        state.batchOperations = [];
    },

};





