export default [
  {
    path: "/customer",
    name: "customer-root",
    component: () =>
      import(/* webpackChunkName: "customer" */ "@/modules/customer/views/CustomerListView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
  },

  {
    path: "/customer/new",
    name: "customer-new",
    component: () =>
      import(/* webpackChunkName: "customer" */ "@/modules/customer/views/CustomerNewView.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
  },

  {
    path: "/customer/:id",
    name: "customer-detail-root",
    component: () =>
      import(/* webpackChunkName: "customer-detail" */ "@/modules/customer/views/CustomerDetailView.vue"), // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    children: [
      {
        path: "detail",
        name: "customer-detail",
        components: {
          detail: () =>
            import(/* webpackChunkName: "customer-detail" */ "@/modules/customer/components/CustomerDetail.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "order",
        name: "customer-order",
        components: {
          detail: () =>
            import(/* webpackChunkName: "customer-detail" */ "@/modules/customer/components/CustomerOrder.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "access",
        name: "customer-access",
        components: {
          detail: () =>
              import(/* webpackChunkName: "customer-detail" */ "@/modules/customer/components/CustomerAccess.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "product",
        name: "customer-product",
        components: {
          detail: () =>
              import(/* webpackChunkName: "customer-detail" */ "@/modules/customer/components/CustomerProduct.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "membership",
        name: "customer-membership",
        components: {
          detail: () =>
              import(/* webpackChunkName: "customer-detail" */ "@/modules/customer/components/CustomerMembership.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
    ]
  }
];
